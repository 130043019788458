import { BaseControlButton } from "@/components/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { CalendarIcon } from "shared/ui/icons";
import ButtonMenu from "shared/ui/button-menu";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import {
  ArchiveBoxXMarkIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { CREATE_OPPORTUNITY_MODAL_ID } from "@/modals/single-user-license/meetings/create-opportunity-modal";
import { NOT_CREATED_OPPORTUNITY_MODAL_ID } from "@/modals/single-user-license/meetings/not-created-opportunity-modal";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { AccountStatusI, ContactDetailI } from "shared/lib/interfaces/account";
import { getFullEntityName } from "shared/lib/helpers";
import {
  ACCOUNT_STATUSES,
  MEETING_OUTCOMES,
} from "shared/lib/constants/account";
import { X_BOOK_MEETING_MODAL_ID } from "@/modals/x_book_meeting_modal";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const SingleUserLicenseMeetingStatusButton = () => {
  const { contact: callContact } = useInCallContext();
  const { setModalAccount } = useAccountsContext();

  const { account, accountStatus, campaign, contacts, callStatus } =
    useAccountDetailsContext();

  const isMeetingUnqualified =
    accountStatus?.meeting_status === MEETING_OUTCOMES.UNQUALIFIED;

  const currentSingleContact = useMemo(() => {
    return callStatus === DIALER_CALL_STATUS.BEFORE
      ? contacts?.[0]
      : contacts?.find((c) => c.id === callContact.id);
  }, [callStatus, contacts, callContact]);

  const handleOpenBookMeetingModal = (contact: ContactDetailI | undefined) => {
    if (!contact) {
      return;
    }

    const { id, title = "", email = "", first_name, last_name } = contact;

    const name = getFullEntityName({ first_name, last_name });

    //TODO refactor this one
    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountStatus?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        id,
        title,
        email,
        name,
      },
      followUpTime: accountStatus?.meeting_time || "",
    });

    setTimeout(() => modalHelpers.open(X_BOOK_MEETING_MODAL_ID));
  };

  const actions = useMemo(() => {
    const opportunityCreatedItem = {
      icon: CheckCircleIcon,
      text: "Opportunity Created",
      onClick: () => modalHelpers.open(CREATE_OPPORTUNITY_MODAL_ID),
    };

    const noOpportunityCreatedItem = {
      icon: ArchiveBoxXMarkIcon,
      text: "No Opportunity Created",
      onClick: () => modalHelpers.open(NOT_CREATED_OPPORTUNITY_MODAL_ID),
    };

    const rescheduleMeetingItem = {
      icon: CalendarDaysIcon,
      text: "Reschedule Meeting",
      onClick: () => handleOpenBookMeetingModal(currentSingleContact),
    };

    if (isMeetingUnqualified) {
      return [opportunityCreatedItem, rescheduleMeetingItem];
    }

    return [
      opportunityCreatedItem,
      noOpportunityCreatedItem,
      rescheduleMeetingItem,
    ];
  }, [isMeetingUnqualified, currentSingleContact, handleOpenBookMeetingModal]);

  const options = useMemo(
    () =>
      actions.map(({ icon: Icon, text, onClick }) => (
        <ButtonMenuItem
          icon={<Icon className="h-5 w-5" />}
          text={text}
          onClick={onClick}
        />
      )),
    [actions]
  );

  return (
    <ButtonMenu
      items={options}
      className="p-0"
      position="end"
      containerClassName="dropdown-top"
      contentClassName="w-max max-w-[300px] mt-0 mb-2"
      renderTrigger={() => (
        <BaseControlButton className="btn-ae-default">
          <CalendarIcon className="mb-1 mt-1 w-4" />
          <span>Meeting status</span>
        </BaseControlButton>
      )}
    />
  );
};
