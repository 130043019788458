import { FC, useRef } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import { clsxMerge } from "shared/lib/helpers";
import { BadgeInput, TextField } from "shared/ui";

import { BadgeI } from "shared/ui/user-input/badge-input";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

const EnchancedTextField = TextField(Field);
const EnchancedBadgeInput = BadgeInput(Field);

export interface FindMoreLeadsFormI {
  titles?: BadgeI[];
  first_name?: string;
  last_name?: string;
  linkedin_url?: string;
}

interface FindMoreLeadsFormPropsI
  extends PropsWithClassNameI,
    FindMoreLeadsFormI {
  accountName?: string;
  loading?: boolean;
  onSubmit?: (data: FindMoreLeadsFormI) => void;
}

const FindMoreLeadsFormValidation = Yup.object().shape({
  titles: Yup.array(),
  first_name: Yup.string(),
  last_name: Yup.string(),
  linkedin_url: Yup.string(),
});

export const FindMoreLeadsForm: FC<FindMoreLeadsFormPropsI> = ({
  className,
  accountName,
  titles = [],
  first_name,
  last_name,
  linkedin_url,
  loading,
  onSubmit = () => {},
}) => {
  const formikRef = useRef<FormikProps<FindMoreLeadsFormI>>(null);

  const form: FindMoreLeadsFormI = {
    titles: titles || [],
    first_name: first_name || "",
    last_name: last_name || "",
    linkedin_url: linkedin_url || "",
  };

  const onFormSubmit = async (data: FindMoreLeadsFormI) => {
    onSubmit(data);
  };

  return (
    <div className={className}>
      <div className="typography-header-6">
        Find more leads for {accountName || "this account"}
      </div>

      <p className="mb-8 text-[#666666] typography-body-4">
        Find more leads for{" "}
        <span className="typography-body-4-bold">
          {accountName || "this account"}
        </span>{" "}
        (All filters are optional).
      </p>

      <Formik
        initialValues={form}
        validationSchema={FindMoreLeadsFormValidation}
        onSubmit={onFormSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ errors, touched, submitCount }) => (
          <Form>
            <div className="mb-8">
              <h5 className="mb-3 typography-body-4-bold">
                Filter for these titles
              </h5>
              <EnchancedBadgeInput
                value={titles}
                name="titles"
                placeholder="Add titles to look for"
                errors={errors.titles}
                // NOTE - workaround on touched prop - is not populated on submit
                touched={touched.titles ? touched.titles : submitCount > 0}
              />
            </div>

            <p className="mb-2 typography-body-4-bold">
              Filter for specific name
            </p>

            <div className="flex gap-3">
              <EnchancedTextField
                name="first_name"
                placeholder="First name"
                label="First"
                labelContentClassName="typography-body-4-medium"
                errors={errors.first_name}
                touched={touched.first_name}
              />

              <EnchancedTextField
                name="last_name"
                placeholder="Last name"
                label="Last"
                labelContentClassName="typography-body-4-medium"
                errors={errors.last_name}
                touched={touched.last_name}
              />
            </div>

            <div className="divider my-1" />

            <div className="mt-3">
              <EnchancedTextField
                name="linkedin_url"
                placeholder="linkedin.com/in/personname"
                label="Filter for specific person by Linkedin URL"
                errors={errors.linkedin_url}
                touched={touched.linkedin_url}
              />
            </div>

            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={clsxMerge(
                  "btn-ae-default w-[170px] typography-header-8-semibold",
                  {
                    loading,
                  }
                )}
              >
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
