import _omit from "lodash/omit";

import {
  ActivityLogQCsV2,
  MappedQCsAndPricingResponseI,
  QCsAndPricingResponseI,
  QualificationCriteriaSurveyValuesI,
  QualificationCriteriaTableTogglesValueI,
} from "shared/lib/interfaces/qualification-criteria";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_TYPE_MAP,
} from "shared/lib/constants/qualification-criteria";

export const getQCsAndPricingFromResponse = (
  qcsAndPricingResponse: QCsAndPricingResponseI | undefined
): MappedQCsAndPricingResponseI => {
  if (!qcsAndPricingResponse) {
    return {
      baseChargeAmount: 0,
      bonusChargeAmount: 0,
      companySizeCriteria: "",
      titleCriteria: "",
      industryCriteria: "",
      problemPainCriteria: "",
      timingBudgetCriteria: "",
    };
  }

  return {
    baseChargeAmount:
      qcsAndPricingResponse.qualification_criteria?.base_charge_amount,
    bonusChargeAmount:
      qcsAndPricingResponse.qualification_criteria?.bonus_charge_amount,
    companySizeCriteria:
      qcsAndPricingResponse.qualification_criteria?.company_size_criteria,
    titleCriteria: qcsAndPricingResponse.qualification_criteria?.title_criteria,
    industryCriteria:
      qcsAndPricingResponse.qualification_criteria?.industry_criteria,
    problemPainCriteria:
      qcsAndPricingResponse.qualification_criteria?.problem_pain_criteria,
    timingBudgetCriteria:
      qcsAndPricingResponse.qualification_criteria?.timing_budget_criteria,
  };
};

export const mapSelectedQCsToRequest = (
  selectedQCs: QualificationCriteriaTableTogglesValueI
): QualificationCriteriaSurveyValuesI => ({
  size_met: selectedQCs[QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_SIZE] ?? false,
  title_met:
    selectedQCs[QUALIFICATION_CRITERIA_TYPE_MAP.PROSPECT_TITLE] ?? false,
  industry_met:
    selectedQCs[QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_INDUSTRY] ?? false,
  problem_met: selectedQCs[QUALIFICATION_CRITERIA_TYPE_MAP.PROBLEM] ?? false,
  timing_met: selectedQCs[BONUS_QUALIFICATION_CRITERIA_TYPE] ?? false,
});

export const mapActivityLogQCToSelectedQCs = (
  backendCheckedQCs: ActivityLogQCsV2 | undefined
): QualificationCriteriaTableTogglesValueI => ({
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_SIZE]:
    backendCheckedQCs?.is_size_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROSPECT_TITLE]:
    backendCheckedQCs?.is_title_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_INDUSTRY]:
    backendCheckedQCs?.is_industry_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROBLEM]:
    backendCheckedQCs?.is_problem_pain_met || false,
  [BONUS_QUALIFICATION_CRITERIA_TYPE]:
    backendCheckedQCs?.is_timing_budget_met || false,
});

export const checkIfSelectedQCsQualify = (
  selectedQCs: QualificationCriteriaSurveyValuesI
) => Object.values(_omit(selectedQCs, "timing_met")).every(Boolean);

export const checkIfSelectedQCsQualifyWithBonus = (
  selectedQCs: QualificationCriteriaSurveyValuesI
) => Object.values(selectedQCs).every(Boolean);
