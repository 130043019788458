import { FC, ReactNode } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { NextSeo } from "next-seo";
import { Toaster } from "react-hot-toast";

import { seo } from "lib/seo";
import { AuthProvider } from "lib/auth";

import { GlobalContextProvider } from "@/context/global";
import PrimaryNavigation from "components/navigation/primary";

import { CookieMessageNotifier } from "@/helpers/cookies";
import CustomMeetingsToaster from "components/shared/custom-meetings-toaster";
import { RedirectsProvider } from "lib/auth/redirects-provider";
import { IntercomComponent } from "@/helpers/scripts/intercom";
import { AppLayoutPagePropsI } from "@/interfaces/layout";
import { AppLayoutProvider, useAppLayoutContext } from "@/context/app-layout";
import { WidgetsProvider } from "@/components/shared/widgets";
import { DialerGlobalProvider } from "@/context/dialer-global";
import { queryClient } from "@/api/query-client";
import { LocalStorageProvider } from "@/context/local-storage";
import { ApiClientProvider } from "@/context/api-client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WebsocketsProvider } from "@/context/websockets-context";
import { ListTourProvider } from "@/modules/tours/list/context";
import { ListCallingTourProvider } from "@/components/modules/tours/list-calling/context";
import { ProfileSettingsModule } from "components/modules/settings/profile";
import { AppWideModals } from "@/components/modules/app-wide-modals";

interface AppLayoutPropsI {
  layoutProps: AppLayoutPagePropsI;
  children: ReactNode;
}

type ChildrenWithPageLayoutPropsI = AppLayoutPropsI;

const ChildrenWithPageLayoutProps: FC<ChildrenWithPageLayoutPropsI> = ({
  children,
}) => {
  const { appLayoutPageProps: userDefinedLayoutProps } = useAppLayoutContext();

  const {
    title,
    isCurrentTimeVisible,
    isBackgroundVisible,
    contentContainerClassName,
    pageContainerClassName,
    headerClassName,
    isNavbarHidden,
    isPageHeaderHidden,
    isPageHeaderContained,
    pageHeaderContainerClassName,
  } = userDefinedLayoutProps;

  return (
    <PrimaryNavigation
      title={title}
      isCurrentTimeVisible={isCurrentTimeVisible}
      isBackgroundVisible={isBackgroundVisible}
      contentContainerClassName={contentContainerClassName}
      pageContainerClassName={pageContainerClassName}
      headerClassName={headerClassName}
      isNavbarHidden={isNavbarHidden}
      isPageHeaderHidden={isPageHeaderHidden}
      isPageHeaderContained={isPageHeaderContained}
      pageHeaderContainerClassName={pageHeaderContainerClassName}
    >
      {children}

      <ProfileSettingsModule />
      <AppWideModals />
    </PrimaryNavigation>
  );
};

const AppLayout: FC<AppLayoutPropsI> = ({ children, layoutProps }) => {
  const meta = layoutProps.meta || {};

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <WebsocketsProvider>
          <GlobalContextProvider>
            <RedirectsProvider>
              <LocalStorageProvider>
                <ApiClientProvider>
                  <NextSeo {...seo(meta)} />

                  <IntercomComponent />

                  <DialerGlobalProvider>
                    <ListTourProvider>
                      <ListCallingTourProvider>
                        <WidgetsProvider>
                          <main data-theme="glencoco">
                            <Toaster
                              containerStyle={{
                                top: 80,
                              }}
                              position="top-center"
                            />

                            <CustomMeetingsToaster />

                            <AppLayoutProvider>
                              <ChildrenWithPageLayoutProps
                                layoutProps={layoutProps}
                              >
                                {children}
                              </ChildrenWithPageLayoutProps>
                            </AppLayoutProvider>

                            <CookieMessageNotifier />
                          </main>
                        </WidgetsProvider>
                      </ListCallingTourProvider>
                    </ListTourProvider>
                  </DialerGlobalProvider>
                </ApiClientProvider>
              </LocalStorageProvider>
            </RedirectsProvider>
          </GlobalContextProvider>
        </WebsocketsProvider>
      </AuthProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppLayout;
