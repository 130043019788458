import { getCookie } from "cookies-next";
import { AxiosError } from "axios";

import { checkIfClient, getExpirationTime, parseJwt } from "shared/lib/helpers";
import { DayJs } from "shared/lib/helpers/date";

import { dd } from "@/helpers/datadog";
import {
  COOKIES_GLCC_ACCESS_TOKEN,
  COOKIES_GLCC_NATIVE_ACCESS_TOKEN,
  COOKIES_USE_GLCC_NATIVE_TOKEN,
} from "@/constants/cookies";
import { ERROR_CATEGORIES, ERROR_CODES } from "@/constants/errors";
import Router from "@/helpers/router";
import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";

export const getAuthToken = () => {
  const isNativeToken = getCookie(COOKIES_USE_GLCC_NATIVE_TOKEN);
  const nativeAccessToken = getCookie(COOKIES_GLCC_NATIVE_ACCESS_TOKEN);
  const accessToken = getCookie(COOKIES_GLCC_ACCESS_TOKEN);

  return isNativeToken ? `${nativeAccessToken}` || "" : `${accessToken}` || "";
};

/**
 * Verifies the validity of an access token.
 *
 * This function performs the following checks:
 * 1. Ensures that the access token is not empty.
 * 2. Parses the JWT from the access token and extracts the expiration time.
 * 3. Checks if the token has expired based on the current date.
 * 4. Logs errors if the token is empty, expired, or if any issues occur during processing.
 *
 * @param {string} accessToken - The access token to verify.
 * @returns {boolean} Returns `true` if the token is valid and not expired; `false` otherwise.
 */
export const verifyAccessToken = (accessToken: string) => {
  if (!accessToken) {
    dd.error(`${ERROR_CATEGORIES.AUTH} - Auth0 returned an empty access token`);
    return false;
  }

  try {
    const parsedJwt = parseJwt(accessToken as string);

    const expirationTime = getExpirationTime(parsedJwt);

    const { date: expirationDate } = expirationTime;

    if (DayJs().isAfter(DayJs(expirationDate))) {
      dd.error(
        `${ERROR_CATEGORIES.AUTH} - Auth0 returned already expiredToken`,
        { data: { expirationTime, accessToken, parsedJwt } }
      );

      return false;
    }
  } catch (e) {
    dd.error(
      `${ERROR_CATEGORIES.AUTH} - verifyAccessToken failed to process access token`,
      {
        data: {
          accessToken,
          error: e,
        },
      }
    );

    return false;
  }

  return true;
};

export const logout = () => {
  if (checkIfClient()) {
    window.location.href = Router.logout();
  }
};

/**
 * Removes Auth0-related items from the local storage if the current environment is a client.
 *
 * This function performs the following operations:
 * 1. Checks if the current environment is a client by calling `checkIfClient()`.
 * 2. Iterates over all entries in `localStorage`.
 * 3. Filters out the keys that include the substring "auth0".
 * 4. Removes each of the filtered items from `localStorage`.
 *
 * This is typically used to clean up authentication-related data stored in `localStorage`.
 *
 * @returns {void} This function does not return a value.
 */
export const auth0CleanUser = () => {
  if (checkIfClient()) {
    Object.entries(localStorage)
      .map((arr) => arr?.[0])
      .filter((lsKey) => lsKey?.includes("auth0"))
      .forEach((lsKey) => localStorage?.removeItem(lsKey));
  }
};

/**
 * Use in Axios error interceptors but logic should be stored
 * in context relevant environment
 *
 * @param e
 */
export const redirectOnAxiosErrorInterceptor = (
  e: AxiosError<any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  // Profile blocked
  if (
    e?.response?.data?.error_code === ERROR_CODES.PROFILE_BLOCKED &&
    !window.location.href.includes(Router.profileInactive())
  ) {
    window.location.href = Router.profileInactive();
  }

  // GlenX is unavailable due to using glencoco email
  if (
    e?.response?.data?.error_code === ERROR_CODES.CANNOT_BE_GLENX_USER &&
    !window.location.href.includes(Router.cannotBeGlenXUser())
  ) {
    window.location.href = Router.cannotBeGlenXUser();
  }

  // Unauthorized
  // if (
  //   e?.response?.data?.error_code === ERROR_CODES.UNAUTHORIZED &&
  //   !window.location.href.includes(Router.unauthorized())
  // ) {
  //   window.location.href = Router.unauthorized();
  // }
};
