import { FC, PropsWithChildren, useState } from "react";
import { AxiosResponse } from "axios";

import {
  EmailAutomationContext,
  UseEmailAutomationConfigQueryI,
  UseEmailAutomationSuggestedDomainsQueryI,
} from "./index";

import {
  EmailAutomationSuggestedDomainI,
  PurchaseDomainRequestParamsI,
  PurchaseDomainResponseI,
  UpdateEmailAutomationConfigParamsI,
} from "shared/lib/interfaces/email/automation";
import { MutationResultI } from "shared/lib/interfaces/react-query";

interface EmailAutomationProviderPropsI extends PropsWithChildren {
  campaignId: string;

  fetchEmailAutomationConfigApi: UseEmailAutomationConfigQueryI;
  updateEmailAutomationConfigApi: MutationResultI<
    AxiosResponse,
    UpdateEmailAutomationConfigParamsI
  >;

  fetchSuggestedEmailAutomationDomainsApi: UseEmailAutomationSuggestedDomainsQueryI;
  purchaseEmailAutomationDomainApi: MutationResultI<
    AxiosResponse<PurchaseDomainResponseI>,
    PurchaseDomainRequestParamsI
  >;

  selectedDomain: EmailAutomationSuggestedDomainI | undefined;
  setSelectedDomain: (domain: EmailAutomationSuggestedDomainI) => void;

  enableSuggestedDomainsApi: () => void;
}

export const EmailAutomationBaseProvider: FC<EmailAutomationProviderPropsI> = ({
  campaignId,
  children,
  fetchEmailAutomationConfigApi,
  updateEmailAutomationConfigApi,
  fetchSuggestedEmailAutomationDomainsApi,
  purchaseEmailAutomationDomainApi,
  enableSuggestedDomainsApi,
}) => {
  const { mutateAsync: updateConfigAsync } = updateEmailAutomationConfigApi;

  const { mutateAsync: purchaseDomainAsync } = purchaseEmailAutomationDomainApi;

  const [selectedDomain, setSelectedDomain] =
    useState<EmailAutomationSuggestedDomainI>();

  /**
   * The id of the domain that is being purchased
   */
  const [purchasingDomainId, setPurchasingDomainId] = useState<string>();

  const updateConfig = async (data: UpdateEmailAutomationConfigParamsI) => {
    const resp = await updateConfigAsync(data);

    return resp.status === 200;
  };

  const purchaseDomain = async (data: PurchaseDomainRequestParamsI) => {
    const purchaseDomainResponse = await purchaseDomainAsync(data);

    // Simulate API call with delay
    // const resp: { status: number; data: { domain_id: string } } =
    //   await new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve({
    //         status: 200,
    //         data: {
    //           domain_id: "123",
    //         },
    //       });
    //     }, 2000);
    //   });

    if (purchaseDomainResponse.status === 200) {
      setPurchasingDomainId(purchaseDomainResponse.data.domain_id);
    }

    return undefined;
  };

  return (
    <EmailAutomationContext.Provider
      value={{
        currentCampaignId: campaignId,

        emailConfigApi: fetchEmailAutomationConfigApi,
        updateConfig,

        selectedDomain,
        setSelectedDomain,
        purchasingDomainId,
        enableSuggestedDomainsApi,
        suggestedDomainsApi: fetchSuggestedEmailAutomationDomainsApi,
        purchaseDomain,
      }}
    >
      {children}
    </EmailAutomationContext.Provider>
  );
};
