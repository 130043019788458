import { AxiosInstance } from "axios";
import {
  GetEmailInboxCountersResponseI,
  GetEmailInboxRequestI,
  GetEmailInboxResponseI,
  GetEmailThreadResponseI,
} from "shared/lib/interfaces/email/inbox";

export interface EmailTemplateI {
  id?: string;
  name?: string;
  is_custom?: boolean;
  prompt?: string;
  attach_one_pager?: boolean;
  attachments?: AttachmentI[];
}

export interface AttachmentI {
  id: string;
  name: string;
}

export interface GetEmailTemplatesListResponseI {
  email_templates: EmailTemplateI[];
}

export interface CreateEmailTemplateRequestParamsI {
  name: string;
  prompt: string;
  attach_one_pager: boolean;
}

export interface CreateEmailTemplateResponseI {
  email_template: EmailTemplateI;
}

export interface CheckCampaignEmailSetupResponseI {
  is_email_setup: boolean;
}

export interface UpdateEmailTemplateRequestParamsI
  extends CreateEmailTemplateRequestParamsI {}

export interface GetEmailGenerationInfoRequestParamsI {
  /**
   * template id to use for email generation
   * as for example of email generation, template id is not needed
   * but if you want to use a specific template for email generation, you can pass the template id
   */
  email_template_id?: string;
  campaign_id: string;
  contact_id: string;
  account_id: string;
}

export interface GetEmailGenerationStatusResponseI {
  is_complete?: boolean;
  created_at?: string;
  subject?: string;
  body?: string;
  attachments?: AttachmentI[];
}

export interface ValidateEmailsRequestParamsI {
  emails: string[];
}

export interface ValidateEmailsResponseI {
  valid_emails: string[];
  invalid_emails: string[];
}

export interface EmailParticipantsRequestParamsI {
  to: string[];
  cc?: string[];
  bcc?: string[];
  max_follow_ups: number;
  follow_up_interval_days: number;
}

export interface SendEmailRequestParamsI
  extends EmailParticipantsRequestParamsI {
  contact_id: string;
  account_id: string;
  campaign_id: string;

  subject: string;
  body?: string; //plain_text
  // // @deprecated
  raw_body?: string; //html_text
  attachments: string[];

  plain_body: string;
  html_body: string;

  //Note: You can get it from the disposition response
  email_id?: string;
}

export interface GetEmailGenerationInfoResponseI {
  campaign_id: string;
  from_email: string;
  contact_email: string;
  account_id: string;
  contact_id: string;
  contact_name: string;
  contact_title: string;
  account_name: string;
  account_industry: string;
  account_city: string;
  account_state: string;
  account_headcount: string;
  account_website: string;
  email_signature: string;
  user_prompt: string;
}
export interface GetEmailConfigResponseI {
  signature: string;
}

export interface UpdateEmailConfigRequestI {
  signature: string;
}

export const EmailAPIEndpoints = (api: AxiosInstance) => ({
  getEmailTemplatesList: () =>
    api.get<GetEmailTemplatesListResponseI>(`/v1/email_template/list`),

  checkCampaignHasEmailAutomation: (campaignId: string) =>
    api.get<CheckCampaignEmailSetupResponseI>(
      `/v2/campaign/${campaignId}/email_is_setup`
    ),

  createEmailTemplate: (params: CreateEmailTemplateRequestParamsI) =>
    api.post<CreateEmailTemplateResponseI>(`/v1/email_template`, params),

  updateEmailTemplate: (
    templateId: string,
    params: UpdateEmailTemplateRequestParamsI
  ) => api.patch(`/v1/email_template/${templateId}`, params),

  deleteEmailTemplate: (templateId: string) =>
    api.delete(`/v1/email_template/${templateId}`),

  getEmailGenerationInfo: (
    templateId?: string,
    params?: GetEmailGenerationInfoRequestParamsI
  ) => {
    const templateIdParam = templateId ? { email_template_id: templateId } : {};

    return api.post<GetEmailGenerationInfoResponseI>(
      `/v1/email/fetch_info_for_email_generation`,
      { ...params, ...templateIdParam }
    );
  },

  validateEmails: (params: ValidateEmailsRequestParamsI) =>
    api.post<ValidateEmailsResponseI>(
      `/v1/email/confirm_email_address`,
      params
    ),

  sendEmail: (params: SendEmailRequestParamsI) => {
    const campaignId = params?.campaign_id;

    return api.post(`/v2/campaign/${campaignId}/email/send`, params);
  },

  getInbox: ({
    inbox_type,
    campaign_ids,
    search_term,
    next_token,
  }: GetEmailInboxRequestI) =>
    api.post<GetEmailInboxResponseI>("/v1/inbox", {
      inbox_type,
      campaign_ids,
      next_token,
      search_term: search_term || undefined,
    }),

  getInboxThread: (threadId: string, nextToken?: string | null) =>
    api.post<GetEmailThreadResponseI>(`/v1/inbox/thread/${threadId}`, {
      next_token: nextToken,
    }),

  getInboxCounters: (campaignIds: string[]) =>
    api.post<GetEmailInboxCountersResponseI>("/v1/inbox_stats", {
      campaign_ids: campaignIds,
    }),

  getEmailConfig: () => api.get<GetEmailConfigResponseI>(`/v1/email_config`),

  updateEmailConfig: (params: UpdateEmailConfigRequestI) =>
    api.patch(`/v1/email_config`, params),
});
