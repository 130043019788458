// NOTE - extend only in the case when you specifically need to target a specific error
// WHY - code readability
export const ERROR_CODES = {
  OVER_50_BACKLOG_ITEMS: 1071,
  NO_CONTACTS_TO_CALL: 1073,
  PROFILE_BLOCKED: 1008,
  CANNOT_BE_GLENX_USER: 1136,
  NO_CURRENT_WORKING_CONTACT: 1096,
  USER_NOT_APPROVED_FOR_CAMPAIGN: 1099,
  UNAUTHORIZED: 1001,
  INVALID_PHONE_NUMBER: 1135,
} as const;

export const GLOBALLY_HANDLED_ERRORS = {
  [ERROR_CODES.USER_NOT_APPROVED_FOR_CAMPAIGN]:
    "You're not approved to work on this campaign.",
} as const;

export const ERRORS = {
  [ERROR_CODES.PROFILE_BLOCKED]: "This account is not allowed on the platforms",
  [ERROR_CODES.UNAUTHORIZED]: "Unauthorized",
  [1110 as number]: "This campaign isn't available for weekend calling",
  [1026 as number]: "You already have an active session open",
  [1037 as number]: "This lead already exists in our system",
  [1068 as number]:
    "This company is not eligible to be reached out to for this campaign. Sorry!",
  [1069 as number]: "Someone is already working this lead! Sorry.",
  [1070 as number]: "This prospect or account is on the do not call list",
  [ERROR_CODES.OVER_50_BACKLOG_ITEMS]:
    "Backlog limit (50 items) reached. Please remove some items from the backlog to add more.",
  [1072 as number]: "Contact view limit reached",
  [ERROR_CODES.NO_CONTACTS_TO_CALL]: "No contacts to call",
  [1070 as number]:
    "This prospect has specifically requested to be put on the DNC list. Sorry!",
  [1074 as number]: "This campaign does not allow self-sourced leads.",
  [ERROR_CODES.NO_CURRENT_WORKING_CONTACT]: "No current working contact.",
  [1124 as number]:
    "There already exists an ongoing email chain with this prospect.",
  [1127 as number]:
    "The prospect requested not to email. Please respect their request.",
  [ERROR_CODES.INVALID_PHONE_NUMBER]: "Please provide a valid phone number.",
  [1137 as number]:
    "You've reached the maximum invalid email overrides for this week. Please try again next week.",
} as const;

/** - - - - - - - - - - - SOCKET ERRORS  - - - - - - - - - - - */

export const SOCKET_ERROR_CODES_GROUP_DIALER_LIST = {
  START_SESSION: 1112,
  LEAD_ON_CALL_ALREADY: 1113,
  MEMBERSHIP_ID_NOT_FOUND: 1114,
} as const;

export const SOCKET_ERROR_CODES = {
  GENERAL: 999,

  /**
   * Dialer List
   */
  ...SOCKET_ERROR_CODES_GROUP_DIALER_LIST,
} as const;

export const SOCKET_ERRORS = {
  [SOCKET_ERROR_CODES.GENERAL as number]:
    "Something went wrong. Please reload the page",

  /**
   * Dialer List
   */
  [SOCKET_ERROR_CODES.START_SESSION]:
    "Need to start list dialing session first",
  [SOCKET_ERROR_CODES.LEAD_ON_CALL_ALREADY]: "Lead is already in a call",
  [SOCKET_ERROR_CODES.MEMBERSHIP_ID_NOT_FOUND]:
    "Lead's membership id is not found",
} as const;

/** - - - - - - - - - - - LOGGING ERROR CATEGORIES  - - - - - - - - - - - */

export const ERROR_CATEGORIES = {
  DIALER_LIST: `[DIALER LIST][ERROR]`,
  AUTH: `[AUTHENTICATION][ERROR]`,
  TWILIO: `[TWILIO_CUSTOM][ERROR]`,
  DISPOSITION: `[DISPOSITION][ERROR]`,
  WS: `[WEBSOCKETS][ERROR]`,
  LEAD: `[LEAD][ERROR]`,
  BOOK_MEETING: "[BOOK_MEETING][ERROR]",
} as const;
