// React imports
import { FC, useCallback } from "react";

// Shared lib imports
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";

// Internal app imports
import { ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID } from "@/components/modules/pipeline/account-details/modals";
import { AccountDetailsLeadItem } from "@/components/modules/pipeline/account-details/sections/leads-section/lead-item";
import { ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME } from "@/modules/pipeline/account-details/constants";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

const ACCOUNT_STATUSES_PROTECTED_FROM_DELETE = [
  ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED,
  ACCOUNT_DISPOSITION_STATUSES.NURTURE,
];

const openAddLeadModal = () =>
  modalHelpers.open(ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID);

const Header = () => {
  return (
    <div className="flex w-full items-center justify-between">
      <h6>Leads</h6>

      <button
        className={ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME}
        onClick={openAddLeadModal}
      >
        Add
      </button>
    </div>
  );
};

export const LeadsSection: FC<PropsWithClassNameI> = ({ className }) => {
  const { accountStatus, contacts, accountDetailsApi } =
    useAccountDetailsContext();

  const { contact: callingContact } = useInCallContext();

  // make sure contact doesn't have any significant upcoming events like a booked meeting
  const checkIfContactDeletionDisabled = useCallback(
    (contact: ContactDetailI) =>
      accountStatus?.status &&
      accountStatus?.primary_contact_id === contact.id &&
      ACCOUNT_STATUSES_PROTECTED_FROM_DELETE.find(
        (status) => status === accountStatus?.status
      ),
    [accountStatus]
  );

  return (
    <AccountDetailsSectionContainer
      title={<Header />}
      className={className}
      contentClassName="py-0"
    >
      <DataStatesWrapper
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[160px]" />}
      >
        {contacts && (
          <div className="flex flex-col divide-y">
            {contacts.map((contact) => (
              <AccountDetailsLeadItem
                key={contact.id}
                contact={contact}
                isCurrentlyCalling={callingContact?.id === contact?.id}
                className="lg:min-w-none py-3"
                isDeleteContactAllowed={
                  contacts.length > 1 &&
                  !checkIfContactDeletionDisabled(contact)
                }
              />
            ))}
          </div>
        )}
      </DataStatesWrapper>
    </AccountDetailsSectionContainer>
  );
};
