import { FC, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface LabelValuePropsI extends PropsWithClassNameI {
  label?: string;
  value?: string | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
}

export const createRecord = (
  label?: string,
  value?: string | ReactNode,
  valueClassName?: string
) => ({ label, value, valueClassName });

export const LabelValue: FC<LabelValuePropsI> = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName,
}) => {
  return value === null ? null : (
    <div className={clsxMerge("flex flex-col", className)}>
      <span
        className={clsxMerge(
          "mb-1 line-clamp-1 break-words font-medium",
          {
            "brand-typography-body1": !valueClassName?.includes("typography"),
          },
          valueClassName
        )}
        title={typeof value === "string" ? value : undefined}
      >
        {value}
      </span>

      <span
        className={clsxMerge(
          "text-[#333]",
          {
            "brand-typography-detail2": !labelClassName?.includes("typography"),
          },
          labelClassName
        )}
      >
        {label}
      </span>
    </div>
  );
};

export const LabelValueV2 = ({
  label,
  value,
  className,
  valueClassName,
  labelClassName,
}: LabelValuePropsI) => {
  return value === null ? null : (
    <div className={clsxMerge("flex flex-col", className)}>
      <span
        className={clsxMerge(
          "text-[#666] ",
          { "typography-body-5": !labelClassName?.includes("typography") },
          labelClassName
        )}
      >
        {label}
      </span>
      <span
        className={clsxMerge(
          "break-words text-[#18181B] ",
          {
            "typography-header-8-semibold":
              !valueClassName?.includes("typography"),
          },
          valueClassName
        )}
      >
        {value}
      </span>
    </div>
  );
};
