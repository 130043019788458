import { AxiosInstance } from "axios";
import { DefaultResponseI } from "shared/lib/interfaces/api";

export interface UpdateCallSettingsParamsI {
  call_forwarding_disabled?: boolean;
  phone_number?: string;
}

export interface GetCooldownHoursResponseI {
  call_cooldown_hours: number | null;
}

export const CallSettingsAPIEndpoints = (client: AxiosInstance) => ({
  update: (settings: UpdateCallSettingsParamsI) =>
    client.patch("/v1/settings", { settings }),

  fetchCooldownHours: () =>
    client.get<GetCooldownHoursResponseI>("/x/v1/dialer_settings"),

  setCooldownHours: (cooldownHours: number) =>
    client.patch<DefaultResponseI>("/x/v1/dialer_settings", {
      call_cooldown_hours: cooldownHours,
    }),
});
