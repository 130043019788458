import {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  FC,
} from "react";

import { useEffectOnce } from "shared/lib/hooks";

import { UserI } from "@/interfaces/user";
import { CampaignI } from "@/interfaces/campaign";
import { GetUserResponseI, glencocoClientAPI } from "@/api/glencoco";
import { AsyncGetI, asyncGet } from "@/helpers/context";
import { GlobalNotificationsProvider } from "@/context/notifications-context";
import { SessionStorage } from "@/helpers/session-storage";
import { UserOnboardingStatusI } from "@/interfaces/signup";

export interface DefaultGlobalContextI {
  isUserLoaded?: boolean;
  glencocoUser?: UserI;
  userOnboardingStatus?: UserOnboardingStatusI;
  currentCampaign?: CampaignI;
  campaigns?: Array<CampaignI>;
  intercom?: any;

  asyncGet?: AsyncGetI;
  reloadUser?: () => Promise<void>;

  isSingleUserLicenseSubscriptionActive?: boolean;
}

export interface GlobalContextI extends DefaultGlobalContextI {
  asyncGet: AsyncGetI;
}

interface GlobalContextProviderPropsI extends PropsWithChildren {
  props?: DefaultGlobalContextI;
}

const GlobalContext = createContext<DefaultGlobalContextI>({});

export const GlobalContextProvider: FC<GlobalContextProviderPropsI> = ({
  children,
  ...props
}) => {
  const typedProps = props as DefaultGlobalContextI;

  const [
    isSingleUserLicenseSubscriptionActive,
    setIsSingleUserLicenseSubscriptionActive,
  ] = useState(false);

  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [user, setUser] = useState(typedProps?.glencocoUser);
  const [userOnboardingStatus, setUserOnboardingStatus] = useState(
    typedProps?.userOnboardingStatus
  );
  const [currentCampaign, setCurrentCampaign] = useState(
    typedProps?.currentCampaign
  );
  // const [campaigns, setCampaigns] = useState(typedProps?.campaigns);
  const [intercom, setIntercom] = useState(typedProps?.intercom);

  const reloadUser = async () => {
    const storage = new SessionStorage();

    const API = glencocoClientAPI();

    const sessionUser = storage.user;

    if (sessionUser) {
      setUser(sessionUser);
    }

    // NOTE - set the current user ---------------------------
    const userPromise = API.user
      .getUser()
      .then((userResponse) => {
        if (userResponse.status === 200) {
          const data = userResponse?.data as GetUserResponseI;

          storage.user = data?.user;

          setUser(data?.user);
          setCurrentCampaign(data?.campaign);
          setIntercom(data?.intercom_payload);
          setUserOnboardingStatus(data?.user_onboarding_status);
          setIsSingleUserLicenseSubscriptionActive(
            data?.is_user_subscription_active
          );
        }
      })
      .catch((e) => e);

    // NOTE - Get campaigns user can start calling to ---------------------------
    // It's not needed here anymore, as glenX user is attached to just one campaign
    // const campaignsPromise = requestAndMergeCampaigns({
    //   filter: {
    //     custom: {
    //       is_approved_to_call: true,
    //     },
    //   },
    // });

    userPromise
      .then(() => {
        setTimeout(() => {
          setIsUserLoaded(true);
        }, 100);
      })
      .catch(() => {
        setIsUserLoaded(true);
      });
    /** ----------------------- END ----------------------- */
  };

  const [gc, setGlobalContext] = useState({
    ...props,
    setIsUserLoaded,
    setUser,
    // setCampaigns,
    setIntercom,
    setUserOnboardingStatus,

    asyncGet,
    reloadUser,

    isSingleUserLicenseSubscriptionActive,
  } as GlobalContextI);

  useEffectOnce(() => {
    void reloadUser();
  });

  useEffect(() => {
    setGlobalContext({
      ...gc,
      glencocoUser: user,
      currentCampaign,
      isUserLoaded,
      // campaigns,
      intercom,
      userOnboardingStatus,
      isSingleUserLicenseSubscriptionActive,
    });
  }, [
    user,
    isUserLoaded,
    currentCampaign,
    // campaigns,
    intercom,
    userOnboardingStatus,
    isSingleUserLicenseSubscriptionActive,
  ]);

  return (
    <GlobalContext.Provider value={gc}>
      <GlobalNotificationsProvider>{children}</GlobalNotificationsProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
