import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import noop from "lodash/noop";

import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { useLocalStorage } from "@/context/local-storage";

interface ListTourContextI {
  isTourOpen: boolean;
  openTour: () => void;
  closeTour: () => void;
  isTourCompleted: boolean;
  setTourCompleted: (isCompleted: boolean) => void;
}

const ListTourContext = createContext<ListTourContextI>({
  isTourOpen: false,
  openTour: noop,
  closeTour: noop,
  isTourCompleted: false,
  setTourCompleted: noop,
});

export const useListTourContext = () => {
  const context = useContext(ListTourContext);

  if (!context) {
    throw new Error(
      "useListTourContext must be used within a ListTourProvider"
    );
  }

  return context;
};

export const ListTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const localStorage = useLocalStorage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isTourCompleted, setIsTourCompleted] = useState(false);

  useEffect(() => {
    if (localStorage.listTourCompletionDate) {
      setIsTourCompleted(true);
    }
  }, []);

  const setTourCompleted = (isCompleted: boolean) => {
    setIsTourCompleted(isCompleted);

    if (isCompleted) {
      localStorage.listTourCompletionDate = new Date().toString();
    } else {
      localStorage.listTourCompletionDate = "";
    }
  };

  return (
    <ListTourContext.Provider
      value={{
        isTourOpen: isOpen,
        openTour: onOpen,
        closeTour: onClose,
        isTourCompleted,
        setTourCompleted,
      }}
    >
      {children}
    </ListTourContext.Provider>
  );
};
