import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";
import { dispatchCustomEvent } from "@/helpers/events";
import { ListDialingWebsocketEventI } from "@/interfaces/list-dialing";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";

/**
 * SOCKET MESSAGES RECEIVE TYPE ONLY
 */
export const dialerListSocketEventHandler = {
  /**
   *
   * The function is designed to update the status of a call based on received data.
   * It triggers a custom event with the event type CALL_STATUS_UPDATE
   * and includes the relevant data from the WebSocket event in the dispatched event.
   *
   * @param data
   * @param data.type - one of WEBSOCKET_DIALER_LIST_EVENT_TYPES
   * @param data.data - ListMemberUpdateMessageI
   * @param data.data.membership_id - string;
   * @param data.data.status - one of DIALER_LIST_DAILING_STATUS;
   * @param data.data.cooldown_until - string;
   * @param data.data.do_not_call - string;
   *
   */
  updateCallStatus: (data: ListDialingWebsocketEventI) => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
      data: data.data,
    });

    if (data?.data?.status === DIALER_LIST_DIALING_STATUS.CONNECTED) {
      dd.rum.log(
        `${LOG_CATEGORIES.DIALER_LIST}[WS][RECEIVE][CONNECTED LEAD] - socket message to set connected lead`,
        { data: data.data }
      );

      return;
    }

    dd.rum.log(
      `${LOG_CATEGORIES.DIALER_LIST}[WS][RECEIVE] - updated call status`,
      { data: data.data }
    );
  },
};
