import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { CallsMapI, useWSListDialing } from "./use-ws-list-dialing";

import uniqBy from "lodash/uniqBy";
import take from "lodash/take";
import async from "async";

import { ValueOf } from "next/constants";
import { asyncGet } from "@/helpers/context";
import { LocalStorage } from "@/helpers/local-storage";

import { DayJs } from "shared/lib/helpers/date";
import { glencocoClientAPI } from "@/api/glencoco";
import { GetListMemberResponseI } from "@/api/routes/list";

import { datadogRum } from "@datadog/browser-rum";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { ListMemberUpdateMessageI } from "@/interfaces/list-dialing";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";
import { getAccountDetailsData } from "@/helpers/account-details";
import { produce } from "immer";
import { dispatchCustomEvent } from "@/helpers/events";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";
import { PipelineListContactI } from "shared/lib/interfaces/lists";

/**
 * If leads quantity is less than this through event
 */
export const LEADS_COUNT_THRESHOLD = 20;

/**
 * As the name implies
 */
const DEFAULT_MAX_LEADS_DIALING = 3;

/**
 * Used as a trigger to check lead status
 */
export const SOFT_CALL_TIMEOUT_SECONDS = 30;

/**
 * Used as a final safeguard for the scenario when lead is
 * returned from the BE as undefined and is basically may
 * be indefinitely reside in dialing state forever
 * so we have to manually move it to the processed
 */
export const HARD_CALL_TIMEOUT_SECONDS = 60;

export type LeadsMapI = Map<string, PipelineListContactI>;
export type CallsMetadataMapI = Map<string, { startDialingAt?: string }>;
export type AccountDetailsMapI = Map<string, GetAccountDetailsDataI>;

export interface HookLeadsQueueManagerI {
  isListenWS?: boolean;
  setIsListenWS?: Dispatch<SetStateAction<boolean>>;

  calls?: CallsMapI;
  callsMetadataMap?: CallsMetadataMapI;
  accountDetailsMap?: AccountDetailsMapI;
  leadsMap: LeadsMapI;
  leadsQueue: PipelineListContactI[];
  leadsDialing: PipelineListContactI[];
  leadsProcessed: PipelineListContactI[];
  connectedLead?: PipelineListContactI;
  maxLeadsDialing: number;

  setLeadsMap: Dispatch<SetStateAction<LeadsMapI>>;
  setAccountDetailsMap: Dispatch<SetStateAction<AccountDetailsMapI>>;
  setCalls: Dispatch<SetStateAction<CallsMapI>>;
  setLeadsQueue: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setLeadsDialing: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setLeadsProcessed: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setConnectedLead: Dispatch<SetStateAction<PipelineListContactI | undefined>>;
  setMaxLeadsDialing: Dispatch<SetStateAction<number>>;

  isQueuePaused: boolean;
  /**
   * Means if no leads to call for now
   */
  isIdle?: boolean;

  setIsQueuePaused: Dispatch<SetStateAction<boolean>>;
  setIsIdle: Dispatch<SetStateAction<boolean>>;
  pauseQueue: () => void;
  resumeQueue: () => void;

  clear: () => void;

  addToLeadsQueue: (_leads: PipelineListContactI[]) => void;
  moveToDialingList: (
    onSuccess?: (leads: PipelineListContactI[]) => void
  ) => Promise<void>;
  isFreeSpotForDialing: (leadsDialing?: PipelineListContactI[]) => boolean;
  saveCallDate: (membershipId: string) => Promise<void>;
  processTimedoutCalls: () => Promise<void>;
  updateConnectedCall: (membershipId: string) => Promise<void>;
  syncLeadsWithUpdatedList: (leadsToSync: PipelineListContactI[]) => void;
}

const assignLeadStatus = (
  lead: PipelineListContactI,
  status: ValueOf<typeof DIALER_LIST_DIALING_STATUS>
): PipelineListContactI => {
  return lead ? { ...lead, status } : lead;
};

const selectorValidLeads = (leads: PipelineListContactI[], listId?: string) => {
  return uniqBy(leads, "list_membership_id").filter((lead) => {
    return !(lead.do_not_call || (listId && lead.list_id !== listId));
  });
};

/**
 * Custom hook to manage the leads queue for dialing.
 *
 * @param {PipelineListContactI[]} [leads] - Initial list of leads to be added to the queue.
 * @returns {Object} - Returns an object containing leads queue state and related functions.
 */
export const useLeadsQueueManager = ({
  leads,
  listId,
}: {
  leads?: PipelineListContactI[];
  listId?: string;
}): HookLeadsQueueManagerI => {
  /**
   * Manages additional information related to calls, separated from the main calls map to avoid unnecessary
   * side effects, re-renders, and to distinguish between backend data and frontend UI-related logic.
   *
   * The primary focus is on the `startDialingAt` property, which indicates when to timeout dialing a lead if the
   * backend has not returned the appropriate message.
   *
   */
  const [callsMetadataMap, setCallsMetadataMap] = useState<CallsMetadataMapI>(
    new Map()
  );

  /**
   * Manages additional information related to the lead, for every dialing lead there is prefetched account details
   * that is going to be passed to MAXED DIALER widget as soon as it is connected to the prospect
   */
  const [accountDetailsMap, setAccountDetailsMap] =
    useState<AccountDetailsMapI>(new Map());

  const [maxLeadsDialing, setMaxLeadsDialing] = useState(
    DEFAULT_MAX_LEADS_DIALING
  );

  const [leadsQueue, setLeadsQueue] = useState<PipelineListContactI[]>(
    leads || []
  );
  const [leadsDialing, setLeadsDialing] = useState<PipelineListContactI[]>([]);
  const [leadsProcessed, setLeadsProcessed] = useState<PipelineListContactI[]>(
    []
  );
  const [connectedLead, setConnectedLead] = useState<PipelineListContactI>();

  /**
   * State of not processing leads basically when run out of leads
   * and widget is closed
   */
  const [isIdle, setIsIdle] = useState(false);

  const [leadsMap, setLeadsMap] = useState<LeadsMapI>(new Map());
  useEffect(
    () =>
      setLeadsMap((prevLeadsMap) => {
        const newLeadsMap = new Map(prevLeadsMap);

        leadsQueue.forEach((lead) => {
          newLeadsMap.set(lead.list_membership_id, lead);
        });

        return newLeadsMap;
      }),

    [leadsQueue]
  );

  /**
   * Handles updates for call status by logging any issues when membership_id is missing from the leads queue.
   *
   * @async
   * @function logOnCallUpdate
   * @param {ListMemberUpdateMessageI} data - The data object containing the call information and membership ID.
   * @returns {Promise<void>} - A promise that resolves when the process completes.
   */
  const logOnCallUpdate = async (data: ListMemberUpdateMessageI) => {
    if (data.membership_id) {
      const allLeads = await asyncGet(setLeadsMap);

      if (data.status === DIALER_LIST_DIALING_STATUS.CONNECTED) {
        (window as any).GLOBAL_DEBUG_CALL_CONNECTED_AT =
          new Date().toISOString();
      }

      if (!allLeads.get(data.membership_id)) {
        dd.rum.error(
          `${ERROR_CATEGORIES.DIALER_LIST} - membership_id is not in the leads queue`,
          {
            data: {
              listId,
              call: data,
            },
          }
        );
      }
    }
  };
  const { calls, setCalls, isListenWS, setIsListenWS } = useWSListDialing({
    onIncomingCallStatusUpdate: logOnCallUpdate,
  });

  /**
   * Get max dialing leads at once from local storage
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (LS.dialerGlobalMaxLeadsDialing) {
      setMaxLeadsDialing(LS.dialerGlobalMaxLeadsDialing);
    }
  }, []);

  /**
   * useEffect hook to update the local storage whenever maxLeadsDialing state changes.
   *
   * Save max dialing leads at once to local storage
   *
   * @param {number} maxLeadsDialing - The current state of maxLeadsDialing.
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (maxLeadsDialing) {
      LS.dialerGlobalMaxLeadsDialing = maxLeadsDialing;
    }
  }, [maxLeadsDialing]);

  /**
   * State variable to track if the queue is paused
   * Current applicable solution is the call is connected
   * on dialer list
   * @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
   */
  const [isQueuePaused, setIsQueuePaused] = useState(false);

  /**
   * Pauses the queue by setting isQueuePaused to true.
   */
  const pauseQueue = () => {
    setIsQueuePaused(true);
  };

  /**
   * Resumes the queue by setting isQueuePaused to false.
   */
  const resumeQueue = () => {
    setIsQueuePaused(false);
  };

  /**
   * Update valid leads on hook arg update
   */
  useEffect(() => {
    if (!leads) return;

    setLeadsQueue((leadsQueue) =>
      selectorValidLeads([...leadsQueue, ...leads])
    );
  }, [leads]);

  /**
   * Update valid leads on list id change
   */
  useEffect(() => {
    if (!leadsQueue?.length || !listId) return;

    setLeadsQueue((leadsQueue) => selectorValidLeads(leadsQueue, listId));
  }, [listId]);

  /**
   * Adds a list of leads to the leads queue and filter out invalid.
   *
   * @param {PipelineListContactI[]} _leads - List of leads to be added to the queue.
   */
  const addToLeadsQueue = useCallback(
    (_leads: PipelineListContactI[]) => {
      setLeadsQueue((leads) =>
        selectorValidLeads([...leads, ..._leads], listId)
      );
    },
    [listId]
  );

  useEffect(() => {
    if ((leadsQueue?.length || 0) < LEADS_COUNT_THRESHOLD) {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.LIST_DIALING.LEADS_RUNNING_OUT,
      });
    }
  }, [leadsQueue?.length]);

  /**
   * NOTE leads extracted from the queue that are
   * still not in dialing list
   * waiting for the first socket messages are in "limbo" state
   */

  /**
   * Checks if there is a free spot available for dialing.
   *
   * @param {PipelineListContactI[]} activeDilaingLeads - List of leads that are currently being dialed.
   * @returns {boolean} - Returns true if there is a free spot for dialing, false otherwise.
   */
  const isFreeSpotForDialing = useCallback(
    (paramLeadsDialing?: PipelineListContactI[]) => {
      const leads = paramLeadsDialing || leadsDialing;

      return leads?.length < maxLeadsDialing;
    },
    [leadsDialing]
  );

  /**
   * Checks if there is a free spot in dialing list
   *
   * Fills remaining free spots in leadsDialing list with leads from leadsQueue
   *
   * Note to make it work inside setInterval it is required to async get leadsQueue & leadsDialing
   *
   * @param {PipelineListContactI[]} leadsQueue
   * @param {PipelineListContactI[]} leadsDialing
   * @param onSuccess - callback to facilitate action after leads have being added to the leadsDialing
   */
  const moveToDialingList = async (
    onSuccess?: (leads: PipelineListContactI[]) => void
  ) => {
    const leadsQueue = await asyncGet(setLeadsQueue);
    const leadsDialing = await asyncGet(setLeadsDialing);
    const maxLeadsDialing = await asyncGet(setMaxLeadsDialing);

    // Calculate how many more leads can be added to dialing list
    const freeSpotsCount = maxLeadsDialing - leadsDialing.length || 0;

    // Take leads from queue up to free spots count
    // Map them to add dialing status
    // Filter out any leads without membership IDs
    const leads = take(leadsQueue, freeSpotsCount)
      .map((l) => ({
        ...l,
        status: DIALER_LIST_DIALING_STATUS.DIALING,
      }))
      .filter((l) => !!l?.list_membership_id);

    // Add the selected leads to the dialing list
    setLeadsDialing((leadsDialing) => [...leadsDialing, ...leads]);

    // Remove the selected leads from the queue by filtering out
    // any leads whose membership IDs match the ones moved to dialing
    setLeadsQueue((leadsQueue) =>
      leadsQueue.filter(
        (leadQueue) =>
          !leads
            .map((leadDialing) => leadDialing.list_membership_id)
            .includes(leadQueue.list_membership_id)
      )
    );

    onSuccess?.(leads);
  };

  /**
   * Updates the status of a connected call based on incoming messages from Twilio.
   *
   * This function takes a membership ID as input, retrieves the associated lead from the leads map,
   * and updates the call status accordingly. If there are other calls marked as connected,
   * their statuses are updated to "cancelled".
   *
   * @async
   * @function updateConnectedCall
   * @param {string} membershipId - The membership ID of the lead for which the call status needs to be updated.
   * @returns {Promise<void>} - Resolves when the call status is updated.
   */
  const updateConnectedCall = async (membershipId: string) => {
    if (!membershipId) return;

    const leadsMap = await asyncGet(setLeadsMap);

    const lead = leadsMap.get(membershipId);

    if (!lead) return;

    setCalls((prevCallsMap) => {
      const callsMap = new Map(prevCallsMap);
      const calls = Array.from(callsMap, ([, call]) => call);

      const connectedCallMembershipIds = calls
        .filter((call) => call.status === DIALER_LIST_DIALING_STATUS.CONNECTED)
        .map((c) => c.membership_id);

      connectedCallMembershipIds.forEach((mId) => {
        if (mId !== membershipId)
          callsMap.set(mId, {
            membership_id: mId,
            status: DIALER_LIST_DIALING_STATUS.NO_ANSWER,
          });
      });

      callsMap.set(membershipId, {
        membership_id: membershipId,
        status: DIALER_LIST_DIALING_STATUS.CONNECTED,
      });

      return callsMap;
    });
  };

  /**
   * Main Source of Truth
   * Process calls map object to set accurate leads state
   */
  useEffect(() => {
    const lastUpdatedCalls = Array.from(calls, ([, data]) => data);

    /**
     * Update Dialing Leads
     */
    setLeadsDialing(
      lastUpdatedCalls
        .filter((call) => {
          const isCallMembershiIdInLeadsMap = !!leadsMap.get(
            call.membership_id as string
          );

          //TODO this should not happen
          // if (!isCallMembershiIdInLeadsMap) {
          //   console.log(
          //     `${ERROR_CATEGORIES.DIALER_LIST} - membership_id is not in the leads queue`,
          //     {
          //       data: {
          //         listId,
          //         call,
          //       },
          //     }
          //   );
          // }

          return (
            call.status === DIALER_LIST_DIALING_STATUS.DIALING &&
            isCallMembershiIdInLeadsMap
          );
        })
        .map((call) => {
          return assignLeadStatus(
            leadsMap.get(call.membership_id as string) as PipelineListContactI,
            DIALER_LIST_DIALING_STATUS.DIALING
          );
        })
    );

    /**
     * Update Processed Leads
     */
    setLeadsProcessed(
      lastUpdatedCalls
        .filter((call) => {
          // const isCallMembershiIdInLeadsMap = !!leadsMap.get(
          //   call.membership_id as string
          // );

          //TODO this should not happen
          // if (!isCallMembershiIdInLeadsMap) {
          //   console.log(
          //     `${ERROR_CATEGORIES.DIALER_LIST} - membership_id is not in the leads queue`,
          //     {
          //       data: {
          //         listId,
          //         call,
          //       },
          //     }
          //   );
          // }

          return (
            call.status !== DIALER_LIST_DIALING_STATUS.DIALING &&
            leadsMap.get(call.membership_id as string)
          );
        })
        .map((call) => {
          return assignLeadStatus(
            leadsMap.get(call.membership_id as string) as PipelineListContactI,
            call.status
          );
        })
    );
  }, [calls]);

  /**
   * Effect hook to filter out leads that have already been processed.
   *
   * @param {Array} leadsDialing - List of leads that are currently being dialed.
   * @param {Array} leadsProcessed - List of leads that have already been processed.
   *
   * This effect runs whenever `leadsDialing` or `leadsProcessed` changes.
   * It checks each lead in `leadsDialing` against `leadsProcessed`.
   * If a lead in `leadsDialing` is found in `leadsProcessed`, it is removed from `leadsDialing`.
   */
  useEffect(() => {
    if (leadsDialing?.length) {
      leadsDialing.forEach((leadDialing) => {
        if (
          leadsProcessed.some(
            (leadProcessed) =>
              leadProcessed.list_membership_id ===
              leadDialing.list_membership_id
          )
        ) {
          setLeadsDialing((leadsDialing) =>
            leadsDialing.filter(
              (lead) =>
                lead.list_membership_id !== leadDialing.list_membership_id
            )
          );
        }
      });
    }
  }, [leadsDialing, leadsProcessed]);

  /**
   * Updates the call metadata map with the current date and time for the specified membership ID.
   *
   * Using asynchronous "get" as this function is used in the setInterval
   *
   * @param {string} membershipId - The ID of the membership for which the call date is being saved.
   */
  const saveCallDate = async (membershipId: string) => {
    setCallsMetadataMap((callsMetadataMap) => {
      const cMetadataMap = new Map(callsMetadataMap);
      const meta = cMetadataMap.get(membershipId) || {};

      cMetadataMap.set(membershipId, {
        ...meta,
        startDialingAt: DayJs().toISOString(),
      });

      return cMetadataMap;
    });
  };

  const httpGetPipelineListContact = async (lead: PipelineListContactI) => {
    const API = glencocoClientAPI();

    const ResponseLeadCallStatus = await API.getListMember(
      lead?.list_id as string,
      lead?.list_membership_id
    ).catch((e) => e);

    if (ResponseLeadCallStatus.status !== 200) return undefined;

    return (ResponseLeadCallStatus.data as GetListMemberResponseI)?.contact;
  };

  /**
   *
   * Processes timed-out calls by checking the call metadata for each dialing lead and updating their status if they have timed out.
   *
   * This function retrieves the current calls metadata map and the list of leads dialing asynchronously. It then iterates through each lead,
   * checks if the lead's call has timed out based on the `startDialingAt` property and the defined timeout duration.
   * If a call has timed out, the lead is removed from the dialing list, and the call's status is updated to "TIMEOUT".
   *
   * @async
   * @returns {Promise<void>}
   */
  const processTimedoutCalls = async () => {
    const callsMetadataMap = await asyncGet(setCallsMetadataMap);
    const leadsDialing = await asyncGet(setLeadsDialing);

    let leadsTimedout = leadsDialing.filter((lead) => {
      let isTimedout = false;

      const startDialingAt = callsMetadataMap.get(
        lead.list_membership_id
      )?.startDialingAt;

      if (startDialingAt) {
        isTimedout =
          DayJs(DayJs()).diff(startDialingAt, "second") >
          SOFT_CALL_TIMEOUT_SECONDS;

        return isTimedout;
      }
    });

    if (!leadsTimedout?.length) return;

    /**
     * Re-validation of leads that exceed timeout
     * If some of them are still dialing ->
     * leave them until hard timeout reached,
     * otherwise move to the status that BE returned
     * or if lead is undefined set is as timedout
     *
     */

    /**
     * Get latest leads data
     * https://caolan.github.io/async/v3/docs.html#map
     */
    const freshLeadsTimedout = await async.map<
      PipelineListContactI,
      PipelineListContactI | undefined,
      Error
    >(leadsTimedout, async (lead: PipelineListContactI, callback) => {
      const updatedLead = await httpGetPipelineListContact(lead);

      callback(undefined, updatedLead);
    });

    /**
     * Filter actively dialing lists that do not exceed hard timeout
     * and update statuses according to recently updated leads
     *
     * Takes into account that socket message got lost
     * and lead remains in the dialing state while it
     * was actually updated
     */
    leadsTimedout = leadsTimedout
      .filter((lead, i) => {
        const startDialingAt = callsMetadataMap.get(
          lead.list_membership_id
        )?.startDialingAt;

        return (
          freshLeadsTimedout[i]?.status !==
            DIALER_LIST_DIALING_STATUS.CONNECTED &&
          DayJs(DayJs()).diff(startDialingAt, "second") >
            HARD_CALL_TIMEOUT_SECONDS
        );
      })
      .map(
        (lead) =>
          freshLeadsTimedout.find(
            (l) => l?.list_membership_id === lead.list_membership_id
          ) || lead
      );

    if (!leadsTimedout?.length) return;

    const ddMessage = `${ERROR_CATEGORIES.DIALER_LIST} Leads timed out`;
    dd.error(ddMessage, {
      data: { freshLeadsTimedout, leadsTimedout, leadsDialing },
    });
    datadogRum.addError(ddMessage, {
      data: { freshLeadsTimedout, leadsTimedout, leadsDialing },
    });

    const leadsTimedoutIds = leadsTimedout.map((l) => l.list_membership_id);

    setLeadsDialing((leads) =>
      leads.filter((l) => !leadsTimedoutIds?.includes(l.list_membership_id))
    );
    setCalls((calls) => {
      const callsMap = new Map(calls);

      leadsTimedout.forEach((lead) => {
        callsMap.set(lead.list_membership_id, {
          membership_id: lead.list_membership_id,
          status:
            lead?.status === DIALER_LIST_DIALING_STATUS.DIALING
              ? DIALER_LIST_DIALING_STATUS.TIMEOUT
              : (lead?.status as ValueOf<typeof DIALER_LIST_DIALING_STATUS>),
        });
      });

      return callsMap;
    });
  };

  const updateAccountDetailsByMembershipId = (
    membership_id: string,
    accountDetailsData: GetAccountDetailsDataI
  ) => {
    setAccountDetailsMap((accountDetailsMap) => {
      const newAccountDetailsMap = new Map(accountDetailsMap);
      newAccountDetailsMap.set(membership_id, accountDetailsData);

      return newAccountDetailsMap;
    });
  };

  /**
   * Retrieves and updates account details.
   *
   * This effect checks if account details for a lead in the dialing list have been
   * requested already. If not, it fetches the account details and updates the `accountDetailsMap`
   * with the information, which is passed to the MAXED DIALER widget once the lead is connected.
   */
  useEffect(() => {
    leadsDialing.forEach((lead) => {
      /**
       * If already requested -> don't do second time
       */
      if (accountDetailsMap.get(lead.list_membership_id)) return;

      /**
       * Mark account details as requested by setting an empty object.
       */

      updateAccountDetailsByMembershipId(lead.list_membership_id, {});

      /**
       * Now requesting the data and updating account details
       */
      (async () => {
        const accountDetails = await getAccountDetailsData(
          lead.campaign_id,
          lead.account_id
        );

        updateAccountDetailsByMembershipId(
          lead.list_membership_id,
          accountDetails
        );
      })();
    });
  }, [leadsDialing]);

  /**
   * Checks the queue for new leads and removes those that already exist in the leads map.
   *
   * This function compares leads in the `leadsToSyncMap` with those in the global leads map
   * (fetched via `asyncGet(setLeadsMap)`). If any lead already exists in the map, it is
   * removed from the `leadsToSyncMap`. Any remaining leads are added to the leads queue.
   *
   * @async
   * @param {{[k: string]: PipelineListContactI}} leadsToSyncMap - A map of leads to synchronize,
   * indexed by their contact IDs.
   * @returns {Promise<void>} - A promise that resolves when the leads queue has been updated.
   */
  const leadsQueueCheckOnNewLeads = async (leadsToSyncMap: {
    [k: string]: PipelineListContactI;
  }) => {
    const leadsMap = await asyncGet(setLeadsMap);
    const allLeads = Array.from(leadsMap, ([, lead]) => lead);

    // Remove any leads already in the leads map from leadsToSyncMap
    allLeads.forEach((lead) => {
      if (lead?.contact_id && leadsToSyncMap[lead?.contact_id]) {
        delete leadsToSyncMap[lead?.contact_id];
      }
    });

    const leadsToAdd = Object.values(leadsToSyncMap);

    // If there are new leads to add, update the leads queue
    if (leadsToAdd.length)
      setLeadsQueue((leadsQueue) => [...leadsQueue, ...leadsToAdd]);
  };

  /**
   * Synchronizes leads with the updated list.
   *
   * This function updates the state of leads being dialed, processed, and queued
   * by merging new lead data from the `leadsToSync` array. It ensures that the dialing
   * status is maintained while updating other fields with new data.
   *
   * @param {PipelineListContactI[]} leadsToSync - An array of leads to synchronize,
   * containing updated data.
   */
  const syncLeadsWithUpdatedList = (leadsToSync: PipelineListContactI[]) => {
    const leadsToSyncMap = Object.fromEntries(
      leadsToSync.map((c) => [c.contact_id, c])
    );

    setLeadsMap((prevLeadsMap) => {
      const newLeadsMap = new Map(prevLeadsMap);

      leadsToSync.forEach((lead) => {
        newLeadsMap.set(lead.list_membership_id, lead);
      });

      return newLeadsMap;
    });

    // Update the leads being dialed, keeping dialing status and merging new data
    setLeadsDialing((leadsDialing) => {
      const leadsDialingSmartUpdate = produce(leadsDialing, (draft) => {
        return draft.reduce((leads, lead) => {
          if (leadsToSyncMap[lead.contact_id]) {
            //It is important to not loose item dialing status and get updated values if there are a any
            leads.push({ ...lead, ...leadsToSyncMap[lead.contact_id] });
          }
          return leads;
        }, [] as PipelineListContactI[]);
      });

      //TODO for _diff kill calls

      return leadsDialingSmartUpdate;
    });

    // Update the processed leads with the synchronized data
    setLeadsProcessed((leadsProcessed) => {
      const leadsProcessedSmartUpdate = produce(leadsProcessed, (draft) => {
        return draft.reduce((leads, lead) => {
          if (leadsToSyncMap[lead.contact_id]) {
            leads.push({ ...lead, ...leadsToSyncMap[lead.contact_id] });
          }
          return leads;
        }, [] as PipelineListContactI[]);
      });

      return leadsProcessedSmartUpdate;
    });

    // Update the leads queue, as a side effect triggering a leads map update
    setLeadsQueue((leadsQueue) => {
      const leadsQueueSmartUpdate = produce(leadsQueue, (draft) => {
        return draft.reduce((leads, lead) => {
          if (leadsToSyncMap[lead.contact_id]) {
            leads.push({ ...lead, ...leadsToSyncMap[lead.contact_id] });
          }
          return leads;
        }, [] as PipelineListContactI[]);
      });

      return leadsQueueSmartUpdate;
    });

    // Check for any new leads in the queue after a delay
    setTimeout(() => {
      leadsQueueCheckOnNewLeads(leadsToSyncMap);
    }, 500);
  };

  /**
   * Clears all the leads states.
   *
   * This function resets the leads queue, leads dialing, and leads processed
   * states to empty arrays. It is used to clear all the leads-related data.
   */
  const clear = () => {
    setIsIdle(false);
    setLeadsQueue([]);
    setLeadsDialing([]);
    setLeadsProcessed([]);
    setIsQueuePaused(false);
    setConnectedLead(undefined);
    setCalls(new Map());
    setLeadsMap(new Map());
    setCallsMetadataMap(new Map());
    setAccountDetailsMap(new Map());
  };

  return {
    isListenWS,
    setIsListenWS,

    leadsMap,
    leadsQueue,
    leadsDialing,
    leadsProcessed,

    maxLeadsDialing,

    calls,
    callsMetadataMap,
    accountDetailsMap,
    connectedLead,
    isQueuePaused,
    isIdle,

    setLeadsMap,
    setAccountDetailsMap,
    setLeadsQueue,
    setLeadsDialing,
    setLeadsProcessed,
    setCalls,
    setConnectedLead,
    setMaxLeadsDialing,
    setIsQueuePaused,
    setIsIdle,
    pauseQueue,
    resumeQueue,

    clear,

    addToLeadsQueue,
    moveToDialingList,
    isFreeSpotForDialing,
    saveCallDate,
    processTimedoutCalls,
    updateConnectedCall,
    syncLeadsWithUpdatedList,
  };
};
