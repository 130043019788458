import { useEffect, useMemo, useState } from "react";
import { Field as FormikTextField } from "formik";
import { PlusIcon } from "@heroicons/react/24/solid";

import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import { TextField } from "shared/ui";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ChevronDownIcon } from "shared/ui/icons";

import { MeetingTakerList } from "./list";
import {
  ADD_MEETING_TAKER_MODAL_ID,
  AddMeetingTakerModal,
  MeetingTakerFormI,
} from "@/modals/add-meeting-taker-modal";
import { useFetchMeetingTakers } from "@/api/routes/account/queries";
import { MeetingTakerI } from "@/interfaces/accounts";

const DropdownContent = ({
  data = [],
  selected,

  className,

  onSelect,

  hasNoMeetingTakerOption = false,
}: {
  data?: MeetingTakerI[];
  selected?: MeetingTakerI;

  className?: string;

  onSelect: (meetingTaker: MeetingTakerI) => void;

  hasNoMeetingTakerOption?: boolean;
}) => {
  const openMeetingTakerModal = () => {
    modalHelpers.open(ADD_MEETING_TAKER_MODAL_ID);
  };

  return (
    <div
      className={clsxMerge(
        "dropdown-content",
        "rounded border  border-[#E4E4E7] bg-white shadow-lg",
        "mt-1 w-full p-0",
        className
      )}
    >
      <div className="px-3 py-[6px]">
        <span className="typography-body-4-bold">Meeting takers</span>
      </div>
      <hr className="h-[0.5px] w-full bg-[#F4F4F5]" />
      <button
        className={clsxMerge(
          "flex items-center text-[#014DFE] typography-body-4-light",
          "px-3 py-[6px]"
        )}
        type="button"
      >
        <PlusIcon className="mr-1 w-5" />
        <span onClick={openMeetingTakerModal}>Add meeting taker</span>
      </button>

      <MeetingTakerList
        hasNoMeetingTakerOption={hasNoMeetingTakerOption}
        data={data}
        selected={selected}
        onSelect={onSelect}
      />
    </div>
  );
};

export const MeetingTakerDropdown = ({
  name,
  errors,
  touched,
  onChange,

  defaultValue,

  className,
  textFieldClassName,
  dropdownClassName,

  isFormik,

  hasNoMeetingTakerOption = false,
}: {
  name?: string;
  errors?: string;
  touched?: boolean;
  onChange?: (meetingTaker: MeetingTakerI) => void;

  defaultValue?: string;

  className?: string;
  textFieldClassName?: string;
  dropdownClassName?: string;

  isFormik?: boolean;

  hasNoMeetingTakerOption?: boolean;
}) => {
  const { data, refetch } = useFetchMeetingTakers();

  const meetingTakers = data?.data?.meeting_takers;

  const [meetingTaker, setMeetingTaker] = useState<MeetingTakerI>();
  const [candidateMeetingTaker, setCandidateMeetingTaker] =
    useState<MeetingTakerFormI>();

  const meetingTakerDisplayValue = useMemo(() => {
    if (meetingTaker && meetingTaker.id === null) {
      return "No meeting taker";
    } else if (meetingTaker && meetingTaker.id !== null) {
      return `${meetingTaker?.first_name} ${meetingTaker?.last_name}`;
    }

    return undefined;
  }, [meetingTaker]);

  const handleOnSuccessAddMeetingTaker = (data: MeetingTakerFormI) => {
    setCandidateMeetingTaker(data);
    void refetch();
  };

  const selectMeetingTaker = (meetingTaker: MeetingTakerI) => {
    setMeetingTaker(meetingTaker);
    setCandidateMeetingTaker(undefined);

    onChange?.(meetingTaker);
  };

  useEffect(() => {
    if (candidateMeetingTaker) {
      const newMeetingTaker = meetingTakers?.find(
        (mt) => mt.email === candidateMeetingTaker?.email
      );

      if (newMeetingTaker) {
        selectMeetingTaker(newMeetingTaker);
      }
    }
  }, [meetingTakers, candidateMeetingTaker?.email]);

  useEffect(() => {
    if (!candidateMeetingTaker && !meetingTaker && meetingTakers?.length) {
      const meetingTaker = meetingTakers.find((mt) => mt.id === defaultValue);

      if (meetingTaker) selectMeetingTaker(meetingTaker);
    }
  }, [meetingTakers, defaultValue]);

  const EnhancedTextField = useMemo(
    () => (isFormik ? TextField(FormikTextField) : TextField()),
    [isFormik]
  );

  return (
    <div className={className}>
      <ControlledDropdown className="dropdown-start w-full">
        {(isDropdownVisible) => (
          <>
            <label tabIndex={0} className="relative">
              <EnhancedTextField
                name={name}
                errors={errors}
                touched={touched}
                placeholder="Select meeting taker"
                className={clsxMerge(
                  "relative mb-0 animate-fadein",
                  textFieldClassName
                )}
                labelContentClassName="brand-typography-body2"
                inputClassName="text-black typography-body-4 placeholder:text-black cursor-pointer"
                inputProps={{
                  readOnly: true,
                  // Only make it controllable if it's not a formik field
                  value: meetingTakerDisplayValue,
                }}
              />

              <ChevronDownIcon
                className={clsxMerge(
                  "absolute right-4 top-5 w-3",
                  "transition-transform duration-300"
                )}
              />
            </label>

            <div tabIndex={0}>
              {isDropdownVisible && (
                <DropdownContent
                  hasNoMeetingTakerOption={hasNoMeetingTakerOption}
                  data={meetingTakers}
                  selected={meetingTaker}
                  className={dropdownClassName}
                  onSelect={selectMeetingTaker}
                />
              )}
            </div>
          </>
        )}
      </ControlledDropdown>

      <AddMeetingTakerModal onSuccess={handleOnSuccessAddMeetingTaker} />
    </div>
  );
};
