import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";

interface ButtonMenuItemPropsI {
  id?: string;
  text: string;
  className?: string;
  onClick?: () => void;
  href?: string;
  target?: string;
  rel?: string;
  icon?: any;
}

const ITEM_DEFAULT_CLASSNAME = "ae-typography-body2 flex items-center";

const ButtonMenuItem: FC<ButtonMenuItemPropsI> = ({
  id,
  text,
  className,
  onClick = () => {},
  href,
  target,
  rel,
  icon,
}) => {
  return href ? (
    <a
      id={id}
      href={href}
      target={target}
      rel={rel}
      className={clsxMerge(ITEM_DEFAULT_CLASSNAME, className)}
      onClick={onClick}
    >
      {!!icon && icon}

      {text}
    </a>
  ) : (
    <span
      id={id}
      className={clsxMerge(ITEM_DEFAULT_CLASSNAME, className)}
      onClick={onClick}
    >
      {!!icon && icon}

      {text}
    </span>
  );
};

export default ButtonMenuItem;
