import { FC, useState } from "react";

import { ContactSearchResultItemI } from "@/api/routes/account/interfaces";
import { Checkbox } from "shared/ui";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

const EnhancedCheckbox = Checkbox();

interface FindMoreLeadsSearchResultsPropsI extends PropsWithClassNameI {
  contacts?: ContactSearchResultItemI[];
  onBack?: () => void;
  onSubmit?: (selectedContacts: ContactSearchResultItemI[]) => void;
}

const TableDataCell = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <td
      className={clsxMerge(
        "max-w-[136px] py-3 pr-2 typography-body-4",
        className
      )}
    >
      <p className="line-clamp-2">{children}</p>
    </td>
  );
};

export const FindMoreLeadsSearchResults: FC<
  FindMoreLeadsSearchResultsPropsI
> = ({ className, contacts = [], onBack = () => {}, onSubmit = () => {} }) => {
  const [selectedContacts, setSelectedContacts] = useState<
    ContactSearchResultItemI[]
  >([]);

  const handleSelectContact = (contact: ContactSearchResultItemI) => {
    const isSelected = selectedContacts.includes(contact);

    if (isSelected) {
      setSelectedContacts(selectedContacts.filter((c) => c.id !== contact.id));
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  return (
    <div className={clsxMerge(className)}>
      <div className="mb-6 typography-header-6">
        Search & find leads/lead info
      </div>

      <div className="mb-2 typography-body-4">
        We found{" "}
        <span className="typography-body-4-bold">
          {contacts.length} potential results
        </span>
      </div>

      <div>
        <table className="w-full">
          <thead>
            <tr className="border-b border-t text-left">
              <th className="w-10 py-3"></th>
              <th className="py-3 text-[#71717A] typography-body-4">First</th>
              <th className="py-3 text-[#71717A] typography-body-4">Last</th>
              <th className="py-3 text-[#71717A] typography-body-4">Title</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id} className="border-b">
                <TableDataCell className="min-w-fit">
                  <EnhancedCheckbox
                    name={contact.id}
                    label=""
                    labelClassName="p-0"
                    className="w-fit border-none p-0"
                    inputProps={{
                      checked: selectedContacts.includes(contact),
                      onChange: () => handleSelectContact(contact),
                    }}
                  />
                </TableDataCell>
                <TableDataCell className="typography-body-4-medium">
                  {contact.first_name}
                </TableDataCell>
                <TableDataCell>{contact.last_name}</TableDataCell>
                <TableDataCell>{contact.title}</TableDataCell>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="sticky bottom-0 mt-4 flex justify-between bg-white pb-[24px]">
        <div className="flex justify-start">
          <button
            className="ae-link typography-header-8-semibold"
            onClick={onBack}
          >
            Edit search
          </button>
        </div>

        <div className="flex justify-end">
          <button
            className="btn-ae-default w-[170px] typography-header-8-semibold"
            onClick={() => onSubmit(selectedContacts)}
            disabled={selectedContacts.length === 0}
          >
            Add to account
          </button>
        </div>
      </div>
    </div>
  );
};
