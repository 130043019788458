import { FC, PropsWithChildren } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface ContainerPropsI extends PropsWithClassNameI, PropsWithChildren {
  id?: string;
}

export const Container: FC<ContainerPropsI> = ({ children, className, id }) => (
  <div
    id={id}
    className={clsxMerge("mx-auto min-w-[1085px] px-4 lg:container", className)}
  >
    {children}
  </div>
);
