import { AxiosInstance, AxiosResponse } from "axios";
import { CoachingMessageI } from "@/interfaces/coaching";

export interface getCoachingMessagesResponseI {
  coaching_messages: Array<CoachingMessageI>;
  next_token?: string;
}

export interface CoachingApiI {
  getCoachingMessages: (params: {
    next_token?: string;
  }) => Promise<AxiosResponse<getCoachingMessagesResponseI>>;

  likeCoachingFeedbackFetcher: (
    callRecordingId: string,
    params: {
      is_liked: boolean;
    }
  ) => Promise<AxiosResponse>;
}

export const CoachingAPIEndpoints = (API: AxiosInstance): CoachingApiI => ({
  getCoachingMessages: (params) => API.post(`/v1/coaching`, params),

  likeCoachingFeedbackFetcher: (callRecordingId, params) =>
    API.post(`v1/coaching/${callRecordingId}/like`, params),
});
