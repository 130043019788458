import { InputHTMLAttributes } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { Input } from "shared/ui/shadcn/input";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "shared/ui/shadcn/form";

export interface TextFieldPropsI {
  label?: string;
  placeholder: string;
  disabled?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange?: (value: string) => void;
}

const TextField = ({
  label,
  placeholder,
  disabled = false,
  inputProps = {},
  containerClassName,
  labelClassName,
  inputClassName,
  onChange,
}: TextFieldPropsI) => {
  return (
    <FormItem className={containerClassName}>
      {label && (
        <FormLabel className={clsxMerge(labelClassName)}>{label}</FormLabel>
      )}
      <FormControl>
        <Input
          placeholder={placeholder}
          className={clsxMerge(inputClassName)}
          disabled={disabled}
          {...inputProps}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

export default TextField;
