import { AxiosInstance } from "axios";

import {
  KnowledgeBaseTypeI,
  KnowledgeBaseAIDownstreamTypeI,
} from "shared/lib/interfaces/knowledge-base";
import { GlenXKnowledgeBaseAssetI } from "shared/lib/interfaces/knowledge-base";

export interface GetKnowledgeBaseUploadURLParamsI {
  file_type: string;
  file_name: string;
  content_size: number;
}

export interface GetKnowledgeBaseUploadURLResponseI {
  id?: string;
  knowledge_base_presigned_upload_url?: string;
}

export interface SubmitKnowledgeBaseParamsI {
  text: string[];
  website_urls: string[];
  upload_ids: string[];
}

export type GetKnowledgeBaseAIDownstreamCountsResponseI = Record<
  KnowledgeBaseAIDownstreamTypeI,
  number
>;

export interface GlenXKnowledgeBaseAIDetectionI {
  detection_type: KnowledgeBaseTypeI;
  detected: boolean;
}

export interface GlenXKnowledgeBaseAssetsResponseI {
  assets?: GlenXKnowledgeBaseAssetI[];
  ai_detections?: GlenXKnowledgeBaseAIDetectionI[];
}

export const KnowledgeBaseAPIEndpoints = (api: AxiosInstance) => ({
  getKnowledgeBaseUploadURL: (
    campaignId: string,
    params: GetKnowledgeBaseUploadURLParamsI
  ) =>
    api.post<GetKnowledgeBaseUploadURLResponseI>(
      `/x/v1/campaign/${campaignId}/knowledge_bases/upload_url`,
      params
    ),

  deleteKnowledgeBaseAsset: (campaignId: string, assetId: string) =>
    api.delete(`/x/v1/campaign/${campaignId}/knowledge_bases/${assetId}`),

  submitKnowledgeBase: (
    campaignId: string,
    params: SubmitKnowledgeBaseParamsI
  ) => api.post(`/x/v1/campaign/${campaignId}/knowledge_bases/submit`, params),

  getKnowledgeBases: (campaignId: string) =>
    api.post<GlenXKnowledgeBaseAssetsResponseI>(
      `/x/v1/campaign/${campaignId}/knowledge_bases/get`
    ),

  getKnowledgeBaseAIDownstreamCounts: (campaignId: string) =>
    api.get<GetKnowledgeBaseAIDownstreamCountsResponseI>(
      `/x/v1/campaign/${campaignId}/downstream_counts`
    ),
});
