// React hooks
import { useEffect } from "react";
import { useMemo } from "react";

// Dialer hooks and context
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

// WebSocket related
import { useWebsocketConnection } from "@/hooks/use-websocket-connection";
import { WEBSOCKET_CONNECTION_TYPES } from "@/lib/websockets/constants";
import { callCopilotSocketActions } from "@/lib/websockets/call-copilot/socket-actions";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import {
  WSAuthSuccessDataI,
  WSCallCopilotMessageReceivedDataI,
} from "@/interfaces/events";

// Account details context
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ACCOUNTS_DETAILS_TABS } from "@/modules/pipeline/account-details/constants";

// UI
import toast from "react-hot-toast";
import { EnvironmentalVariables } from "@/interfaces/environmental-variables";

export const useCallCopilotWSConnection = () => {
  useWebsocketConnection(WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT);

  const {
    callCopilotContent: content,
    setCallCopilotContent: setContent,
    activeTab,
  } = useAccountDetailsContext();

  const callStatus = useDialerCallStatus();
  const { call } = useCallingContext();

  const callId = useMemo(() => call?.customParameters?.get("call_id"), [call]);

  /**
   * Useecase
   * Start listening for call copilot messages when call is ongoing
   */
  useEffect(() => {
    if (callStatus !== DIALER_CALL_STATUS.DURING) {
      setContent?.(undefined);
      return;
    }

    callCopilotSocketActions.startListening(callId as string);
  }, [callStatus]);

  /**
   * Useecase
   * Handle event when competitor mention is detected
   */
  useEffect(() => {
    if (!callId) {
      return;
    }

    const handleMessageReceived = (
      event: CustomEvent<WSCallCopilotMessageReceivedDataI>
    ) => {
      const data = event.detail;

      setContent?.((content) => [data, ...(content ?? [])]);
    };

    /**
     * Useecase
     * Re-establish WebSocket connection if it drops during an active call
     */
    const handleWSAuthSuccess = (event: CustomEvent<WSAuthSuccessDataI>) => {
      if (
        event?.detail?.connectionType !==
        WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT
      )
        return;

      callCopilotSocketActions.startListening(callId as string);
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CALL_COPILOT.MESSAGE_RECEIVED,
      handleMessageReceived as EventListener
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
      handleWSAuthSuccess as EventListener
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CALL_COPILOT.MESSAGE_RECEIVED,
        handleMessageReceived as EventListener
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
        handleWSAuthSuccess as EventListener
      );
    };
  }, [callId]);

  useEffect(() => {
    if (
      activeTab !== ACCOUNTS_DETAILS_TABS.DIALER_TOOLKIT &&
      callStatus === DIALER_CALL_STATUS.DURING &&
      content?.length
    ) {
      //TODO
      // - show toast based on the last item in the content array
      toast.success(
        "The new competitor mention is available in Dialer Copilot!"
      );
    }
  }, [content?.length]);
};

export const useCallCopilot = () => {
  const { callCopilotContent: content, setCallCopilotContent: setContent } =
    useAccountDetailsContext();

  const callStatus = useDialerCallStatus();

  /**
   * Note
   * We want to show call copilot section only when call is ongoing
   *
   * Initialization logic is in useCallCopilotWSConnection hook and is used in
   * Primary Contact section
   *
   * Event subsctription happens in the component itself
   */
  const isCallCopilotSection = useMemo(() => {
    const isProd =
      (process?.env as unknown as EnvironmentalVariables)?.NEXT_PUBLIC_ENV ===
      "production";
    /**
     * Note
     * Until we are ready to enable call copilot for glenx, we will return false
     * return false
     */
    if (isProd) {
      return false;
    }

    return callStatus === DIALER_CALL_STATUS.DURING;
  }, [callStatus]);

  return { content, setContent, isCallCopilotSection };
};
