import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { FindMoreLeadsForm, FindMoreLeadsFormI } from "./find-more-leads-form";
import { ContactSearchResultItemI } from "@/api/routes/account/interfaces";
import { useStepsManager } from "shared/lib/hooks";
import { FindMoreLeadsSearchResults } from "@/modals/account-find-more-leads-modal/find-more-leads-search-results";
import {
  useEnrichAndAddContacts,
  useFindMoreContacts,
} from "@/api/routes/account/mutations";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { inputBadgesToString } from "shared/ui/user-input/badge-input/helper";
import { FIND_MORE_LEADS_MODAL_CONTENT_ID } from "@/constants/element-ids";
import { FeatureIntroTooltip } from "shared/ui/feature-intro/tooltip-guide";
import { useListCallingTour } from "@/components/modules/tours/list-calling/hooks";

interface FindMoreLeadsModalPropsI {
  defaultTitles?: string[];
  onSuccess?: () => void;
  handleClose?: () => void;
}

export const FIND_MORE_LEADS_MODAL = "find_more_leads_modal";

const TOTAL_STEPES = 2;

const ModalContent: FC<ModalChildrenFnPropsI & FindMoreLeadsModalPropsI> = ({
  defaultTitles = [],
  onSuccess = () => {},
  handleClose = () => {},
}) => {
  const { activeStep, incrementStep, decrementStep } = useStepsManager({
    totalStepCount: TOTAL_STEPES,
  });
  const { campaign, account } = useAccountDetailsContext();
  const { tooltips, isStepVisible, markStepAsCompleted } = useListCallingTour();

  const { mutateAsync: findMoreContactsAsync } = useFindMoreContacts(
    campaign?.id as string,
    account?.id as string
  );

  const { mutateAsync: enrichAndAddContactsAsync } = useEnrichAndAddContacts(
    campaign?.id as string,
    account?.id as string
  );

  const defaultTitleBadges = defaultTitles.map((title) => ({
    label: title,
    value: title,
  }));

  // store the form data incase user comes back to the form via "back" button
  const [searchParams, setSearchParams] = useState<FindMoreLeadsFormI>({
    titles: defaultTitleBadges,
    first_name: "",
    last_name: "",
    linkedin_url: "",
  });

  const [isFindContactsLoading, setIsFindContactsLoading] = useState(false);
  const [searchResultContacts, setSearchResultContacts] = useState<
    ContactSearchResultItemI[]
  >([]);

  const handleFindMoreContacts = async (data: FindMoreLeadsFormI) => {
    setSearchParams({ ...data });
    setIsFindContactsLoading(true);

    const result = await findMoreContactsAsync({
      titles: inputBadgesToString(data.titles),
      first_name: data.first_name,
      last_name: data.last_name,
      linkedin_url: data.linkedin_url,
    });

    if (result.status === 200) {
      setSearchResultContacts(result.data.contacts);
      incrementStep();
    }

    setIsFindContactsLoading(false);
  };

  const handleAddContactsToAccount = async (
    contacts: ContactSearchResultItemI[]
  ) => {
    const result = await enrichAndAddContactsAsync(contacts);
    if (result.status === 200) {
      handleClose();
      onSuccess();
    } else {
      toast.error("Failed to add contacts.");
    }
  };

  useEffect(() => {
    return () => {
      markStepAsCompleted(FIND_MORE_LEADS_MODAL_CONTENT_ID);
    };
  }, []);

  return (
    <>
      <div id={FIND_MORE_LEADS_MODAL_CONTENT_ID}>
        {activeStep === 0 && (
          <FindMoreLeadsForm
            className="w-[600px] max-w-full p-6"
            {...searchParams}
            accountName={account?.name}
            loading={isFindContactsLoading}
            onSubmit={handleFindMoreContacts}
          />
        )}

        {activeStep === 1 && (
          <FindMoreLeadsSearchResults
            className="w-[800px] max-w-full p-6 pb-0"
            contacts={searchResultContacts}
            onBack={decrementStep}
            onSubmit={handleAddContactsToAccount}
          />
        )}
      </div>

      {isStepVisible(FIND_MORE_LEADS_MODAL_CONTENT_ID) && (
        <FeatureIntroTooltip
          referenceId={FIND_MORE_LEADS_MODAL_CONTENT_ID}
          {...tooltips[FIND_MORE_LEADS_MODAL_CONTENT_ID]}
        />
      )}
    </>
  );
};

export const FindMoreLeadsModal: FC<FindMoreLeadsModalPropsI> = ({
  defaultTitles,
  onSuccess,
}) => (
  <Modal
    id={FIND_MORE_LEADS_MODAL}
    modalContainerClassName="overflow-hidden rounded-lg"
    modalBoxClassName="p-0 w-fit"
  >
    {(props) => (
      <ModalContent
        {...props}
        onSuccess={onSuccess}
        defaultTitles={defaultTitles}
      />
    )}
  </Modal>
);
