import { useMemo, useState } from "react";
import clsx from "clsx";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

import { CloseIcon } from "shared/ui/icons";

import { meetingsToastEmitter } from "..";
import {
  CustomQualifiedMeetingsToastI,
  MeetingQualifiedToastI,
} from "@/interfaces/custom-toasts/meetings";
import { CampaignLogo } from "@/components/base/campaign-logo";

interface QualifiedMeetingToastI {
  toastData: CustomQualifiedMeetingsToastI;
}

const QualifiedMeetingToast = ({
  toastData,
  className,
}: QualifiedMeetingToastI & { className?: string }) => {
  const [renderToast, setRenderToast] = useState(true);

  const handleClose = () => {
    setRenderToast(false);
    meetingsToastEmitter.dismiss();
  };

  const {
    campaign_id,
    campaign_name,
    payout,
    user_name,
    contact_title,
    account_name,
    // forum_post_id,
    recording_url,
  }: MeetingQualifiedToastI = useMemo(
    () => toastData.data || {},
    [toastData.data]
  );

  return renderToast ? (
    <div
      className={twMerge(
        clsx(
          " w-[600px]",
          "p-5",
          "transition-opacity duration-500 ease-out",
          "animate-in fade-in",
          "gap-0 overflow-hidden rounded-xl border-2 text-white shadow-[0px_4px_88px_rgba(0,0,0,0.06)]",
          "bg-[linear-gradient(273deg,_#000_82.66%,_#535353_99.29%)]",

          className
        )
      )}
    >
      <div className="flex">
        <div className="flex items-center">
          <CampaignLogo
            campaignId={campaign_id}
            defaultBgColor="bg-white"
            className="min-h-[60px] min-w-[60px] rounded-xl"
          />
          <div className="relative top-1 ml-6 flex flex-col justify-center">
            <h4 className="ae-typography-h4 mb-1">{campaign_name}</h4>

            <p className="ae-typography-body2 mb-3 line-clamp-2">
              <b>{`${user_name} booked a qualified meeting🔥`}</b> worth{" "}
              <b>${payout}</b> with a {contact_title} from {account_name}{" "}
              account
            </p>
          </div>

          {/* TODO Figure out if that's needed on GlenX */}
          {/*{campaign_id && forum_post_id ? (*/}
          {/*  <a*/}
          {/*    href={Router.salesFloorByCampaignPost({*/}
          {/*      campaignId: campaign_id,*/}
          {/*      postId: forum_post_id,*/}
          {/*      category: FORUM_POST_TYPES.QUALIFIED_MEETIGNS,*/}
          {/*    })}*/}
          {/*    className="btn-b-white ml-4 w-[120px]"*/}
          {/*  >*/}
          {/*    Listen*/}
          {/*  </a>*/}
          {/*) : (*/}
          {/*  <Link*/}
          {/*    href={recording_url || ""}*/}
          {/*    target="_blank"*/}
          {/*    className="btn-b-white ml-4 w-[120px]"*/}
          {/*  >*/}
          {/*    Listen*/}
          {/*  </Link>*/}
          {/*)}*/}

          <Link
            href={recording_url || ""}
            target="_blank"
            className="btn-b-white ml-4 w-[120px]"
          >
            Listen
          </Link>

          <div>
            <button
              className={clsx("btn btn-circle btn-ghost btn-sm")}
              onClick={handleClose}
            >
              <CloseIcon className="ml-6 min-w-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default QualifiedMeetingToast;
