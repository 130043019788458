import { AxiosInstance } from "axios";

import { UserI, UserOnboardingI } from "@/interfaces/user";
import { UserOnboardingStatusI } from "@/interfaces/signup";
import { CampaignI } from "@/interfaces/campaign";

export interface GetUserResponseI {
  user: UserI;
  intercom_payload?: any;
  campaign?: CampaignI;
  is_user_subscription_active: boolean;
  user_onboarding_status: UserOnboardingStatusI;
}

export interface UserUpdateResponseI {
  user: UserI;
}

interface CaptureSignupUTMAndReferralParamsI {
  referrer_slug?: string | null;
  utm_params: Record<string, string>;
}

export const UserAPIEndpoints = (API: AxiosInstance) => ({
  getUser: () => API.get<GetUserResponseI>("/x/v1/me"),
  patchUser: (params: UserOnboardingI) =>
    API.patch<UserUpdateResponseI>("/x/v1/me", params),
  captureSignupUTMAndReferral: (params: CaptureSignupUTMAndReferralParamsI) =>
    API.post("/x/v1/capture_utm_and_referrer", params),
});
