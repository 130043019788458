import { PerformanceFilterI } from "shared/lib/interfaces/performance";

export const PERFORMANCE_DASHBOARD_QUERY_KEY =
  "PERFORMANCE_DASHBOARD_QUERY_KEY";

export const PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY =
  "PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY";

export const TODAY_PERFORMANCE_QUERY_KEY = "today_performance";

export const LIFETIME_PERFORMANCE_QUERY_KEY = "lifetime_performance";

export const GOAL_TRACKER_PROGRESS_QUERY_KEY = "goal_tracker_progress";

export const PerformanceDashboardQueryKeys = {
  dashboardMetrics: (
    filters: PerformanceFilterI | undefined,
    campaignId: string | undefined
  ) => [PERFORMANCE_DASHBOARD_QUERY_KEY, filters, campaignId],
  campaignStats: (campaignId: string | undefined) => [
    PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY,
    campaignId,
  ],
  lifetimePerformance: () => [LIFETIME_PERFORMANCE_QUERY_KEY],
  todayPerformance: () => [TODAY_PERFORMANCE_QUERY_KEY],
  callCoachingByCampaign: (campaignId: string | undefined) => [
    PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY,
    "coaching",
    campaignId,
  ],
  goalTrackerProgress: () => [GOAL_TRACKER_PROGRESS_QUERY_KEY],
};
