import { useMemo } from "react";

import { FeatureIntroTooltipGuideStepsI } from "shared/ui/feature-intro/tooltip-guide";

import {
  FIND_MORE_LEADS_BUTTON_ID,
  FIND_MORE_LEADS_MODAL_CONTENT_ID,
  LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID,
  LISTS_START_CALLING_BUTTON_ID,
} from "@/constants/element-ids";
import { useListCallingTourContext } from "@/modules/tours/list-calling/context";

export const useListCallingTour = () => {
  const { completedSteps, saveNewCompletedStep, visibleSteps } =
    useListCallingTourContext();

  const markStepAsCompleted = (stepId: string) => {
    saveNewCompletedStep(stepId);
  };

  const isStepVisible = (stepId: string) => {
    return visibleSteps.includes(stepId);
  };

  const tooltips: FeatureIntroTooltipGuideStepsI = useMemo(
    () => ({
      [LISTS_START_CALLING_BUTTON_ID]: {
        title: "See the magic. Make your first call",
        description: "Click the start calling button below",
        placement: "top",
      },
      [LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID]: {
        tooltipContentClassName: "z-50",
        delayMs: 100,
        placement: "bottom",
        title: "Disposition leads after a call",
        description:
          "After every conversation, you can capture what happened before moving on to the next lead.",
        buttons: {
          primary: {
            text: "Got it",
            className: "w-[125px]",
            actionHandler: () => {
              markStepAsCompleted(
                LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID
              );
            },
          },
        },
      },
      [FIND_MORE_LEADS_BUTTON_ID]: {
        tooltipContentClassName: "z-50",
        delayMs: 100,
        title: "Pull more leads for a given account",
        description:
          "With our database, find leads without needing to leave your outreach platform.",
        buttons: {
          primary: {
            text: "Next",
            className: "w-[125px]",
            actionHandler: () => {
              document.getElementById(FIND_MORE_LEADS_BUTTON_ID)?.click();
            },
          },
        },
      },
      [FIND_MORE_LEADS_MODAL_CONTENT_ID]: {
        tooltipContentClassName: "z-[999]",
        placement: "right",
        title: "Lightning fast lead search",
        description:
          "Search by ideal titles, first name or Linkedin URL to find the best leads to add to an account.",
        buttons: {
          primary: {
            text: "Got it",
            className: "w-[125px]",
            actionHandler: () => {
              markStepAsCompleted(FIND_MORE_LEADS_MODAL_CONTENT_ID);
            },
          },
        },
      },
    }),
    []
  );

  const isAllStepsComplete = useMemo(() => {
    return completedSteps.length === Object.keys(tooltips).length;
  }, [completedSteps, tooltips]);

  return {
    tooltips,
    isAllStepsComplete,
    isStepVisible,
    markStepAsCompleted,
  };
};
