export const EMAIL_SETUP_STEPS = [
  {
    title: "Warm up",
    description:
      "Customize your email messaging, and our AI will warm up your leads for you.",
  },
  {
    title: "AI copilot",
    description:
      "Our AI recaps calls and follows up with next steps, referencing past interactions.",
  },
  {
    title: "Smart outbounding",
    description:
      "Use GlenX AI to re-engage cold leads with hyper-personalized, context-aware emails.",
  },
];
