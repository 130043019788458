import {
  AcademicCapIcon,
  ArrowLeftStartOnRectangleIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Headset, ListIcon, Phone, SignatureIcon } from "lucide-react";

import { CoachIcon, CogsIcon, LeaderboardIcon } from "shared/ui/icons";

import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/navigation/primary/interfaces";
import Router from "@/helpers/router";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { NOTIFICATION_MAPPED_IDS } from "@/components/navigation/shared/notification-label";
import { openProfileSettings } from "@/lib/store/profile-settings";

export const SETTINGS_ITEM_ID = "settings";
export const BILLING_ITEM_ID = "billing";
export const CALL_FORWARDING_ITEM_ID = "call_forwarding";

export const NAV_GROUPS = {
  LEADS: "leads",
  EMAILS: "emails",
  PERFORMANCE: "performance",
  COACHING: "coaching",
  SETTINGS: "settings",
} as const;

type NavGroupNamesI = ValueOfObjectFields<typeof NAV_GROUPS>;

// All nav items organized by their group structures.
// Remember to reference the item you want from here into the appropriate menu array (found further below).
export const NAV_ITEMS: Record<
  NavGroupNamesI,
  PrimaryNavigationItemCollapsableWithIconI
> = {
  [NAV_GROUPS.LEADS]: {
    text: "Leads",
    icon: <CurrencyDollarIcon />,
    isCollapsed: true,
    children: [
      {
        text: "Working lists",
        href: Router.lists(),
        icon: <ListIcon className="w-4" />,
        disabled: false,
      },
      {
        text: "History",
        href: Router.history(),
        icon: <Phone className="w-4" />,
        disabled: false,
      },
    ],
  },
  [NAV_GROUPS.EMAILS]: {
    text: "Emails",
    id: NOTIFICATION_MAPPED_IDS.EMAILS,
    icon: <EnvelopeIcon />,
    href: Router.emailInbox(),
    disabled: false,
  },
  [NAV_GROUPS.PERFORMANCE]: {
    text: "Performance",
    icon: <LeaderboardIcon />,
    href: Router.performance(),
    disabled: false,
  },
  [NAV_GROUPS.COACHING]: {
    text: "Coaching",
    icon: <CoachIcon />,
    isCollapsed: true,
    children: [
      {
        text: "Coaching",
        href: Router.coaching(),
        icon: <CoachIcon />,
        disabled: false,
      },
      {
        text: "Training",
        href: Router.training(),
        icon: <AcademicCapIcon />,
        disabled: false,
      },
    ],
  },
  [NAV_GROUPS.SETTINGS]: {
    text: "Settings",
    id: SETTINGS_ITEM_ID,
    icon: <CogsIcon className="w-5" />,
    isCollapsed: true,
    children: [
      {
        text: "My Profile",
        handler: openProfileSettings,
        icon: <UserIcon />,
      },
      {
        text: "Email Signature",
        href: Router.settings.emailSignature(),
        icon: <SignatureIcon className="w-4" />,
      },
      {
        text: "Call Settings",
        id: CALL_FORWARDING_ITEM_ID,
        href: Router.settings.callForwarding(),
        icon: <Headset size={16} />,
      },
      {
        text: "Sign out",
        href: Router.logout(),
        icon: <ArrowLeftStartOnRectangleIcon />,
      },
    ],
  },
};

export const NAV_ITEMS_PRIMARY = [
  // Temporarily removing the Performance nav item until the data is accurate
  NAV_ITEMS[NAV_GROUPS.PERFORMANCE],
  NAV_ITEMS[NAV_GROUPS.COACHING],
];
