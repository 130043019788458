import { MeetingTakerI } from "@/interfaces/accounts";
import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { Avatar } from "shared/ui";
import { Ban } from "lucide-react";

export const MeetingTakerListItem = ({
  id,
  first_name,
  last_name,
  isSelected,
  onClick,
}: MeetingTakerI & {
  isSelected?: boolean;
  onClick: () => void;
}) => {
  const meetingTakerName = getFullEntityName({ first_name, last_name });

  return (
    <li
      className={clsxMerge(
        "flex cursor-pointer items-center gap-x-2",
        "px-3 py-[6px]",
        "hover:bg-black/5",
        { "bg-[#014DFE] text-white hover:bg-[#014DFE]": isSelected }
      )}
      onClick={onClick}
    >
      {id === null ? (
        <Ban size={16} />
      ) : (
        <Avatar
          placeholderText={meetingTakerName}
          className="h-4 w-4 typography-body-6"
        />
      )}
      <span>{id === null ? "No meeting taker" : meetingTakerName}</span>
    </li>
  );
};

export const MeetingTakerList = ({
  data,
  selected,
  onSelect,
  hasNoMeetingTakerOption = false,
}: {
  data: MeetingTakerI[];
  selected?: MeetingTakerI;
  onSelect: (meetingTaker: MeetingTakerI) => void;
  hasNoMeetingTakerOption: boolean;
}) => {
  const handleOnClick = (meetingTaker: MeetingTakerI) => () => {
    onSelect(meetingTaker);
  };

  const options = hasNoMeetingTakerOption
    ? [{ id: null } as unknown as MeetingTakerI, ...(data || [])]
    : data;

  return (
    <ul className={clsxMerge("max-h-[30vh]", "overflow-auto")}>
      {options.map((mt) => (
        <MeetingTakerListItem
          key={mt.id}
          {...mt}
          onClick={handleOnClick(mt)}
          isSelected={selected?.id === mt.id}
        />
      ))}
    </ul>
  );
};
