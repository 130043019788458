import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import _debounce from "lodash/debounce";

import { clsxMerge } from "shared/lib/helpers";
import { Switch } from "shared/ui/shadcn/switch";
import { ScriptPreviewSection } from "shared/ui/account-details/scripts/preview-section";
import { AccountDetailsLayout as Layout } from "@/components/modules/pipeline/account-details/layout";
import { ScriptEditorSection } from "@/components/modules/pipeline/account-details/sections/scripts-editor-section";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  useFetchCampaignDefaultScript,
  useFetchCampaignScript,
} from "@/modules/pipeline/account-details/queries";
import { useUpdateCampaignScript } from "@/modules/pipeline/account-details/queries/mutations";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { RESET_CAMPAIGN_SCRIPT_MODAL_ID } from "@/components/modules/pipeline/account-details/modals";
import { DEFAULT_MARKDOWN_TEXT } from "shared/lib/constants/markdown";

export const AccountDetailsTabScripts = () => {
  const { campaign } = useAccountDetailsContext();

  const { data: savedUserScript } = useFetchCampaignScript(campaign?.id);
  const { data: defaultScript } = useFetchCampaignDefaultScript(campaign?.id);
  const { mutateAsync: updateCampaignScript } = useUpdateCampaignScript();

  const [isSaving, setIsSaving] = useState(false);
  const [customScriptValue, setCustomScriptValue] = useState<string>(
    savedUserScript?.script_text || defaultScript || ""
  );

  const isScriptDefault = savedUserScript?.is_default;

  const [isPreviewDefaultScript, setIsPreviewDefaultScript] = useState(false);

  const debouncedOnChange = useCallback(
    _debounce(
      async (updatedScript: string) => {
        if (!campaign?.id) return;

        setIsSaving(true);

        const resp = await updateCampaignScript({
          campaignId: campaign.id,
          params: {
            script_text: updatedScript,
          },
        });

        if (resp.status !== 200) {
          toast.error("Error saving script");
        }

        setIsSaving(false);
      },
      1000,
      { leading: false, trailing: true }
    ),
    [updateCampaignScript, campaign?.id]
  );

  const handleUserScriptChange = async (value: string) => {
    setCustomScriptValue(value);
    debouncedOnChange(value);
  };

  useEffect(() => {
    setCustomScriptValue(savedUserScript?.script_text || defaultScript || "");
  }, [savedUserScript]);

  return (
    <Layout.GridColumns columns={2}>
      <Layout.GridColumn className="bg-white p-5 pb-44">
        <div className="h-full">
          {isPreviewDefaultScript ? (
            <ScriptPreviewSection
              markdown={customScriptValue || DEFAULT_MARKDOWN_TEXT}
              title="Your custom script"
            />
          ) : (
            <ScriptEditorSection
              value={customScriptValue}
              isSaving={isSaving}
              isModifiedFromDefault={!isScriptDefault}
              onChange={handleUserScriptChange}
              onReset={() =>
                modalHelpers.trigger(RESET_CAMPAIGN_SCRIPT_MODAL_ID)
              }
            />
          )}

          <div className="mt-1 flex w-full items-center justify-end gap-2 typography-body-5">
            <span
              className={clsxMerge("mt-1 opacity-50", {
                "opacity-100": isPreviewDefaultScript,
              })}
            >
              Preview
            </span>

            <Switch
              checked={isPreviewDefaultScript}
              onCheckedChange={() =>
                setIsPreviewDefaultScript(!isPreviewDefaultScript)
              }
            />
          </div>
        </div>
      </Layout.GridColumn>

      <Layout.GridColumn className="bg-white p-5 pb-44">
        <div className="h-full">
          <ScriptPreviewSection
            title="Default script"
            markdown={defaultScript}
          />
        </div>
      </Layout.GridColumn>
    </Layout.GridColumns>
  );
};
