import { widgets } from "@/components/shared/widgets";
import { WIDGETS } from "@/components/shared/widgets";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { useEffect, useMemo, useRef } from "react";
import { useAccountDetailsContext } from "../context";
import { CONFIRM_ACTION_MODAL_ID } from "@/components/shared/widgets/buttons/close-button";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";

export const useAccountDetailsCloseBehavior = () => {
  const { isEmailSection } = useAccountDetailsContext();

  const callStatus = useDialerCallStatus();
  const isAllowedToClose = useMemo(
    () => callStatus === DIALER_CALL_STATUS.BEFORE || isEmailSection,
    [callStatus, isEmailSection]
  );

  const isAllowedToCloseRef = useRef(isAllowedToClose);
  useEffect(() => {
    isAllowedToCloseRef.current = isAllowedToClose;
  }, [isAllowedToClose]);

  const openConfirmationModal = () => {
    modalHelpers.open(CONFIRM_ACTION_MODAL_ID);
  };

  useEffect(() => {
    const handleOnWidgetCloseEvent = () => {
      /**
       * When email section is open, user clicked close and we need to close the account details
       * as it can't close on its own from the sidebar as it has no access to account details context
       */

      if (isAllowedToCloseRef.current) {
        widgets.close({ id: WIDGETS.MAXED_DIALER });
      } else {
        openConfirmationModal();
      }
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.CLOSE,
      handleOnWidgetCloseEvent
    );

    return () => {
      window.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.CLOSE,
        handleOnWidgetCloseEvent
      );
    };
  }, []);
};
