import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { checkIfClient } from "shared/lib/helpers";

import { websocketEvents } from "./events";
import {
  WEBSOCKET_CONNECTION_TYPES,
  WEBSOCKET_CONNECTION_TYPES_NAMES,
  WEBSOCKET_EVENT_TYPES,
} from "./constants";
import {
  getWebsocketInstance,
  setWebsocketInstance,
} from "@/helpers/websockets";
import { getAuthToken } from "@/auth/utils";
import { dispatchCustomEvent } from "@/helpers/events";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { WSAuthRequestedDataI, WSClosedDataI } from "@/interfaces/events";

const establishWSConnection = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  console.log(`Try establish socket connection`);
  const ws = new WebSocket(connectionType);

  return ws;
};

const closeWSConnection = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  const ws = getWebsocketInstance(connectionType);

  if (ws?.readyState === WebSocket.OPEN) {
    ws.close();

    return true;
  } else {
    // Websocket is not open, or already closed.
    return false;
  }
};

const wsAuth = (ws: WebSocket) => {
  const authToken = getAuthToken();

  ws.send(
    JSON.stringify({
      msg_type: WEBSOCKET_EVENT_TYPES.AUTH,
      msg_auth_token: {
        auth_token: authToken,
      },
    })
  );
};

const setupSocketEventHandlers = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>,
  ws: WebSocket
) => {
  ws.onopen = () => {
    dispatchCustomEvent<WSAuthRequestedDataI>({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
      data: { connectionType },
    });

    wsAuth(ws);
  };

  ws.onclose = (event) => {
    dispatchCustomEvent<WSClosedDataI>({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      data: { event, connectionType },
    });
  };

  ws.onerror = (err) =>
    dd.rum.error(
      `${ERROR_CATEGORIES.WS}[${WEBSOCKET_CONNECTION_TYPES_NAMES[connectionType]}] - ws on error event`,
      {
        data: { error: err },
      }
    );

  ws.onmessage = (msg) => {
    try {
      const data = JSON.parse(msg.data as string);

      const eventType =
        data?.error_code || data?.error_str
          ? WEBSOCKET_EVENT_TYPES.ERROR
          : data?.type;

      websocketEvents({
        connectionType,
        eventType,
        data,
      });

      return false;
    } catch (e) {
      dd.error(
        `${ERROR_CATEGORIES.WS}[${WEBSOCKET_CONNECTION_TYPES_NAMES[connectionType]}] - failed parsing ws message`,
        {
          data: { msg, e },
        }
      );
    }
  };
};

const initWebSockets = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  const currentWs = getWebsocketInstance(connectionType);

  if (
    !currentWs ||
    [WebSocket.CLOSING as number, WebSocket.CLOSED].includes(
      currentWs.readyState
    )
  ) {
    // only create new ws if old one is closed or in process of closing
    const ws = establishWSConnection(connectionType);

    setupSocketEventHandlers(connectionType, ws);
    setWebsocketInstance(connectionType, ws);

    return ws;
  }
};

export const websocketListener = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  let removeEventListeners = () => {};

  if (process.env.NEXT_PUBLIC_SOCKET_DOMAIN && checkIfClient()) {
    initWebSockets(connectionType);

    setInterval(() => {
      const ws = getWebsocketInstance(connectionType);

      if (ws?.readyState === 1) {
        try {
          // console.log(`Websoket PING`);
          ws?.send(JSON.stringify({ message: "ping" }));
        } catch {
          console.log("Failed to send websoket ping");
        }
      }
    }, 10000);

    /**
     *  don't bother trying to reconnect if client is offline
     *  this floods the client with numerous websocket attempts
     */
    const eventHandlerWSClosed = () => {
      if (window.navigator.onLine) {
        initWebSockets(connectionType);
      }
    };

    /**
     * setup websocket connection again when client comes back online after having lost connection.
     */
    const eventHandlerWindowOnline = () => {
      initWebSockets(connectionType);
    };

    /**
     * Close existing websocket connection so that current uses of it in the app don't continue trying to use it.
     */
    const eventHandlerWindowOffline = () => {
      closeWSConnection(connectionType);
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      eventHandlerWSClosed
    );
    window.addEventListener("online", eventHandlerWindowOnline);
    window.addEventListener("offline", eventHandlerWindowOffline);

    removeEventListeners = () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
        eventHandlerWSClosed
      );
      window.removeEventListener("online", eventHandlerWindowOnline);
      window.removeEventListener("offline", eventHandlerWindowOffline);
    };
  }

  return removeEventListeners;
};
