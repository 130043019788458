import { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { EmailAutomationQueryKeys } from "./keys";
import { useApiClient } from "@/context/api-client";
import { queryClient } from "@/api/query-client";
import {
  PurchaseDomainRequestParamsI,
  UpdateEmailAutomationConfigParamsI,
} from "shared/lib/interfaces/email/automation";
import { DOMAIN_REGISTRATION_STATUS_REFETCH_INTERVAL } from "shared/lib/constants/email-automation";

export const useFetchEmailAutomationConfigQuery = (
  campaignId: string | undefined
) => {
  const api = useApiClient();

  const emailConfigQuery = useQuery({
    enabled: !!campaignId,
    queryKey: EmailAutomationQueryKeys.emailConfig(campaignId as string),
    select: (data) => data?.data || null,
    queryFn: () =>
      api.singleUserLicense.emailAutomation.getEmailAutomationConfig(
        campaignId as string
      ),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (emailConfigQuery.isError) {
      toast.error(
        `Failed to fetch email automation data. Please try to reload the page.`
      );
    }
  }, [emailConfigQuery.isError]);

  return emailConfigQuery;
};

export const useEmailAutomationSuggestedDomainsQuery = (
  campaignId: string,
  isQueryEnabled: boolean
) => {
  const api = useApiClient();

  const suggestedDomainsQuery = useQuery({
    enabled: !!campaignId && isQueryEnabled,
    refetchOnWindowFocus: false,
    queryKey: EmailAutomationQueryKeys.suggestedDomains(campaignId),
    select: (data) => data?.data?.items || null,
    queryFn: () =>
      api.singleUserLicense.emailAutomation.getEmailAutomationSuggestedDomains(
        campaignId
      ),
  });

  useEffect(() => {
    if (suggestedDomainsQuery.isError) {
      toast.error(
        `Failed to fetch suggested domains. Please try to reload the page.`
      );
    }
  }, [suggestedDomainsQuery.isError]);

  return suggestedDomainsQuery;
};

export const useEmailAutomationUpdateConfigMutation = (
  campaignId: string | undefined
) => {
  const api = useApiClient();

  return useMutation({
    mutationFn: (data: UpdateEmailAutomationConfigParamsI) =>
      api.singleUserLicense.emailAutomation.updateEmailAutomationConfig(
        campaignId as string,
        data
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: EmailAutomationQueryKeys.emailConfig(campaignId as string),
      }),
    onError: () =>
      toast.error(`Failed to update email automation. Please try again.`),
  });
};

export const useEmailAutomationPurchaseDomainMutation = (
  campaignId: string | undefined
) => {
  const api = useApiClient();

  return useMutation({
    mutationFn: (data: PurchaseDomainRequestParamsI) =>
      api.singleUserLicense.emailAutomation.purchaseDomain(
        campaignId as string,
        data
      ),
    onError: () =>
      toast.error(
        `Error purchasing domain. Please message us so we can assist.`
      ),
  });
};

export const useEmailAutomationPurchasedDomainStatusQuery = (
  campaignId: string | undefined,
  domainId: string | undefined,
  isEnabled: boolean
) => {
  const api = useApiClient();

  const domainRegistrationStatusQuery = useQuery({
    enabled: !!campaignId && !!domainId && isEnabled,
    queryKey: EmailAutomationQueryKeys.domainRegistrationStatus(
      campaignId as string,
      domainId as string
    ),
    select: (data) => data?.data || null,
    queryFn: () =>
      api.singleUserLicense.emailAutomation.getDomainRegistrationStatus(
        campaignId as string,
        domainId as string
      ),
    refetchInterval: DOMAIN_REGISTRATION_STATUS_REFETCH_INTERVAL,
  });

  useEffect(() => {
    if (domainRegistrationStatusQuery.isError) {
      toast.error(`Failed to fetch domain registration status.`);
    }
  }, [domainRegistrationStatusQuery.isError]);

  return domainRegistrationStatusQuery;
};

// TODO Uncomment when we enable cold outreach example generation for single user
// licenses
// export const useEmailAutomationWarmUpExampleContactsQuery = (
//   isEnabled: boolean,
//   campaignId: string | undefined,
//   searchTerm: string | undefined
// ) => {
//   const api = useApiClient();
//
//   const warmUpExampleContactsQuery = useInfiniteQuery({
//     enabled: isEnabled && !!campaignId,
//     queryKey: EmailAutomationQueryKeys.warmUpExampleContacts(
//       campaignId as string,
//       searchTerm
//     ),
//     queryFn: ({ pageParam }) =>
//       api.singleUserLicense.emailAutomation.getWarmUpExampleContacts(
//         campaignId as string,
//         {
//           next_token: pageParam,
//           search_term: searchTerm,
//         }
//       ),
//     initialPageParam: undefined as string | undefined,
//     getNextPageParam: (lastPage) => lastPage?.data?.next_token,
//     select: (data) =>
//       data?.pages.flatMap((page) => page.data.contacts || []) || [],
//   });
//
//   useEffect(() => {
//     if (warmUpExampleContactsQuery.isError) {
//       toast.error(`Failed to fetch warm up example contacts.`);
//     }
//   }, [warmUpExampleContactsQuery.isError]);
//
//   return warmUpExampleContactsQuery;
// };
