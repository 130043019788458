import { FC, useState } from "react";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import TextArea from "shared/ui/user-input/text-area";
import { useInputValue } from "shared/lib/hooks/use-input-value";
import { clsxMerge } from "shared/lib/helpers";

interface NotCreatedOpportunityModalPropsI {
  onSubmit: (notes: string) => Promise<any>;
}

export const NOT_CREATED_OPPORTUNITY_MODAL_ID =
  "glenx-not-created-opportunity-modal";

const Textarea = TextArea((props) => <textarea {...props} />);

const NotCreatedOpportunityModalContent: FC<
  NotCreatedOpportunityModalPropsI & ModalChildrenFnPropsI
> = ({ onSubmit, handleClose }) => {
  const [value, onChange] = useInputValue("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const markMeetingResult = await onSubmit(value);

    if (markMeetingResult) {
      handleClose();
    }

    setIsLoading(false);
  };

  return (
    <section className="flex flex-col">
      <Textarea
        name="notes"
        label="Additional notes"
        placeholder="Share more details about your meeting"
        inputProps={{
          value,
          onChange,
        }}
      />

      <div className="flex justify-end gap-2">
        <button
          className={clsxMerge("btn-ae-text", isLoading && "disabled")}
          onClick={handleClose}
        >
          Cancel
        </button>

        <button
          className={clsxMerge(
            "btn-ae-default",
            isLoading && "loading disabled"
          )}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export const NotCreatedGlenXOpportunityModal: FC<
  NotCreatedOpportunityModalPropsI
> = (props) => (
  <Modal
    id={NOT_CREATED_OPPORTUNITY_MODAL_ID}
    title="Opportunity not created"
    description="Mark this meeting as opportunity not created"
  >
    {(modalProps) => (
      <NotCreatedOpportunityModalContent {...props} {...modalProps} />
    )}
  </Modal>
);
