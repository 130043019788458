import { useMemo } from "react";

import { NotificationI } from "@/interfaces/notification";
import { NotificationCampaignImage } from "./campaign";
import { NotificationIndividualImage } from "./individual";
import { NOTIFICATION_TYPES } from "shared/lib/constants/notifications";
import { NotificationTypeIcon } from "./type-icon";

export type NotificationImageI = NotificationI;

export const NotificationImage = (props: NotificationImageI) => {
  const { notification_type, contact_name } = props;

  const isIndividual = useMemo(() => {
    return [
      NOTIFICATION_TYPES.MISSED_CALL as string,
      NOTIFICATION_TYPES.EMAIL_OPENED,
      NOTIFICATION_TYPES.EMAIL_RECEIVED,
      NOTIFICATION_TYPES.COLD_OUTREACH_EMAIL_RESPONDED,
    ].includes(notification_type as string);
  }, [notification_type]);

  return (
    <div className="relative">
      {isIndividual ? (
        <NotificationIndividualImage contact_name={contact_name} />
      ) : (
        <NotificationCampaignImage />
      )}

      <NotificationTypeIcon {...props} />
    </div>
  );
};
