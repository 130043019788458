import { ChangeEvent, useCallback, useState } from "react";

/**
 * This hook is useful when making controllable input fields, it's onChange bound
 * directly to the input field (input or textarea) via ChangeEvent.
 *
 * @param initialValue
 */
export const useInputValue = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    []
  );

  return [value, onChange] as const;
};
