import { useCallback, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

import { clsxMerge } from "shared/lib/helpers";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  useEmailGenerationInfo,
  useGenerateAIEmailStreamBody,
  useGenerateAIEmailStreamSubject,
} from "@/modules/email-templates-sidebar/queries";
import { AILoader } from "@/components/base/loaders/ai-loader";
import { GetEmailGenerationStatusResponseI } from "@/api/routes/email";
import { TextField } from "shared/ui";
import { EmailEditor } from "shared/ui/email/editor";
import { EmailAttachmentsSection } from "@/modules/email-templates-sidebar/shared/email-attachments-section";
import { GenerateEmailStreamRequestParamsI } from "shared/lib/interfaces/email/ai";

const EnhancedTextField = TextField();

export const EmailTemplateExample = ({
  className,
  prompt,
}: {
  className?: string;
  prompt?: string;
}) => {
  const { campaign, account, contacts } = useAccountDetailsContext();
  const { mutateAsync: generateAIEmailBodyStreamAsync } =
    useGenerateAIEmailStreamBody();
  const { mutateAsync: generateAIEmailSubjectStreamAsync } =
    useGenerateAIEmailStreamSubject();
  const { mutateAsync: getEmailConfigAsync } = useEmailGenerationInfo();

  const [isGeneratingExample, setIsGeneratingExample] = useState(false);
  const [emailData, setEmailData] =
    useState<GetEmailGenerationStatusResponseI>();

  const handleStream = async (
    isBody: boolean,
    parameters: GenerateEmailStreamRequestParamsI
  ) => {
    try {
      let resp;
      if (isBody) {
        resp = await generateAIEmailBodyStreamAsync({
          parameters: parameters,
        });
      } else {
        resp = await generateAIEmailSubjectStreamAsync({
          parameters: parameters,
        });
      }

      if (!resp.body) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      const reader = resp.body?.getReader();
      const decoder = new TextDecoder();

      let done = false;

      while (!done) {
        const readResult = await reader?.read();
        const value = readResult?.value;
        const readerDone = readResult?.done;

        done = readerDone ?? true;

        if (value) {
          setIsGeneratingExample(false);
          const chunk = decoder.decode(value);

          if (isBody) {
            setEmailData((prev) => ({
              ...prev,
              body: (prev?.body || "") + chunk,
            }));
          } else {
            setEmailData((prev) => ({
              ...prev,
              subject: (prev?.subject || "") + chunk,
            }));
          }
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const generatePreview = useCallback(async () => {
    const contactId = contacts?.[0]?.id;

    if (!prompt) {
      toast.error("Prompt is required to generate preview.");
      return;
    }

    if (campaign?.id && account?.id && contactId) {
      const resp = await getEmailConfigAsync({
        templateId: undefined,
        parameters: {
          campaign_id: campaign.id,
          account_id: account.id,
          contact_id: contactId,
        },
      });

      if (resp.status === 200) {
        setIsGeneratingExample(true);
        const parameters: GenerateEmailStreamRequestParamsI = {
          campaign_id: resp.data.campaign_id,

          contact_id: resp.data.contact_id,
          contact_email: resp.data.contact_email,
          contact_name: resp.data.contact_name,
          contact_title: resp.data.contact_title,

          account_id: resp.data.account_id,
          account_name: resp.data.account_name,
          account_industry: resp.data.account_industry,
          account_city: resp.data.account_city,
          account_state: resp.data.account_state,
          account_headcount: resp.data.account_headcount,
          account_website: resp.data.account_website,
          email_signature: resp.data.email_signature,
          user_prompt: prompt,
        };
        // stream email subject
        await Promise.all([
          handleStream(false, parameters), // stream email subject
          handleStream(true, parameters), // stream email body
        ]);
      }
    }
  }, [prompt]);

  return (
    <section className={className}>
      <div className="mb-11 flex">
        <div className="flex w-full items-center justify-between">
          <div className="typography-body-3-medium">View email example</div>

          <button
            type="button"
            className={clsxMerge(
              "ae-link flex cursor-pointer items-center typography-body-4-medium",
              { "opacity-60": isGeneratingExample }
            )}
            disabled={isGeneratingExample}
            onClick={generatePreview}
          >
            <ArrowPathIcon className="mr-1 w-4" />
            Generate preview
          </button>
        </div>
      </div>

      {emailData && (
        <div>
          <p className="mb-1">Subject</p>
          <EnhancedTextField
            className="mb-2 rounded-lg border border-[#ccc]"
            name="subject"
            placeholder=""
            inputProps={{ value: emailData.subject }}
            disabled
          />

          <EmailEditor
            placeholder=""
            content={emailData.body}
            disabled={true}
            className="border border-[#CCC] bg-transparent"
            menuClassName="hidden"
          />

          <EmailAttachmentsSection />
        </div>
      )}

      {isGeneratingExample && (
        <>
          <div>
            <AILoader className="mx-auto" title="AI building template" />
          </div>
        </>
      )}
    </section>
  );
};
