import { AxiosInstance } from "axios";

import {
  GetCampaignOnePagerUploadUrlParamsI,
  GetCampaignOnePagerUploadUrlResponseI,
  GetDomainRegistrationStatusResponseI,
  GetEmailAutomationConfigResponseI,
  GetEmailAutomationSuggestedDomainsResponseI,
  GetWarmUpExampleContactsParamsI,
  GetWarmUpExampleContactsResponseI,
  PurchaseDomainRequestParamsI,
  PurchaseDomainResponseI,
  UpdateEmailAutomationConfigParamsI,
} from "shared/lib/interfaces/email/automation";

export const SingleLicenseEmailAutomationAPIEndpoints = (
  api: AxiosInstance
) => ({
  getEmailAutomationConfig: (campaignId: string) =>
    api.get<GetEmailAutomationConfigResponseI>(
      `/x/v1/campaign/${campaignId}/email_config`
    ),

  getEmailAutomationSuggestedDomains: (campaignId: string) =>
    api.get<GetEmailAutomationSuggestedDomainsResponseI>(
      `/x/v1/campaign/${campaignId}/suggested_domains`
    ),

  getCampaignOnePagerUploadUrl: (
    campaignId: string,
    params: GetCampaignOnePagerUploadUrlParamsI
  ) =>
    api.post<GetCampaignOnePagerUploadUrlResponseI>(
      `/x/v1/campaign/${campaignId}/one_pager_upload_url`,
      params
    ),

  updateEmailAutomationConfig: (
    campaignId: string,
    params: UpdateEmailAutomationConfigParamsI
  ) => api.patch(`/x/v1/campaign/${campaignId}/email_config`, params),

  purchaseDomain: (campaignId: string, params: PurchaseDomainRequestParamsI) =>
    api.post<PurchaseDomainResponseI>(
      `/x/v1/campaign/${campaignId}/email_config/register_domain`,
      params
    ),

  getDomainRegistrationStatus: (campaignId: string, domainId: string) =>
    api.get<GetDomainRegistrationStatusResponseI>(
      `/x/v1/campaign/${campaignId}/email_config/email_domain/${domainId}/status`
    ),

  getWarmUpExampleContacts: (
    campaignId: string,
    params: GetWarmUpExampleContactsParamsI
  ) =>
    api.post<GetWarmUpExampleContactsResponseI>(
      `/x/v1/campaign/${campaignId}/email_config/available_contacts`,
      params
    ),
});
