import { AxiosInstance } from "axios";

import { DefaultResponseI } from "shared/lib/interfaces/api";

export interface GetPaymentMethodResponseI {
  is_finished_verification: boolean;
  bank_name: string;
  last4: string;
  id: string;
  payment_type: string;
}

export interface DeletePaymentMethodResponseI {
  status: string;
  message: string;
}

export interface SetupIntentResponseI {
  client_secret: string;
}

export interface SubscriptionHistoryResponseI extends DefaultResponseI {
  is_stripe_subscription_active: boolean;
  next_payment_date?: string;
  next_payment_amount?: number;
  last_payment_date?: string;
}

export const SingleUserLicenseBillingAPIEndpoints = (api: AxiosInstance) => ({
  getPaymentMethod: () =>
    api.get<GetPaymentMethodResponseI>("/x/v1/payment_method"),

  deletePaymentMethod: (paymentMethodId: string) =>
    api.delete<DeletePaymentMethodResponseI>("/x/v1/payment_method", {
      data: {
        id: paymentMethodId,
      },
    }),

  setupIntent: () => api.post<SetupIntentResponseI>("/x/v1/setup_intent"),

  subscriptionHistory: (campaignId: string) =>
    api.get<SubscriptionHistoryResponseI>(
      `/x/v1/campaign/${campaignId}/subscription_history`
    ),

  cancelSubscription: (campaignId: string) =>
    api.post(`/x/v1/campaign/${campaignId}/cancel_subscription`),

  restartSubscription: (campaignId: string) =>
    api.post(`/x/v1/campaign/${campaignId}/restart_subscription`),
});
