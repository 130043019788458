import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";
import { WIDGETS } from "@/constants/widgets";

export const QUERY_PARAMS = {
  LIST: "list",
  NEW_LEAD: "new_lead",
} as const;

const Router = {
  signup: () => "/signup",

  /**
   * NOTE in stack overflow people ask how to auth0 logout without context
   * help them and tell about the workaround.
   */
  logout: () => "/logout",

  home: () => "/",
  verify: () => "/verify",
  unauthorized: () => "/unauthorized",
  coaching: () => "/coaching",
  emailInbox: () => "/email-inbox",
  performance: () => "/performance",
  history: () => "/history",

  profileInactive: () => "/profile-inactive",
  cannotBeGlenXUser: () => "/service-unavailable",

  training: () => "/training",
  trainingModule: (trainingModuleId = "") => `/training/${trainingModuleId}`,

  lists: (listId?: string) =>
    `/lists${Router.searchQueryParams.listId(listId)}`,

  accountDetailsResources: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=resources`,

  accountDetailsMessages: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=messages`,

  createNewLead: () => `${Router.lists()}?${QUERY_PARAMS.NEW_LEAD}=1`,

  settings: {
    profile: () => "/settings/profile",
    callForwarding: () => "/settings/call-forwarding",
    emailSignature: () => "/settings/email-signature",
  },

  knowledge: {
    intro: () => "/knowledge/intro",
    builder: () => "/knowledge/builder",
  },

  searchQueryParams: {
    accountDetails: (campaignId: string, accountId: string) =>
      new URLSearchParams({
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE]: WIDGETS.MAXED_DIALER,
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID]: accountId,
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID]: campaignId,
      }).toString(),
    listId: (listId?: string) =>
      listId ? `?${new URLSearchParams({ list: listId }).toString()}` : "",
  },

  singleUserLicense: {
    gettingStarted: () => "/getting-started",
    emailAutomation: () => "/settings/email-automation",
    billing: () => "/settings/billing",
  },
};

export default Router;
