import { FC, useMemo } from "react";

import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";
import { Checkbox } from "shared/ui/shadcn/checkbox";

import {
  QualificationCriteriaTable,
  QualificationCriteriaTablePropsI,
} from "./base";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_LABELS,
  QUALIFICATION_CRITERIA_TYPES,
} from "shared/lib/constants/qualification-criteria";
import {
  QualificationCriteriaTableTogglesValueI,
  QualificationCriteriaTypeWithBonusI,
} from "shared/lib/interfaces/qualification-criteria";
import {
  checkIfSelectedQCsQualify,
  mapSelectedQCsToRequest,
} from "shared/lib/helpers/qualification-criteria";

interface QualificationCriteriaTableWithTogglesPropsI
  extends QualificationCriteriaTablePropsI,
    ControllableComponentPropsI<QualificationCriteriaTableTogglesValueI> {
  isEditable?: boolean;
  hasBonus?: boolean;
}

const QCCheckbox: FC<
  Omit<QualificationCriteriaTableWithTogglesPropsI, "hasBonus" | "texts"> & {
    type: QualificationCriteriaTypeWithBonusI;
  }
> = ({ isEditable, value: _value, type, onChange }) => {
  const value = _value as QualificationCriteriaTableTogglesValueI;

  return (
    <label className="flex-center h-9 w-full">
      <Checkbox
        disabled={!isEditable}
        checked={value[type] ?? false}
        onCheckedChange={() => onChange({ ...value, [type]: !value[type] })}
      />
    </label>
  );
};

export const QualificationCriteriaTableWithToggles: FC<
  QualificationCriteriaTableWithTogglesPropsI
> = ({
  isEditable = true,
  hasBonus = false,
  value: _value,
  onChange,
  texts,
  ...restTableProps
}) => {
  const value = _value as QualificationCriteriaTableTogglesValueI;

  // Need that to only allow toggling the bonus criteria if all basic criteria
  // are met
  const areBasicQCsMet = useMemo(
    () =>
      _value
        ? checkIfSelectedQCsQualify(mapSelectedQCsToRequest(_value))
        : false,
    [_value]
  );

  const handleChange = (newValue: QualificationCriteriaTableTogglesValueI) => {
    if (
      newValue &&
      !checkIfSelectedQCsQualify(mapSelectedQCsToRequest(newValue)) &&
      newValue[BONUS_QUALIFICATION_CRITERIA_TYPE]
    ) {
      onChange({ ...newValue, [BONUS_QUALIFICATION_CRITERIA_TYPE]: false });
      return;
    }

    onChange(newValue);
  };

  const rightExtension = useMemo(() => {
    const head = {
      className: "text-center",
      content: "Met",
    };

    const cells = QUALIFICATION_CRITERIA_TYPES.map((type) => ({
      className: "text-center pl-0 h-max",
      content: (
        <QCCheckbox
          type={type}
          isEditable={isEditable}
          value={value}
          onChange={handleChange}
        />
      ),
    }));

    return { head, cells };
  }, [isEditable, hasBonus, _value]);

  return (
    <QualificationCriteriaTable
      {...restTableProps}
      texts={texts}
      rightExtension={rightExtension}
      bottomExtension={
        hasBonus
          ? {
              cells: [
                {
                  content:
                    QUALIFICATION_CRITERIA_LABELS[
                      BONUS_QUALIFICATION_CRITERIA_TYPE
                    ],
                },
                {
                  content: texts[BONUS_QUALIFICATION_CRITERIA_TYPE],
                },
                {
                  className: "text-center pl-0",
                  content: (
                    <QCCheckbox
                      type={BONUS_QUALIFICATION_CRITERIA_TYPE}
                      isEditable={areBasicQCsMet && isEditable}
                      value={value}
                      onChange={handleChange}
                    />
                  ),
                },
              ],
            }
          : undefined
      }
    />
  );
};
