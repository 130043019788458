import { UserRoundSearch } from "lucide-react";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { FeatureIntroTooltip } from "shared/ui/feature-intro/tooltip-guide";

import { FIND_MORE_LEADS_MODAL } from "@/components/modals/account-find-more-leads-modal";
import { FIND_MORE_LEADS_BUTTON_ID } from "@/constants/element-ids";
import { useListCallingTour } from "@/components/modules/tours/list-calling/hooks";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

interface FindLeadsButtonProps extends PropsWithClassNameI {}

export const FindLeadsButton = ({ className }: FindLeadsButtonProps) => {
  const { tooltips, isStepVisible, markStepAsCompleted } = useListCallingTour();
  const callStatus = useDialerCallStatus();

  return (
    <>
      <div
        // An external wrapper serving to create a border gradient effect
        className={clsxMerge(
          "relative w-fit rounded-lg border",
          "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]",
          className
        )}
      >
        <div
          id={FIND_MORE_LEADS_BUTTON_ID}
          className={clsxMerge(
            "bg-white",
            "relative",
            "flex items-center",
            "cursor-pointer transition-colors",
            "rounded-[7px] p-1 px-2",
            "text-[#333333] typography-body-5",
            "hover:bg-[rgb(247,247,247)]"
          )}
          onClick={() => {
            modalHelpers.trigger(FIND_MORE_LEADS_MODAL);
            markStepAsCompleted(FIND_MORE_LEADS_BUTTON_ID);
          }}
        >
          <UserRoundSearch className="mr-2 size-4" />
          Find more leads
        </div>
      </div>

      {isStepVisible(FIND_MORE_LEADS_BUTTON_ID) &&
        callStatus === DIALER_CALL_STATUS.AFTER && (
          <FeatureIntroTooltip
            referenceId={FIND_MORE_LEADS_BUTTON_ID}
            {...tooltips[FIND_MORE_LEADS_BUTTON_ID]}
          />
        )}
    </>
  );
};
