import { safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT_ONLY_TIME } from "shared/lib/constants/time";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";
import { ScriptEditorSection as ScriptEditorField } from "shared/ui/account-details/scripts/editor-section";
import { useFetchCampaignScript } from "@/modules/pipeline/account-details/queries";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

export const ScriptEditorSection = ({
  value,
  isSaving,
  isModifiedFromDefault = false,
  onChange = () => {},
  onReset = () => {},
}: {
  value: string;
  isSaving: boolean;
  isModifiedFromDefault?: boolean;
  onChange: (value: string) => void;
  onReset: () => void;
}) => {
  const { campaign } = useAccountDetailsContext();
  const { data: userScript } = useFetchCampaignScript(campaign?.id);

  const { updated_at: autoSavedTime } = userScript || {};

  return (
    <>
      <div className="mb-1.5 flex justify-between">
        <div className="typography-body-4-medium">Your custom script</div>

        <div className="flex items-center gap-1">
          {isSaving && <LoadingSpinner spinnerClassName="size-5" />}

          {isModifiedFromDefault && (
            <>
              {autoSavedTime && !isSaving && (
                <div className=" text-base-content/60 typography-body-4">
                  Autosaved at{" "}
                  {safeGetDate(autoSavedTime, DATE_FORMAT_ONLY_TIME)}
                </div>
              )}

              <div className="divider divider-horizontal mx-0 w-2"></div>
              <button className="ae-link typography-body-4" onClick={onReset}>
                Reset to default
              </button>
            </>
          )}
        </div>
      </div>

      <div className="h-full">
        <ScriptEditorField text={value} onChange={onChange} />
      </div>
    </>
  );
};
