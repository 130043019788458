export const ACCOUNTS_DETAILS_TABS = {
  DIALER_TOOLKIT: "dialer_toolkit",
  DETAILS: "details",
  HISTORY: "history",
  AE_MESSAGES: "ae_messages",
  SCRIPTS: "scripts",
} as const;

export const ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME =
  "ae-link typography-body-4 flex items-center gap-2";
