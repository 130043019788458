export const DIALER_STATES = {
  PRE: "pre",
  LEAD_SELECTION: "lead_selection",
  DIALING: "dialing",
  CALL: "call",
  DISPOSITION: "disposition",
  POST: "post",

  LOADING: "loading",
  UNAUTHORIZED: "unauthorized",
};

export const DIALER_CALL_STATUS = {
  BEFORE: "before",
  DIALING: "dialing",
  DURING: "during",
  AFTER: "after",
} as const;

export const DIALER_MODES = {
  /**
   * DEFAULT mode is a mode used in the v1 dialer context
   * and it means NOT A DEMO mode
   *
   * We will refactor this when we fully migrate to dialer v2
   */
  DEFAULT: "default",
  /**
   * DEMO mode is a mode used in the v1 dialer context
   * and it is used in the embed page that is on Landing portal.
   * This is basically limited funcionality of the dialer with guides
   *
   * We will refactor this when we fully migrate to dialer v2
   */
  DEMO: "demo",
  /**
   * AUTODIAL mode is when caller continuosly get new leads as soon as
   * previous call is dispositioned
   *
   */
  AUTODIAL: "auto-dial",
  /**
   * TARGETED mode is when caller is able to trigger every call manualy
   * after reviewing prospect info
   *
   */
  TARGETED: "targeted",
  /**
   * WIDGET_MINI_DIALER mode is embeddable version of the dialer
   * primarily used to do direct calls on the pipeline view
   *
   */
  WIDGET_MINI_DIALER: "widget_mini-dialer",

  /**
   * WIDGET_MAXED_DIALER mode is a sidebar with account details
   * where user is able to start direct calls or iterate over
   * dialing list
   */
  WIDGET_MAXED_DIALER: "widget_maxed_dialer",
} as const;

export const DIALER_LIVE_TRANSFER_STATUSES = {
  CALENDLY_BOOKING_COMPLETE: "calendly_booking_complete",
  LIVE_TRANSFER_COMPLETE: "live_transfer_complete",
} as const;

export const DIALER_VERSIONS = {
  V1: "v1",
  V2: "v2",
} as const;

export const DIALER_WIDGET_VARIATIONS = {
  DEFAULT: "default",
  SIDEBAR: "sidebar",
} as const;
