// React imports
import { FC, useEffect, useMemo } from "react";

// UI Components
import { Sidebar } from "shared/ui";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import { DialerCloseButton as CloseButton } from "@/components/shared/widgets/buttons/close-button";
import { AccountDetailsModule } from "@/modules/pipeline/account-details";
import { MaxedDialerDataStates } from "./data-states";

// Hooks
import { useAccountDetails } from "@/hooks/use-account-details";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { useMaxedDialerSearchQueryParams } from "./use-search-query-params";
import { usePrefetchedConnectedLead } from "./use-prefetched-connected-lead";

// Context
import { DialerV2Provider } from "@/context/dialer-v2";
import { defaultContextObject } from "@/context/dialer-v1/default-context";

// Constants
import {
  DIALER_CALL_STATUS,
  DIALER_MODES,
  DIALER_VERSIONS,
} from "@/constants/dialer";
import { WIDGETS, widgets } from "@/components/shared/widgets";
import { LOG_CATEGORIES } from "@/constants/logs";
import { CUSTOM_EVENTS } from "@/constants/custom-events";

// Types
import { MaxedDialerWidgetConfigI } from "./interface";

// Utilities
import { dd } from "@/helpers/datadog";
import { dispatchCustomEvent } from "@/helpers/events";

const AccountDetailsSidebar: FC<MaxedDialerWidgetConfigI> = ({
  campaignId,
  accountId,
  accountDetails,
}) => {
  const callStatus = useDialerCallStatus();

  const accountDetailsProps = useAccountDetails(
    campaignId as string,
    accountId as string,
    accountDetails
  );

  const { accountDetailsApi } = accountDetailsProps;

  const {
    campaign,
    account,
    onAccountUpdate,
    isFetchingData,
    isInitialFetchComplete,
    isErrorOccurred,
  } = accountDetailsProps;

  const isAllowedToClose = useMemo(
    () => callStatus === DIALER_CALL_STATUS.BEFORE,
    [callStatus]
  );

  const handleClose = () => {
    /**
     * NOTE account details context is not available here
     * so we need to dispatch custom event to close the account details
     * based not just the call status but other criteria
     */
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.CLOSE,
    });
  };

  const handleErrorClose = () => {
    widgets.close({ id: WIDGETS.MAXED_DIALER });
  };

  /**
   * For the max side of the sidebar we calculate max values for each column
   */
  return (
    <Sidebar
      className="bg-none p-0"
      headerClassName="p-0 h-[0]"
      closeButtonClassName="absolute top-5 right-5"
      containerClassName="max-w-[calc(400px+354px+874px)] w-full shadow-none"
      isOverlayClickable={isAllowedToClose}
      hideTitle
      hideBg
      disableAnimation
      handleClose={handleClose}
      closeButton={CloseButton}
    >
      <DataStatesWrapper
        api={{
          isFetching: !isInitialFetchComplete && !!isFetchingData,
          isError: accountDetailsApi.isError || isErrorOccurred,
          isSuccess: accountDetailsApi.isSuccess && !isErrorOccurred,
          isEmpty: !account || !campaign,
        }}
        empty={
          <MaxedDialerDataStates.EmptyState
            account={account}
            campaign={campaign}
          />
        }
        error={
          <MaxedDialerDataStates.ErrorState
            onClose={handleErrorClose}
            onReload={onAccountUpdate}
          />
        }
        viewName="account"
      >
        <AccountDetailsModule {...accountDetailsProps} />
      </DataStatesWrapper>
    </Sidebar>
  );
};

export const WidgetMaxedDialer: FC<MaxedDialerWidgetConfigI> = (props) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const { campaignId, accountId, connectedLead, accountDetails } = props;

  useMaxedDialerSearchQueryParams(campaignId as string, accountId as string);
  usePrefetchedConnectedLead(connectedLead, accountDetails);

  useEffect(() => {
    dd.rum.log(`${LOG_CATEGORIES.MAXED_DAILER} - widget is mounted`, {
      data: { props },
    });
  }, []);

  return isGlobalDialer ? (
    <AccountDetailsSidebar {...props} />
  ) : (
    //In the scenario when Global Dialer is not enabled
    //Initializing Dialer Provider Locally
    <DialerV2Provider
      context={{
        //TODO Make it option it include spread of defaultContextObject
        ...defaultContextObject,
        campaignId,
      }}
      config={{
        version: DIALER_VERSIONS.V2,
        mode: DIALER_MODES.WIDGET_MAXED_DIALER,
      }}
    >
      <AccountDetailsSidebar {...props} />
    </DialerV2Provider>
  );
};
