import { MeetingTakerI } from "@/interfaces/accounts";
import { DISPOSITION_SECTION_NOTES_TEXTAREA_ID } from "@/components/modules/pipeline/account-details/sections/disposition-section/note-row";
import { MeetingTakerDropdown } from "@/components/modules/pipeline/account-details/shared/meeting-taker-dropdown";

export const DispositionMeetingTakerDropdown = ({
  name,
  errors,
  touched,
  onChange,
}: {
  name?: string;
  errors?: string;
  touched?: boolean;
  onChange?: (account_executive_id?: string) => void;
}) => {
  const handleOnChangeMeetingTaker = (meetingTaker: MeetingTakerI) => {
    onChange?.(meetingTaker?.id);

    (
      window.document.querySelector(
        `#${DISPOSITION_SECTION_NOTES_TEXTAREA_ID} textarea`
      ) as any
    )?.focus();
  };

  return (
    <MeetingTakerDropdown
      name={name}
      errors={errors}
      touched={touched}
      onChange={handleOnChangeMeetingTaker}
      textFieldClassName="w-[250px]"
      dropdownClassName="w-[250px]"
      isFormik
    />
  );
};
