import {
  NEXT_TOUCH_TIME_OPTIONS,
  NEXT_TOUCH_TIME_OPTIONS_MAP,
} from "shared/lib/constants/dispositions";

import omit from "lodash/omit";

export const DISPOSITION_CONTROL_KEYS = {
  CONTINUE_DIALING_LIST: "dialing-list",
} as const;

export const MIN_CONVERSATION_TIME_SECONDS = 30;

export const GLENX_NEXT_TOUCH_TIME_OPTIONS = {
  ...omit(NEXT_TOUCH_TIME_OPTIONS, "CUSTOM"),
  NONE: "none",
  CUSTOM: "custom",
} as const;

export const GLENX_NEXT_TOUCH_TIME_OPTIONS_MAP = {
  ...NEXT_TOUCH_TIME_OPTIONS_MAP,
  [GLENX_NEXT_TOUCH_TIME_OPTIONS.NONE]: "Do not follow up",
} as const;
