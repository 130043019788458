import { AxiosInstance } from "axios";

import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { DefaultResponseI } from "shared/lib/interfaces/api";
import { MeetingDispositionI } from "@/modules/account-executive/interfaces";

export interface AccountExecutiveSurveyUser {
  first_name?: string;
  last_name?: string;
  profile_pic_url?: string;
  user_id?: string;
}

export interface GetAccountExecutiveSurveyResponseI extends DefaultResponseI {
  user: AccountExecutiveSurveyUser;
  contact: CallContactI;
  account: CallAccountI;
  event: CalendlyEventI;
  already_submitted: boolean;
  already_submitted_review: boolean;
}

export interface GetAccountExecutiveSurveyResponseI extends DefaultResponseI {
  user: {
    first_name?: string;
    last_name?: string;
    profile_pic_url?: string;
    user_id?: string;
  };
  contact: CallContactI;
  account: CallAccountI;
  event: CalendlyEventI;
  already_submitted: boolean;
  already_submitted_review: boolean;
}

export interface SubmitAccountExecutiveSurveyParamsI {
  meeting_disposition: MeetingDispositionI;

  // is_opportunity flag replaces the concept of qualification criteria
  is_opportunity: boolean;

  notes?: string;
  reschedule_time?: string;
}

export const AccountExecutiveSurveyEndpoints = (api: AxiosInstance) => ({
  getAccountExecutiveSurvey: (surveyId: string) =>
    api.get<GetAccountExecutiveSurveyResponseI>(
      `/public/customer/v1/ae_survey/${surveyId}`
    ),

  submitAccountExecutiveSurvey: (
    surveyId: string,
    params: SubmitAccountExecutiveSurveyParamsI
  ) => api.post(`/public/customer/x/v1/ae_survey/${surveyId}`, params),
});
