import { useRef } from "react";
import queryString from "query-string";
import { checkIfClient } from "shared/lib/helpers";

import isEqual from "lodash/isEqual";

export const useSearchQueryParams = (key?: string): string => {
  const paramsRef = useRef<any>(null);

  if (checkIfClient()) {
    const url = new URL(window.location.href);
    const params = queryString.parse(url.search) || {};

    if (!isEqual(paramsRef.current, params)) {
      paramsRef.current = params;
    }
  }

  return key ? paramsRef.current?.[key] : paramsRef.current;
};
