import { TIME_INTERVALS_MS } from "./time";

export const SAVING_DATA_STATES = {
  SAVING: "saving",
  SAVED: "saved",
} as const;

export const DOMAIN_STATUS = {
  IN_PROGRESS: "in_progress",
  SUCCESSFUL: "successful",
  FAILED: "failed",
} as const;

export const DOMAIN_STATUS_STRINGS = {
  [DOMAIN_STATUS.IN_PROGRESS]: "In progress",
  [DOMAIN_STATUS.SUCCESSFUL]: "Live",
  [DOMAIN_STATUS.FAILED]: "Failed",
};

// Interval for fetching the status of the domain that is being purchased
export const DOMAIN_REGISTRATION_STATUS_REFETCH_INTERVAL =
  TIME_INTERVALS_MS.ONE_SECOND * 2;
