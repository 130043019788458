const EMAIL_AUTOMATION_COMMON_KEY = "email-automation";

export const EmailAutomationQueryKeys = {
  emailConfig: (campaignId: string) => [
    EMAIL_AUTOMATION_COMMON_KEY,
    campaignId,
  ],
  suggestedDomains: (campaignId: string) => [
    EMAIL_AUTOMATION_COMMON_KEY,
    "suggestedDomains",
    campaignId,
  ],
  updateConfig: (campaignId: string) => [
    EMAIL_AUTOMATION_COMMON_KEY,
    "updateConfig",
    campaignId,
  ],
  domainRegistrationStatus: (campaignId: string, domainId: string) => [
    EMAIL_AUTOMATION_COMMON_KEY,
    "domainRegistrationStatus",
    campaignId,
    domainId,
  ],
  warmUpExampleContacts: (
    campaignId: string,
    searchTerm: string | undefined
  ) => [
    EMAIL_AUTOMATION_COMMON_KEY,
    "warmUpExampleContacts",
    campaignId,
    searchTerm,
  ],
};
