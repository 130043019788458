import { useEffect, useMemo } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import { useQueryState } from "nuqs";

import { checkIfRemoteEnvironment } from "shared/lib/helpers/environment";
import { getFullEntityName } from "shared/lib/helpers";

import Router from "@/helpers/router";
import { useGlobalContext } from "@/hooks/use-global-context";
import { WIDGETS_ENUM } from "@/constants/widgets";

const BLOCKED_LIST = [Router.lists()];

export const PylonInAppChatScript = () => {
  const { glencocoUser } = useGlobalContext();
  const { route } = useRouter();
  const [activeWidget] = useQueryState("widget");

  const routeAllowsPylon = useMemo(
    () =>
      BLOCKED_LIST.every((blocked) => !route?.includes(blocked)) &&
      route != Router.home(),
    [route]
  );

  const shouldRenderPylon =
    activeWidget !== WIDGETS_ENUM.MAXED_DIALER &&
    routeAllowsPylon &&
    checkIfRemoteEnvironment(process.env.NEXT_PUBLIC_ENV) &&
    !!glencocoUser;

  // const shouldAutoOpenChatWindowOnce = useMemo(
  //   () => [Router.home(), Router.lists()].includes(route),
  //   [route]
  // );

  useEffect(() => {
    if (!window.Pylon) {
      return;
    }

    const isPylonVisible =
      activeWidget != WIDGETS_ENUM.MAXED_DIALER && routeAllowsPylon;

    window.Pylon(isPylonVisible ? "showChatBubble" : "hideChatBubble");
  }, [activeWidget, routeAllowsPylon]);

  useEffect(() => {
    if (shouldRenderPylon) {
      window.pylon = {
        chat_settings: {
          app_id: process.env.NEXT_PUBLIC_PYLON_APP_ID as string,
          email: glencocoUser?.email as string,
          name: getFullEntityName(glencocoUser),
          avatar_url: glencocoUser?.profile_image_url as string,
        },
      };
    }
  }, [shouldRenderPylon]);

  /**
   * We want to automatically open the Pylon chat window once per session, when
   * the user first gets to the homepage (or lists, which basically is the same page).
   */
  // Jason and Brandon asked to remove this. Commenting out for now incase Ingwon wants to add it back.
  // useEffectOnceWhen(() => {
  //   if (!storageRef.current.pylonWidgetAutoOpenedAt) {
  //     window?.Pylon?.("show");

  //     /**
  //      * There is no unsubscribe mechanism in Pylon, so leaving it as it is,
  //      * assuming that they handle it properly internally.
  //      */
  //     window?.Pylon?.("onShow", () => {
  //       storageRef.current.pylonWidgetAutoOpenedAt = new Date().toString();
  //     });
  //   }
  // }, shouldAutoOpenChatWindowOnce);

  if (!shouldRenderPylon) {
    return null;
  }

  return (
    <Script id="pylon-in-app-chat" strategy="afterInteractive">
      {`(function(){var e=window;var t=document;var n=function(){n.e(arguments)};n.q=[];n.e=function(e){n.q.push(e)};e.Pylon=n;var r=function(){var e=t.createElement("script");e.setAttribute("type","text/javascript");e.setAttribute("async","true");e.setAttribute("src","https://widget.usepylon.com/widget/${process.env.NEXT_PUBLIC_PYLON_APP_ID}");var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};if(t.readyState==="complete"){r()}else if(e.addEventListener){e.addEventListener("load",r,false)}})();`}
    </Script>
  );
};
