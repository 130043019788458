import { AxiosInstance } from "axios";

import { SingleUserLicenseBillingAPIEndpoints } from "./billing";
import { SingleLicenseEmailAutomationAPIEndpoints } from "./email-automation";
import { TrainingAPIEndpoints } from "./training";
import { SingleLicenseGlenXMeetingsEndpoints } from "./meetings";

export const SingleUserLicenseAPIEndpoints = (api: AxiosInstance) => ({
  billing: SingleUserLicenseBillingAPIEndpoints(api),
  emailAutomation: SingleLicenseEmailAutomationAPIEndpoints(api),
  training: TrainingAPIEndpoints(api),
  meetings: SingleLicenseGlenXMeetingsEndpoints(api),
});
