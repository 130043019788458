import { CloseIcon } from "shared/ui/icons";

import { clsxMerge } from "shared/lib/helpers";

import { Portal } from "react-portal";
import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { FC } from "react";

export const CONFIRM_ACTION_MODAL_ID = "confirm-action-modal-close-widget";

/**
 * We want to simplify and when clicking outside of the sidebar
 * execute same logic when clicking close button
 */
export const MAXED_DIALER_CLOSE_BUTTON_ID = "maxed-dialer-close-button";

export const DialerCloseButton: FC<
  PropsWithClassNameI & { onClick?: () => void }
> = ({ className, onClick }) => {
  return (
    <>
      <button
        id={MAXED_DIALER_CLOSE_BUTTON_ID}
        className={clsxMerge(
          "btn btn-square btn-sm",
          "absolute right-2 top-2",
          "z-20",
          className
        )}
        onClick={onClick}
      >
        <CloseIcon className="w-6 text-white" />
      </button>

      {/*@ts-ignore*/}
      <Portal>
        <ConfirmActionModal
          title="Disposition is required"
          description="You must finish the call and fill out the disposition before proceeding."
          id={CONFIRM_ACTION_MODAL_ID}
          cancelButtonText="Close"
          confirmButtonText={"Understood"}
        />
      </Portal>
    </>
  );
};
