import { AxiosInstance } from "axios";

import { IngestionI } from "@/interfaces/ingestion";
import { IngestionMappingI } from "@/interfaces/ingestion/request";

export interface GetCSVUploadURLRequestI {
  file_size: number;
  file_name: string;
  is_suppression?: boolean;
}

export interface GetCSVUploadURLResponseI {
  ingestion_id: string;
  upload_url: string;
}

export interface IngestionFieldI {
  field_name: string;
  field_sample_value: string;
}

export type IngestionFieldTableI = "Account" | "Contact";

export interface IngestionAIFieldMappingI {
  data_column_name: string;
  glen_column_name: string;
  table_name: IngestionFieldTableI;
}

export interface GetIngestionFieldsResponseI {
  ingestion_fields: Array<IngestionFieldI>;
  ai_mappings: Array<IngestionAIFieldMappingI>;
}

export interface SubmitIngestionFieldsRequestI {
  list_id?: string | null;
  ingestion_mappings: Array<IngestionMappingI>;
  meeting_taker_id?: string;
}

export interface GetIngestionsResponseI {
  ingestions: IngestionI[];
}

export interface GetIngestionResponseI {
  ingestion: IngestionI;
}

export interface GlenXMeetingTakerI {
  id: string;
  first_name: string;
  last_name: string;
  email?: string;
  calendar_link?: string;
}

export interface GetMeetingsTakersResponseI {
  meeting_takers: GlenXMeetingTakerI[] | null;
}

export const IngestionsAPIEndpoints = (api: AxiosInstance) => ({
  getLeadCSVUploadURL: (campaignId: string, params: GetCSVUploadURLRequestI) =>
    api.post<GetCSVUploadURLResponseI>(
      `/v1/campaign/${campaignId}/lead_csv_url`,
      {
        ...params,
      }
    ),

  getIngestionFields: (campaignId: string, ingestionId: string) =>
    api.get<GetIngestionFieldsResponseI>(
      `/v1/campaign/${campaignId}/ingestion/${ingestionId}/ingestion_fields`
    ),

  submitIngestionFields: (
    campaignId: string,
    ingestionId: string,
    params: SubmitIngestionFieldsRequestI
  ) =>
    api.post(
      `/v1/campaign/${campaignId}/ingestion/${ingestionId}/ingestion_mappings`,
      { ...params }
    ),

  getIngestions: () => api.get<GetIngestionsResponseI>("/v1/ingestions"),
  getIngestion: (ingestionId: string) =>
    api.get<GetIngestionResponseI>(`v1/ingestion/${ingestionId}`),

  getMeetingTakers: () =>
    api.get<GetMeetingsTakersResponseI>("/x/v1/meeting_takers"),
});
