import { FC } from "react";

import { AccountDetailsLayout as Layout } from "./layout";

// import { SidebarExtensionResources as Resources } from "@/modules/pipeline/account-details/shared/resources";

import { ConnectedLeadSection } from "@/components/modules/pipeline/account-details/sections/connected-lead-section";
import { AccountTopSection } from "@/components/modules/pipeline/account-details/sections/account-top-section";

import { PrimaryContent } from "./primary-content";

import { AccountDetailsControlMenu as ControlMenu } from "./control-menu";
import { AccountDetailsProvider, AccountDetailsProviderI } from "./context";

import { AccountDetailsModals as ModalsAndSidebars } from "@/components/modules/pipeline/account-details/modals";
import { AccountsContextProvider } from "@/context/accounts";
import { AddUserToListSidebarProvider } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { EmailTemplatesSidebarProvider } from "@/modules/email-templates-sidebar/context";
import { AccountDetailsNav } from "./nav";

import { useAccountDetailsCloseBehavior } from "./hooks/use-account-details-close";
import { WebsocketsProvider } from "@/context/websockets-context";
import { WEBSOCKET_CONNECTION_TYPES } from "@/lib/websockets/constants";

const CloseButtonBehavior = () => {
  useAccountDetailsCloseBehavior();

  return null;
};

export const AccountDetailsModule: FC<AccountDetailsProviderI> = (props) => (
  <AccountsContextProvider>
    <AccountDetailsProvider {...props}>
      <AddUserToListSidebarProvider>
        <EmailTemplatesSidebarProvider>
          <WebsocketsProvider
            connectionType={WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT}
          >
            <Layout>
              <Layout.SidebarExtension>
                {/* <Resources /> */}
              </Layout.SidebarExtension>

              <Layout.GridRows>
                <Layout.Top>
                  <CloseButtonBehavior />
                  <AccountTopSection />
                  <ConnectedLeadSection />
                </Layout.Top>

                <AccountDetailsNav />

                <Layout.Content>
                  <PrimaryContent />
                </Layout.Content>
              </Layout.GridRows>

              <ControlMenu />

              <ModalsAndSidebars />
            </Layout>
          </WebsocketsProvider>
        </EmailTemplatesSidebarProvider>
      </AddUserToListSidebarProvider>
    </AccountDetailsProvider>
  </AccountsContextProvider>
);

export { DISQUALIFY_DISPOSITION_LABELS } from "shared/lib/constants/dispositions";
