import { FC, useEffect } from "react";
import noop from "lodash/noop";

import { Sidebar } from "shared/ui";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import {
  MaxedDialerTourWidgetConfigI,
  MaxedDialerWidgetStateI,
} from "./interface";
import { WIDGETS, widgets } from "@/components/shared/widgets";
import { AccountDetailsModule } from "@/modules/pipeline/account-details";
import { DialerCloseButton as CloseButton } from "@/components/shared/widgets/buttons/close-button";
import {
  MOCK_ACCOUNT_DETAILS,
  MOCK_ACCOUNT_DETAILS_RESPONSE,
  MOCK_ACTIVITY_LOGS,
  MOCK_CALLER_PHONE,
  MOCK_CAMPAIGN,
  MOCK_ID,
} from "@/modules/tours/constants/mock-data";
import { useMaxedDialerTourSearchQueryParams } from "@/widgets/maxed-dialer-tour/use-search-query-params";
import { UseFetchAccountDetailsApiI } from "@/api/routes/account/queries";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { CallContactI } from "@/interfaces/dialer";
import { useListTourContext } from "@/modules/tours/list/context";

type WidgetMaxedDialerTourPropsI = MaxedDialerTourWidgetConfigI &
  MaxedDialerWidgetStateI;

const handleCloseTour = () => {
  widgets.close({ id: WIDGETS.MAXED_DIALER_TOUR });
};

const WidgetMaxedDialerTourContent: FC<WidgetMaxedDialerTourPropsI> = ({
  callStatus: callStatusRaw,
}) => {
  const { callStatus } = useMaxedDialerTourSearchQueryParams(callStatusRaw);

  const { setStartAt, setContact, setCallerPhone } = useInCallContext();

  useEffect(() => {
    if (callStatus === DIALER_CALL_STATUS.DURING) {
      setContact({
        id: `${MOCK_ID}1`,
      } as CallContactI);

      setStartAt(new Date().toString());

      setCallerPhone(MOCK_CALLER_PHONE);
    }
  }, [callStatus]);

  /**
   * For the max side of the sidebar we calculate max values for each column
   */
  return (
    <Sidebar
      className="pointer-events-none bg-none p-0"
      headerClassName="p-0 h-[0]"
      closeButtonClassName="absolute top-5 right-5"
      containerClassName="max-w-[calc(400px+354px+874px)] w-full shadow-none"
      isOverlayClickable={false}
      hideTitle
      hideBg
      disableAnimation
      handleClose={handleCloseTour}
      closeButton={CloseButton}
    >
      <AccountDetailsModule
        areErrorsMuted
        callStatus={callStatus ?? undefined}
        {...MOCK_ACCOUNT_DETAILS}
        accountDetailsApi={
          {
            isFetching: false,
            isPending: false,
            isSuccess: true,
            error: null,
            isError: false,
            isLoading: false,
            data: MOCK_ACCOUNT_DETAILS_RESPONSE,
          } as UseFetchAccountDetailsApiI
        }
        campaign={MOCK_CAMPAIGN}
        accountHistory={MOCK_ACTIVITY_LOGS}
        onAccountUpdate={noop}
      />
    </Sidebar>
  );
};

export const WidgetMaxedDialerTour: FC<WidgetMaxedDialerTourPropsI> = () => {
  const { isTourOpen } = useListTourContext();

  useEffect(() => {
    if (!isTourOpen) {
      handleCloseTour();
    }
  }, [isTourOpen]);

  if (!isTourOpen) {
    return null;
  }

  return <WidgetMaxedDialerTourContent />;
};
