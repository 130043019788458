import { useContext } from "react";

import GlobalContext, { DefaultGlobalContextI } from "@/context/global";

export const useGlobalContext = (): DefaultGlobalContextI => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }

  return context;
};

export const useCurrentCampaignId = (): string => {
  const { currentCampaign } = useGlobalContext();

  return currentCampaign?.id || "";
};

export const useIsSingleUserLicense = () => {
  const { currentCampaign } = useGlobalContext();

  if (!currentCampaign) {
    return null;
  }

  return currentCampaign.is_single_user_license;
};
