import { z } from "zod";

export const FormSchema = z.object({
  domain: z.string().min(2, {
    message: "Required",
  }),
  company_name: z.string().min(2, {
    message: "Required",
  }),
});
