import { FC, useEffect } from "react";
import { Sparkle } from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import RenderHTML from "shared/ui/render-html";
import { MarkdownPreview } from "shared/ui/markdown/preview";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { PITCH_DOM_PURIFIER_CONFIG } from "@/components/modules/pipeline/account-details/sections/talking-points-section/constants";
import { ACCOUNT_AI_META_GENERATION_EXPIRY } from "shared/lib/constants/account";
import { ACCOUNT_AI_META_TYPES } from "shared/lib/constants/account";
import { getNumberOfDaysBetweenDates } from "shared/lib/helpers/date";
import { useUpdateAccountAIMeta } from "@/api/routes/account/mutations";
import { BEGINNING_OF_TIME } from "shared/lib/constants/time";
import { GenerateWithAIButton } from "@/components/modules/pipeline/account-details/shared/generate-ai-btn";
import { useListTourContext } from "@/modules/tours/list/context";

interface AccountTalkingPointsSectionPropsI {
  className?: string;
}

const SparkleCircleIcon = ({ className }: PropsWithClassNameI) => {
  return (
    <div
      className={clsxMerge(
        "h-fit rounded-full border border-[#D0D0D0] bg-[#E6E6E6]",
        className
      )}
    >
      <Sparkle className="size-6 p-1 text-white" fill="currentColor" />
    </div>
  );
};

export const AccountTalkingPointsSection: FC<
  AccountTalkingPointsSectionPropsI
> = ({ className }) => {
  const {
    campaign,
    account,
    accountDetailsApi,
    accountPitch,
    setAccountPitch,
  } = useAccountDetailsContext();

  const { isTourOpen } = useListTourContext();

  const {
    content: pitchContent,
    html_links: pitchLinks,
    last_updated_at,
  } = accountPitch || {};

  const { mutateAsync: updateAccountAIMeta, isPending: isGeneratingAIMeta } =
    useUpdateAccountAIMeta(campaign?.id as string, account?.id as string);

  const isGeneratedRecently =
    accountPitch &&
    getNumberOfDaysBetweenDates(
      new Date().toISOString(),
      last_updated_at as string
    ) < ACCOUNT_AI_META_GENERATION_EXPIRY;

  const handleGenerateAIMeta = async () => {
    if (!campaign?.id || !account?.id) {
      return;
    }

    try {
      // update `last_updated_at` right away incase user navigates away, so we know the AI generation was previosly triggered (and user doesnt trigger it again)
      setAccountPitch?.({
        last_updated_at: new Date().toISOString(),
      });

      if (isTourOpen) {
        return;
      }

      const resp = await updateAccountAIMeta(
        ACCOUNT_AI_META_TYPES.TARGETED_PITCH
      );

      if (!resp.body) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      const reader = resp.body?.getReader();
      const decoder = new TextDecoder();

      let done = false;
      let accumulatedResponse = "";

      while (!done) {
        const readResult = await reader?.read();
        const value = readResult?.value;
        const readerDone = readResult?.done;

        done = readerDone ?? true;

        if (value) {
          const chunk = decoder.decode(value);
          accumulatedResponse += chunk;
        }
      }

      const parsedResponse = JSON.parse(accumulatedResponse);
      const text = parsedResponse.text;
      const googleHtml = parsedResponse.google_html;

      setAccountPitch?.({
        content: text,
        html_links: googleHtml,
        last_updated_at: new Date().toISOString(),
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  // auto-trigger AI generation if never generated before
  useEffect(() => {
    if (
      (!last_updated_at || last_updated_at === BEGINNING_OF_TIME) &&
      !isGeneratingAIMeta
    ) {
      handleGenerateAIMeta();
    }
  }, [last_updated_at]);

  return (
    <div className={clsxMerge(className)}>
      <AccountDetailsSectionContainer
        title="Talking points"
        actions={
          <GenerateWithAIButton
            onClick={handleGenerateAIMeta}
            disabled={isGeneratedRecently || isGeneratingAIMeta}
          />
        }
      >
        <DataStatesWrapper
          viewName="pitch"
          api={accountDetailsApi}
          loading={<CenteredSpinner className="h-[140px]" />}
        >
          {isGeneratingAIMeta && (
            <div className="flex items-center gap-2">
              <SparkleCircleIcon />
              <div className="animate-pulse text-[#33345D] typography-body-4">
                Generating...
              </div>
            </div>
          )}

          {!isGeneratingAIMeta &&
            (pitchContent ? (
              <div>
                <div className="flex gap-2 rounded-lg bg-[#F8F9FA] p-2 pb-4">
                  <SparkleCircleIcon />
                  <MarkdownPreview className="typography-body-4">
                    {pitchContent}
                  </MarkdownPreview>
                </div>

                <RenderHTML
                  html={pitchLinks || ""}
                  className="google-search-suggestion mt-2"
                  domPurifierConfig={PITCH_DOM_PURIFIER_CONFIG}
                />
              </div>
            ) : (
              <div className="italic text-black/30 typography-body-4">
                No data available
              </div>
            ))}
        </DataStatesWrapper>
      </AccountDetailsSectionContainer>
    </div>
  );
};
