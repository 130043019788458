import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useApiClient } from "@/context/api-client";
import { UpdateCampaignCustomScriptRequestParamsI } from "@/api/glencoco";
import { AccountDetailsQueryKeys } from "@/components/modules/pipeline/account-details/queries/keys";

interface UseUpdateCampaignScriptParamsI {
  campaignId: string;
  params: UpdateCampaignCustomScriptRequestParamsI;
}

export const useUpdateCampaignScript = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ campaignId, params }: UseUpdateCampaignScriptParamsI) =>
      api.updateCampaignCustomScript(campaignId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AccountDetailsQueryKeys.script,
      });
    },
  });
};

export const useResetCampaignScript = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (campaignId: string) =>
      api.resetCampaignCustomScript(campaignId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AccountDetailsQueryKeys.script,
      });
    },
  });
};
