import { Button } from "shared/ui/shadcn/button";
import { EMAIL_SECTION_EXIT_OPTIONS } from "@/modules/pipeline/account-details/sections/email-section/constants";

import { EmailSectionExitTypeI } from "@/modules/pipeline/account-details/sections/email-section/interface";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

export const emailSectionActions = ({
  onSelect,
  isLoading,
  className,
}: {
  onSelect?: (exitOption: EmailSectionExitTypeI) => void;

  isLoading?: boolean;
} & PropsWithClassNameI) => {
  const handleOnClick =
    (submitForm: () => void) => (exitOption: EmailSectionExitTypeI) => () => {
      onSelect?.(exitOption);

      submitForm();
    };

  return function EmailSectionActionsContent({
    submitForm,
  }: {
    submitForm: () => void;
  }) {
    const { setCall } = useCallingContext();
    const { clear: clearInCallContext } = useInCallContext();
    const { isEnabled } = useDialerGlobalContext();
    const { setIsEmailSection } = useAccountDetailsContext();

    const handlerOnCancel = () => {
      setCall(undefined);
      clearInCallContext();
      setIsEmailSection?.(false);
    };

    return (
      <div
        className={clsxMerge("flex items-center justify-between", className)}
      >
        {isEnabled && (
          <>
            <Button
              type="button"
              variant="link"
              disabled={isLoading}
              className="text-[#0F35FF]"
              onClick={handlerOnCancel}
            >
              Skip
            </Button>

            <div className="flex items-center gap-x-2">
              <Button
                type="submit"
                variant="outline"
                disabled={isLoading}
                onClick={handleOnClick(submitForm)(
                  EMAIL_SECTION_EXIT_OPTIONS.STAY
                )}
              >
                Send & stay in account
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                onClick={handleOnClick(submitForm)(
                  EMAIL_SECTION_EXIT_OPTIONS.CONTINUE_DIALING
                )}
              >
                Send email & resume dialing
              </Button>
            </div>
          </>
        )}

        {!isEnabled && (
          <>
            <Button
              type="button"
              variant="link"
              disabled={isLoading}
              className="text-[#0F35FF]"
              onClick={handlerOnCancel}
            >
              Skip
            </Button>
            <Button
              type="button"
              disabled={isLoading}
              onClick={handleOnClick(submitForm)(
                EMAIL_SECTION_EXIT_OPTIONS.STAY
              )}
            >
              Send email
            </Button>
          </>
        )}
      </div>
    );
  };
};
