import { FC, MouseEvent, PropsWithChildren } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { MAXED_DIALER_CLOSE_BUTTON_ID } from "@/modules/pipeline/account-details/buttons/close-button";

const ACCOUNT_DETAILS_LAYOUT_ID = "account-details-layout";

/**
 * NOTE
 * To adjust the min-max size of columns
 * here you update grid-cols-[fit-content(400px)_minmax(260px,354px)_minmax(312px,874px)]
 *
 * To adjust sidebar wrapper
 * @/components/shared/widgets/maxed-dialer/index.tsx
 * update max-w-[calc(400px+354px+874px)]
 *
 * To adjust smooth animations for resources
 * @/components/modules/pipeline/account-details/sidebar/components/resources/index.tsx
 * update max-w-[400px]
 *
 */
const LayoutRoot = ({ children }: { children?: any }) => {
  const handlerContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as Element)?.id === ACCOUNT_DETAILS_LAYOUT_ID) {
      event.preventDefault();

      /**
       * Looks bad
       * Very fast
       * Very efficient
       * https://www.dafont.com/forum/attach/orig/4/1/417453.jpg
       */
      (
        window.document.querySelector(
          `#${MAXED_DIALER_CLOSE_BUTTON_ID}`
        ) as HTMLElement
      )?.click();
    }
  };

  return (
    <div
      id={ACCOUNT_DETAILS_LAYOUT_ID}
      onClick={handlerContainerClick}
      className={clsxMerge(
        "h-full",
        "grid grid-cols-[fit-content(400px)_minmax(572px,1228px)] place-content-end",
        "[transition:grid-template_300ms]",
        "rounded-r-none"
      )}
    >
      {children}
    </div>
  );
};
const LayoutSidebarExtension = ({ children }: { children?: any }) => (
  <div className="sticky top-0 h-screen overflow-y-auto">{children}</div>
);

const LayoutGridRows = ({ children }: { children?: any }) => (
  <div className="h-auto overflow-hidden rounded-l-md rounded-r-none">
    {children}
  </div>
);

const LayoutGridColumns: FC<
  {
    columns?: number;
  } & PropsWithChildren &
    PropsWithClassNameI
> = ({ children, columns = 1, className }) => (
  <>
    {columns === 1 && <div className={className}>{children}</div>}

    {columns === 2 && (
      <div
        className={clsxMerge(
          "grid grid-cols-[minmax(260px,671px)_minmax(312px,557px)] ",
          "[transition:grid-template_300ms]"
        )}
      >
        {children}
      </div>
    )}

    {columns === 3 && (
      <div
        className={clsxMerge(
          "grid grid-cols-[30%_40%_30%]",
          "[transition:grid-template_300ms]"
        )}
      >
        {children}
      </div>
    )}
  </>
);

const LayoutGridColumn: FC<PropsWithChildren & PropsWithClassNameI> = ({
  children,
  className,
}) => {
  // const { callStatus } = useAccountDetailsContext();

  const isTopVisible = true;

  return (
    <div
      className={clsxMerge(
        "sticky top-0 overflow-y-auto pb-24",
        {
          "h-[calc(100vh-179px)]": isTopVisible,
          "h-screen": !isTopVisible,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const LayoutTop = ({ children }: { children?: any }) => {
  return <div className={clsxMerge("z-10")}>{children}</div>;
};

const LayoutPrimaryContent = ({ children }: { children?: any }) => {
  // const callStatus = useDialerCallStatus();

  const isTopVisible = true;

  return (
    <div
      className={clsxMerge("sticky top-0 overflow-y-auto bg-[#F2F2F2]", {
        "h-[calc(100vh-179px)]": isTopVisible,
        "h-screen": !isTopVisible,
      })}
    >
      {children}
    </div>
  );
};

export const AccountDetailsLayout = LayoutRoot as typeof LayoutRoot & {
  SidebarExtension: typeof LayoutSidebarExtension;
  GridRows: typeof LayoutGridRows;
  GridColumns: typeof LayoutGridColumns;
  GridColumn: typeof LayoutGridColumn;
  Top: typeof LayoutTop;
  Content: typeof LayoutPrimaryContent;
};

AccountDetailsLayout.SidebarExtension = LayoutSidebarExtension;
AccountDetailsLayout.GridRows = LayoutGridRows;
AccountDetailsLayout.GridColumns = LayoutGridColumns;
AccountDetailsLayout.Top = LayoutTop;
AccountDetailsLayout.GridColumn = LayoutGridColumn;
AccountDetailsLayout.Content = LayoutPrimaryContent;
