// React & Next.js
import { useMemo } from "react";
import { useRouter } from "next/router";

// API & Data
import { useRemoveListFromAccount } from "@/api/routes/account/mutations";
import { AccountDetailsListItemPropsI } from "./interface";

// Constants
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { LIST_TYPES } from "shared/lib/constants/lists";
import { CONFIRM_REMOVE_LIST_FROM_ACCOUNT_MODAL_ID } from "./constants";

// Contexts & Hooks
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { useLazyModal } from "shared/lib/hooks";

// Utils & Helpers
import Router from "@/helpers/router";
import { getListName } from "@/modules/pipeline/utils";
import { WIDGETS, widgets } from "@/widgets/index";

export const useListItem = ({
  listData,
  campaignId,
  accountId,
}: AccountDetailsListItemPropsI) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const { callStatus } = useAccountDetailsContext();

  const router = useRouter();
  const { onAccountUpdate } = useAccountDetailsContext();

  const { mutateAsync: removeListFromAccount } = useRemoveListFromAccount();

  const { isOpen, onOpen, onClose } = useLazyModal(
    CONFIRM_REMOVE_LIST_FROM_ACCOUNT_MODAL_ID
  );

  const isExclusive = listData?.list_type === LIST_TYPES.EXCLUSIVE;

  const listName = useMemo(() => getListName(listData), [listData]);

  /**
   * NOTE
   * User is not allowed to close widget before submits disposition
   * OR
   * While dialing list session is active
   */
  const isAllowedToOpen = useMemo(
    () => !isGlobalDialer && callStatus === DIALER_CALL_STATUS.BEFORE,
    [isGlobalDialer, callStatus]
  );

  const handleOpenList = () => {
    if (isAllowedToOpen) {
      router.push(Router.lists(listData.list_id));
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    }
  };

  const handleRemove = async () => {
    if (!campaignId || !accountId) {
      return;
    }

    await removeListFromAccount(
      {
        campaignId,
        accountId,
        listId: listData.list_id as string,
      },
      {
        onSuccess: () => {
          onClose();
          onAccountUpdate();
        },
      }
    );
  };

  const handleConfirmRemoval = () => {
    handleRemove();
  };

  return {
    isAllowedToOpen,
    isExclusive,
    listName,
    isOpen,
    onOpen,
    onClose,
    handleOpenList,
    handleConfirmRemoval,
  };
};
