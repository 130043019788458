import { FC, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { TextField } from "shared/ui/user-input";
import {
  clsxMerge,
  getFullEntityName,
  getInitialsFromUsername,
  getObjectIf,
} from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { isPromiseFulfilled } from "shared/lib/helpers/utils";

import {
  useGlobalContext,
  useIsSingleUserLicense,
} from "@/hooks/use-global-context";
import { closeProfileSettings } from "@/lib/store/profile-settings";
import { useApiClient } from "@/context/api-client";
import { UPDATE_PROFILE_MESSAGES } from "@/modules/settings/profile/form/constants";
import { useUploadProfileImage } from "@/components/shared/profile-image-selector/hooks";
import { ProfileImageSelector } from "@/components/shared/profile-image-selector";

interface ProfileDataI {
  email: string;
  first_name: string;
  last_name: string;
  company_name?: string;
  company_website?: string;
}

const Input = TextField(Field);

const BaseSchema = {
  first_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(24, "Maximum 24 characters")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(24, "Maximum 24 characters")
    .required("Last name is required"),
};

const ProfileDataSchema = Yup.object().shape(BaseSchema);

const SingleLicenseProfileFormSchema = Yup.object().shape({
  ...BaseSchema,
  company_name: Yup.string(),
  company_website: Yup.string()
    .required()
    .url("Provide a valid URL starting with http:// or https://"),
});

export const ProfileSettingsForm: FC<PropsWithClassNameI> = ({ className }) => {
  const isSingleUserLicense = useIsSingleUserLicense();
  const { currentCampaign } = useGlobalContext();

  const [selectedAvatarFile, setSelectedAvatarFile] = useState<
    File | undefined
  >();

  const { uploadProfileImage, isUploadingProfileImage } =
    useUploadProfileImage();

  const { glencocoUser, reloadUser } = useGlobalContext();
  const apiClient = useApiClient();

  const formValues = useMemo(() => {
    const baseFormValues: ProfileDataI = {
      email: glencocoUser?.email || "Your email is not available",
      first_name: glencocoUser?.first_name || "",
      last_name: glencocoUser?.last_name || "",
    };

    if (isSingleUserLicense) {
      return {
        ...baseFormValues,
        company_name: currentCampaign?.name || "",
        company_website: currentCampaign?.website_url || "",
      };
    }

    return baseFormValues;
  }, [
    isSingleUserLicense,
    currentCampaign,
    glencocoUser?.first_name,
    glencocoUser?.last_name,
  ]);

  const handleSaveProfile = async ({
    first_name,
    last_name,
    company_name,
    company_website,
  }: ProfileDataI) => {
    const updateProfilePromise = apiClient.user
      .patchUser({
        first_name,
        last_name,
        ...getObjectIf(
          {
            company_name,
            company_website,
          },
          isSingleUserLicense
        ),
      })
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject();
        }

        return Promise.resolve();
      });

    const updateAvatarPromise = uploadProfileImage(selectedAvatarFile);

    const [savedProfileResult, savedAvatarResult] = await Promise.allSettled([
      updateProfilePromise,
      updateAvatarPromise,
    ]);

    const isProfileUpdated = isPromiseFulfilled(savedProfileResult);
    const isAvatarUpdated = isPromiseFulfilled(savedAvatarResult);

    reloadUser?.();

    if (isProfileUpdated && isAvatarUpdated) {
      toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.BOTH);
      closeProfileSettings();
    } else {
      if (isProfileUpdated) {
        toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.DATA);
      } else {
        toast.error(UPDATE_PROFILE_MESSAGES.ERROR.DATA);
      }

      if (isAvatarUpdated) {
        toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.PICTURE);
      } else {
        toast.error(UPDATE_PROFILE_MESSAGES.ERROR.PICTURE);
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={
        isSingleUserLicense ? SingleLicenseProfileFormSchema : ProfileDataSchema
      }
      initialValues={formValues}
      onSubmit={handleSaveProfile}
    >
      {({ touched, errors, isValid, isSubmitting }) => (
        <Form
          className={clsxMerge(
            "flex h-full w-full flex-col justify-between",
            className
          )}
        >
          <div className="flex flex-col gap-2">
            <Input
              disabled
              name="email"
              label="Email"
              labelContentClassName="typography-body-4-medium"
              placeholder="You cannot change your email"
              errors={errors.first_name}
              touched={touched.first_name}
            />

            {isSingleUserLicense && (
              <Input
                disabled
                name="company_name"
                label="Company"
                labelContentClassName="typography-body-4-medium"
                placeholder="You cannot change your company name"
                errors={errors.company_name}
                touched={touched.company_name}
              />
            )}

            <Input
              name="first_name"
              label="First name"
              labelContentClassName="typography-body-4-medium"
              placeholder="Enter your first name"
              disabled={isSubmitting}
              errors={errors.first_name}
              touched={touched.first_name}
            />

            <Input
              name="last_name"
              label="Last name"
              labelContentClassName="typography-body-4-medium"
              placeholder="Enter your last name"
              disabled={isSubmitting}
              errors={errors.last_name}
              touched={touched.last_name}
            />

            {isSingleUserLicense && (
              <Input
                name="company_website"
                label="Company website"
                labelContentClassName="typography-body-4-medium"
                placeholder="Enter your company website"
                disabled={isSubmitting}
                errors={errors.company_website}
                touched={touched.company_website}
              />
            )}

            <ProfileImageSelector
              fallbackText={getInitialsFromUsername(
                getFullEntityName(glencocoUser)
              )}
              savedPictureURL={glencocoUser?.profile_image_url}
              isUploadingProfilePicture={
                isUploadingProfileImage || isSubmitting
              }
              onChangeProfilePicture={setSelectedAvatarFile}
            />
          </div>

          <div className="mt-8 flex justify-end gap-1 pb-8">
            <button
              type="button"
              className={clsxMerge("btn-ae-text", isSubmitting && "disabled")}
              onClick={closeProfileSettings}
            >
              Cancel
            </button>

            <button
              className={clsxMerge(
                "btn-ae-default w-[150px]",
                (!isValid || isSubmitting) && "disabled",
                isSubmitting && "loading"
              )}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
