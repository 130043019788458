import { FC, useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { clsxMerge } from "shared/lib/helpers";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { Button } from "shared/ui/shadcn/button";
import { Separator } from "shared/ui/shadcn/separator";
import { Form, FormField } from "shared/ui/shadcn/form";
import { useEmailAutomationContext } from "shared/ui/email/automation/context";
import TextField from "shared/ui/user-input-shadcn/text-field";
import SelectField from "shared/ui/user-input-shadcn/select-field";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";

import {
  useCurrentCampaignId,
  useGlobalContext,
} from "@/hooks/use-global-context";
import { EmailAutomationProvider } from "@/modals/email-setup-modal/context/provider";
import { FormSchema } from "@/modals/email-setup-modal/form-schema";
import { EMAIL_SETUP_STEPS } from "@/modals/email-setup-modal/constants";

export const EMAIL_SETUP_MODAL_ID = "email-setup-modal";

interface EmailSetupModalPropsI {}

const EmailSetupModalContent: FC<
  EmailSetupModalPropsI & ModalChildrenFnPropsI
> = ({ handleClose }) => {
  const { glencocoUser: user, reloadUser } = useGlobalContext();
  const {
    enableSuggestedDomainsApi,
    suggestedDomainsApi,
    purchaseDomain,
    updateConfig,
  } = useEmailAutomationContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!suggestedDomainsApi.isSuccess) {
      enableSuggestedDomainsApi();
    }
  }, []);

  const domainSelectOptions = useMemo(() => {
    return (
      suggestedDomainsApi.data?.map((domain) => ({
        label: domain.domain ?? "",
        value: domain.domain ?? "",
      })) ?? []
    );
  }, [suggestedDomainsApi.data]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      domain: "",
      company_name: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmitting(true);

    await updateConfig({ email_friendly_name: data.company_name });
    await purchaseDomain({ domain_name: data.domain });

    reloadUser?.();
    handleClose();
    setIsSubmitting(false);
  };

  return (
    <div>
      <div className="typography-header-5-semibold">
        Unlock the power of your AI powered{" "}
        <span className="text-[#CC3285]">email assitant</span>
      </div>

      <Separator className="my-5 h-0.5 bg-[#E5E7FF]" />

      <section className="grid grid-cols-3 justify-evenly">
        {EMAIL_SETUP_STEPS.map(({ title, description }, index) => (
          <div key={index} className={clsxMerge("flex w-fit gap-2")}>
            <div className="typography-header-8-semibold">{index + 1}</div>

            <div className="w-[150px]">
              <h5 className="typography-header-8-semibold">{title}</h5>
              <p className="text-[#333] typography-body-4">{description}</p>
            </div>
          </div>
        ))}
      </section>

      <Separator className="my-5 h-0.5 bg-[#E5E7FF]" />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <section>
            <div className="mb-4 typography-header-8-semibold">
              Choose your look-a-like domain
            </div>

            <div className="relative flex">
              <div className="mr-2.5 mt-0.5 typography-header-6">
                {user?.first_name}@
              </div>

              <div className="w-full">
                <FormField
                  name="domain"
                  control={form.control}
                  render={({ field }) => (
                    <SelectField
                      placeholder="Select your domain"
                      options={domainSelectOptions}
                      onChange={field.onChange}
                      disabled={suggestedDomainsApi.isFetching}
                    />
                  )}
                />
                <div className="mt-2 text-[#333] typography-body-5">
                  This email address will be used to send & receive emails from
                  your GlenX account
                </div>
              </div>

              {suggestedDomainsApi.isFetching && (
                <LoadingSpinner className="absolute right-4 top-2 w-4 bg-white" />
              )}
            </div>
          </section>

          <Separator className="my-5 h-0.5 bg-[#E5E7FF]" />

          <section>
            <div className="mb-3 typography-header-8-semibold">
              Add how you want your company referred to in emails
            </div>

            <FormField
              name="company_name"
              control={form.control}
              render={({ field }) => (
                <TextField
                  placeholder="Ex. Apple"
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <div className="mt-2 text-[#333] typography-body-5">
              For example, instead of "National Basketball Association", you may
              want prospects to know your company by "NBA".
            </div>
          </section>

          <div className="mt-6 flex gap-2">
            <Button
              type="button"
              variant="outline"
              size="lg"
              className="flex-1"
              onClick={handleClose}
            >
              Skip for now
            </Button>

            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              variant="default"
              size="lg"
              className="flex-1"
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export const EmailSetupModal: FC<EmailSetupModalPropsI> = (props) => {
  const currentCampaignId = useCurrentCampaignId();

  return (
    <Modal
      id={EMAIL_SETUP_MODAL_ID}
      modalBoxClassName="rounded-md px-7 py-9 w-[620px]"
    >
      {(modalProps) => {
        return (
          <EmailAutomationProvider campaignId={currentCampaignId}>
            <EmailSetupModalContent {...props} {...modalProps} />
          </EmailAutomationProvider>
        );
      }}
    </Modal>
  );
};
