import axios, { AxiosInstance } from "axios";

import {
  DraftTrainingModulesListI,
  GetTrainingModuleAssetUploadURLResponseI,
  GetTrainingModulesResponseI,
  GetTrainingModuleUploadURLResponseI,
  GetTrainingResponseI,
} from "shared/lib/interfaces/training";

export const TrainingAPIEndpoints = (api: AxiosInstance) => ({
  getTrainingModules: (campaignId: string) =>
    api.get<GetTrainingModulesResponseI>(
      `/x/v1/campaign/${campaignId}/training_modules`
    ),

  /**
   * If training_modules includes a list item without an id, it is assumed as
   * a CREATE operation (user created a new course module).
   *
   * If training_modules is missing a list item with an id that was there before,
   * it is assumed as a DELETE operation (user deleted an existing course module).
   */
  submitTrainingModules: (
    campaignId: string,
    params: {
      training_modules: DraftTrainingModulesListI;
    }
  ) => api.post(`/x/v1/campaign/${campaignId}/training_modules`, params),

  publishTraining: (campaignId: string) =>
    api.post(`/x/v1/campaign/${campaignId}/training_modules/publish`, {}),

  getTrainingModule: (campaignId: string, trainingModuleId: string) =>
    api.get<GetTrainingResponseI>(
      `/x/v1/campaign/${campaignId}/training_module/${trainingModuleId}`
    ),

  updateTrainingModuleTitle: (
    campaignId: string,
    trainingModuleId: string,
    title: string
  ) =>
    api.patch(
      `/x/v1/campaign/${campaignId}/training_module/${trainingModuleId}`,
      {
        title,
      }
    ),

  getTrainingModuleUploadURL: (campaignId: string, trainingModuleId: string) =>
    api.get<GetTrainingModuleUploadURLResponseI>(
      `/x/v1/campaign/${campaignId}/training_module/${trainingModuleId}/presigned_upload_url`
    ),

  getAITrainingModuleUploadURL: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post<GetTrainingModuleUploadURLResponseI>(
      `/x/v1/campaign/${campaignId}/training_module/${trainingModuleId}/submit_new_block_notes_url`
    ),

  uploadTrainingModuleContent: (preSignedUploadURL: string, document: Blob) =>
    axios.put(preSignedUploadURL, document, {
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getTrainingModuleAssetUploadURL: (
    campaignId: string,
    trainingModuleId: string,
    data: {
      file_type: string;
      file_name: string;
      content_size: number;
    }
  ) =>
    api.post<GetTrainingModuleAssetUploadURLResponseI>(
      `/x/v1/campaign/${campaignId}/training_module/${trainingModuleId}/presigned_upload_assets_url`,
      data
    ),

  // Use it for any file upload
  uploadTrainingModuleAsset: (preSignedUploadURL: string, asset: File) =>
    axios.put(preSignedUploadURL, asset, {
      headers: {
        "x-amz-acl": "public-read",
        "Content-Type": asset.type,
      },
    }),
});
