import { useEffect, useState } from "react";
import Script from "next/script";

import { checkIfClient } from "shared/lib/helpers";
import { checkIfRemoteEnvironment } from "shared/lib/helpers/environment";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

import { useGlobalContext } from "@/hooks/use-global-context";
import { UserI } from "@/interfaces/user";

const INTERCOM_API_BASE = "https://api-iam.intercom.io";

const isIntercomAllowed = (user?: UserI) => !!user?.registered;

export const IntercomInitLibrary = () => {
  if (!checkIfRemoteEnvironment(process.env.NEXT_PUBLIC_ENV)) {
    return null;
  }

  return (
    <Script id="intercome-script" strategy="afterInteractive" async={true}>
      {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `}
    </Script>
  );
};

export const IntercomLaunchMessenger = (
  intercomData?: any,
  isBoot?: boolean
) => {
  if (checkIfClient() && process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
    const attrs = intercomData || {};

    (window as any).intercomSettings = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      api_base: INTERCOM_API_BASE,
      ...attrs,
    };

    if (isBoot) {
      (window as any).Intercom?.("boot", {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        api_base: INTERCOM_API_BASE,
        ...attrs,
      });
    }
  }
};

export const IntercomComponent = () => {
  const { intercom, isUserLoaded, glencocoUser: user } = useGlobalContext();
  const [isConfigSet, setIsConfigSet] = useState(false);

  useEffect(() => {
    if (isUserLoaded && intercom && isIntercomAllowed(user as UserI)) {
      IntercomLaunchMessenger(intercom);

      setIsConfigSet(true);
    }
  }, [intercom, isUserLoaded, user]);

  useEffect(() => {
    if (isConfigSet) {
      setTimeout(() => {
        IntercomLaunchMessenger(intercom, true);
      }, TIME_INTERVALS_MS.ONE_SECOND);
    }
  }, [isConfigSet]);

  return <>{isConfigSet && IntercomInitLibrary()}</>;
};
