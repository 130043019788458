// Context
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useCallCopilot } from "@/modules/pipeline/account-details/hooks/use-call-copilot";

// Queries
import {
  useFetchCampaignDefaultScript,
  useFetchCampaignScript,
} from "@/modules/pipeline/account-details/queries";

// Constants
import { ACCOUNTS_DETAILS_TABS } from "@/modules/pipeline/account-details/constants";

// UI Components
import { AccountDetailsScript } from "shared/ui/account-details/scripts/script";
import { MarkdownPreview } from "shared/ui/markdown/preview";

// Utilities
import { clsxMerge } from "shared/lib/helpers";
import { transformNewLines } from "shared/lib/helpers/markdown";

export const ScriptsSection = ({ className }: { className?: string }) => {
  const { campaign, setActiveTab } = useAccountDetailsContext();
  const { data: userScript } = useFetchCampaignScript(campaign?.id);
  const { data: defaultScript } = useFetchCampaignDefaultScript(campaign?.id);

  const { content: callCopilotContent, isCallCopilotSection } =
    useCallCopilot();

  const scriptText = transformNewLines(
    userScript?.script_text || defaultScript || ""
  );

  return (
    <div className={className}>
      <AccountDetailsScript
        onEdit={() => setActiveTab(ACCOUNTS_DETAILS_TABS.SCRIPTS)}
      >
        <div
          className={clsxMerge("overflow-y-auto", {
            //height of an extended call copilot section with the header
            "max-h-[calc(100vh-520px)]":
              isCallCopilotSection && callCopilotContent?.length,

            //height of the minimized call copilot section with the header
            "max-h-[calc(100vh-308px)]":
              isCallCopilotSection && !callCopilotContent?.length,

            //height of the scripts section when call copilot is not active
            "max-h-[calc(100vh-258px)]": !isCallCopilotSection,
          })}
        >
          <MarkdownPreview className="ae-typography pb-[140px]">
            {scriptText}
          </MarkdownPreview>
        </div>
      </AccountDetailsScript>
    </div>
  );
};
