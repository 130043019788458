import { useGlobalContext } from "@/hooks/use-global-context";
import { useMemo } from "react";
import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { Avatar } from "shared/ui";
import { Field } from "formik";
import { TextArea } from "shared/ui/ae-user-input";

const EnhancedTextArea = TextArea(Field);

export const DISPOSITION_SECTION_NOTES_TEXTAREA_ID =
  "disposition-section-notes-textarea";

export const DispositionNoteRow = ({
  name,
  errors,
  touched,
  className,
}: {
  name: string;
  errors?: string;
  touched?: boolean;
  className?: string;
}) => {
  const { glencocoUser: user } = useGlobalContext();

  const fullUserName = useMemo(() => getFullEntityName(user), [user]);

  return (
    <div className={clsxMerge("w-full", className)}>
      <hr className="divider-horizontal mx-0 mb-4 w-[90px]" />
      <div className="flex w-full items-center gap-3">
        <Avatar
          className="h-8 w-8"
          placeholderText={fullUserName}
          src={user?.profile_image_url}
        />
        <span className="brand-typography-h7">{fullUserName}</span>
      </div>

      <div className="mt-5 w-full">
        <EnhancedTextArea
          id="disposition-section-notes-textarea"
          name={name}
          errors={errors}
          touched={touched}
          inputProps={{
            rows: 0,
          }}
          inputClassName={clsxMerge(
            "max-h-[200px] min-h-[50px] w-full bg-white p-0",
            "border-none outline-none rounded-none"
          )}
          placeholder="Add a note for this account"
        />
      </div>
    </div>
  );
};
