import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noop from "lodash/noop";

import {
  FIND_MORE_LEADS_BUTTON_ID,
  FIND_MORE_LEADS_MODAL_CONTENT_ID,
} from "@/constants/element-ids";
import { LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID } from "@/constants/element-ids";
import { LISTS_START_CALLING_BUTTON_ID } from "@/constants/element-ids";
import { useLocalStorage } from "@/context/local-storage";
import { useListTourContext } from "@/modules/tours/list/context";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";

interface ListCallingTourContextI {
  visibleSteps: string[];
  completedSteps: string[];
  saveNewCompletedStep: (stepId: string) => void;
}

const ListCallingTourContext = createContext<ListCallingTourContextI>({
  visibleSteps: [],
  completedSteps: [],
  saveNewCompletedStep: noop,
});

export const useListCallingTourContext = () => {
  const context = useContext(ListCallingTourContext);

  if (!context) {
    throw new Error(
      "useListCallingTourContext must be used within a ListCallingTourProvider"
    );
  }

  return context;
};

export const ListCallingTourProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const { isSingleUserLicense } = useOnboardingStatus();
  const {
    isTourCompleted: isBasicListTourCompleted,
    isTourOpen: isBasicListTourOpen,
  } = useListTourContext();
  const localStorage = useLocalStorage();
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);

  useEffect(() => {
    if (isBasicListTourCompleted) {
      setTimeout(() => {
        setIsReady(true);
      }, 2000);
    }
  }, [isBasicListTourCompleted]);

  const saveNewCompletedStep = (stepId: string) => {
    if (!completedSteps.includes(stepId)) {
      setCompletedSteps((prev) => [...prev, stepId]);
    }
  };

  // returns list of IDs for tooltips that should be visible
  const visibleSteps = useMemo(() => {
    if (!isReady || !isSingleUserLicense) {
      return [];
    }

    const stepVisibilities: Record<string, boolean> = {
      [LISTS_START_CALLING_BUTTON_ID]:
        !completedSteps?.includes(LISTS_START_CALLING_BUTTON_ID) &&
        !isBasicListTourOpen,
      [LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID]:
        !completedSteps?.includes(
          LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID
        ),
      [FIND_MORE_LEADS_BUTTON_ID]: !completedSteps?.includes(
        FIND_MORE_LEADS_BUTTON_ID
      ),
      [FIND_MORE_LEADS_MODAL_CONTENT_ID]:
        !completedSteps?.includes(FIND_MORE_LEADS_MODAL_CONTENT_ID) &&
        completedSteps.includes(FIND_MORE_LEADS_BUTTON_ID),
    };

    return Object.entries(stepVisibilities)
      .filter(([, visibility]) => visibility == true)
      .map(([referenceId]) => referenceId);
  }, [completedSteps, isReady, isSingleUserLicense, isBasicListTourOpen]);

  useEffect(() => {
    const seenListCallingFeatureTooltips =
      localStorage.seenListCallingFeatureTooltips;

    if (seenListCallingFeatureTooltips) {
      setCompletedSteps(seenListCallingFeatureTooltips);
    }
  }, []);

  useEffect(() => {
    localStorage.seenListCallingFeatureTooltips = completedSteps;
  }, [completedSteps]);

  return (
    <ListCallingTourContext.Provider
      value={{
        visibleSteps,
        completedSteps,
        saveNewCompletedStep,
      }}
    >
      {children}
    </ListCallingTourContext.Provider>
  );
};
