// React and third-party imports
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
} from "react";
import noop from "lodash/noop";
import { useQueryState } from "nuqs";

// API imports
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { UseFetchAccountDetailsApiI } from "@/api/routes/account/queries";

// Constants
import { ACCOUNTS_DETAILS_TABS } from "./constants";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import {
  LEFT_SIDE_OPTIONS,
  RIGHT_SIDE_OPTIONS,
} from "./nav/view-options/constants";

// Hooks
import { useSearchQueryParams } from "@/hooks/use-search-query-params";
import { useInfinitePagination } from "shared/lib/hooks/index";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { UseInfinitePaginationReturnI } from "shared/lib/hooks/infinite-scrolling/use-infinite-pagination";

// Interfaces
import {
  AccountExecutiveI,
  AccountHistoryItemI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { CampaignI } from "@/interfaces/campaign";
import { DialerCallStatusI } from "@/interfaces/dialer/call-status";
import { AccountNoteI } from "@/interfaces/account-details";
import {
  AccountDetailsStatusI,
  AccountDetailsSummaryI,
  AccountI,
  AccountOneLinerI,
  AccountPitchI,
  AccountUserListI,
  CustomerAccountDispositionI,
} from "shared/lib/interfaces/account";
import { AccountDetailsViewOptionI } from "./nav/view-options/interface";
import { DispositionAdditionalDataI } from "./sections/disposition-section/interface";
import { WSCallCopilotMessageReceivedDataI } from "@/interfaces/events";

// Utils
import { getLeadStateFlags } from "@/modules/pipeline/account-details/utils";
import { syncOptions } from "./nav/view-options/utils";
import { LocalStorage } from "@/helpers/local-storage";
import { SessionStorage } from "@/helpers/session-storage";

export interface AccountDetailsContextI {
  activeTab?: string;
  setActiveTab: (tab: string | null) => void;

  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  contactsForContextMenu: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI;
  customerAccountDisposition?: CustomerAccountDispositionI;

  accountPitch?: AccountPitchI;
  setAccountPitch?: (pitch: AccountPitchI) => void;

  accountOneLiner?: AccountOneLinerI;
  setAccountOneLiner?: (oneLiner: AccountOneLinerI) => void;

  accountNote?: AccountNoteI;
  setAccountNote?: (note: AccountNoteI) => void;

  dialerToolkitViewOptions?: AccountDetailsViewOptionI[];
  setDialerToolkitViewOptions?: (options: AccountDetailsViewOptionI[]) => void;

  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;

  accountHistoryData: UseInfinitePaginationReturnI<AccountHistoryItemI>;

  focusedContact: ContactDetailI | undefined;
  setFocusedContact: (contact?: ContactDetailI) => void;

  isEmailSection?: boolean;
  setIsEmailSection?: (isEmailSection: boolean) => void;

  emailDispositionProps?: DispositionAdditionalDataI;
  setEmailDispositionProps?: (props: DispositionAdditionalDataI) => void;

  callCopilotContent?: WSCallCopilotMessageReceivedDataI[];
  setCallCopilotContent?: Dispatch<
    SetStateAction<WSCallCopilotMessageReceivedDataI[] | undefined>
  >;

  onAccountUpdate: () => void;

  callStatus?: DialerCallStatusI;
  areErrorsMuted?: boolean;
}

export interface AccountDetailsProviderI {
  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  accountPitch?: AccountPitchI;
  accountOneLiner?: AccountOneLinerI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI;
  accountNote?: AccountNoteI;
  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;
  customerAccountDisposition?: CustomerAccountDispositionI;

  accountHistory?: Array<AccountHistoryItemI>;

  onAccountUpdate: () => void;

  callStatus?: DialerCallStatusI;
  areErrorsMuted?: boolean;
}

const accountHistoryFetcher = (
  campaignId: string,
  accountId: string,
  nextToken: string | undefined
) =>
  glencocoClientAPI()
    .getAccountHistoryV2(campaignId, accountId, nextToken)
    .catch((e) => e);

const AccountDetailsContext = createContext<AccountDetailsContextI>({
  activeTab: undefined,
  setActiveTab: () => {},
  focusedContact: undefined,
  setFocusedContact: () => {},
  onAccountUpdate: () => {},
  accountHistoryData: {
    isLoading: false,
    isReachedEnd: false,
    data: [],
    reloadData: () => {},
    loadMore: () => {},
  },
  accountDetailsApi: {} as UseFetchAccountDetailsApiI,
  contactsForContextMenu: [],
  callStatus: undefined,
});

export const AccountDetailsProvider: FC<
  AccountDetailsProviderI & PropsWithChildren
> = ({
  campaign: campaignData,
  account: accountData,
  contacts: contactsData,
  accountUserLists: accountUserListsData,
  accountStatus: accountStatusData,
  accountSummary: accountSummaryData,
  accountPitch: accountPitchData,
  accountOneLiner: accountOneLinerData,
  accountHistory,
  accountNote: accountNoteData,
  customerAccountDisposition: customerAccountDispositionData,

  onAccountUpdate,
  accountExecutiveData,
  accountDetailsApi,
  areErrorsMuted = false,
  callStatus: callStatusFromProps,
  children,
}) => {
  const callStatus = useDialerCallStatus();
  const activeTabFromSearchQuery = useSearchQueryParams("tab");
  const [activeTab, setActiveTab] = useQueryState("tab", {
    defaultValue: activeTabFromSearchQuery || ACCOUNTS_DETAILS_TABS.DETAILS,
  });
  useEffect(() => {
    if (
      [DIALER_CALL_STATUS.DURING as string, DIALER_CALL_STATUS.AFTER].includes(
        callStatus
      )
    ) {
      setActiveTab(ACCOUNTS_DETAILS_TABS.DIALER_TOOLKIT);
    }
  }, [callStatus]);

  const [focusedContact, setFocusedContact] = useState<ContactDetailI>();

  const [campaign, setCampaign] = useState(campaignData);
  useEffect(() => setCampaign(campaignData), [campaignData]);

  const [account, setAccount] = useState(accountData);
  useEffect(() => setAccount(accountData), [accountData]);

  const [contacts, setContacts] = useState(contactsData);
  useEffect(() => setContacts(contactsData), [contactsData]);

  const [accountStatus, setAccountStatus] = useState(accountStatusData);
  useEffect(() => setAccountStatus(accountStatusData), [accountStatusData]);

  const [accountSummary, setAccountSummary] = useState(accountSummaryData);
  useEffect(() => setAccountSummary(accountSummaryData), [accountSummaryData]);

  const [accountOneLiner, setAccountOneLiner] = useState(accountOneLinerData);
  useEffect(
    () => setAccountOneLiner(accountOneLinerData),
    [accountOneLinerData]
  );

  const [customerAccountDisposition, setCustomerAccountDisposition] = useState(
    customerAccountDispositionData
  );
  useEffect(
    () => setCustomerAccountDisposition(customerAccountDispositionData),
    [customerAccountDispositionData]
  );

  const [accountPitch, setAccountPitch] = useState(accountPitchData);
  useEffect(() => setAccountPitch(accountPitchData), [accountPitchData]);

  const [accountExecutive, setAccountExecutive] =
    useState(accountExecutiveData);
  useEffect(
    () => setAccountExecutive(accountExecutive),
    [accountExecutiveData]
  );

  const [accountUserLists, setAccountUserLists] =
    useState(accountUserListsData);
  useEffect(
    () => setAccountUserLists(accountUserListsData),
    [accountUserListsData]
  );

  const [accountNote, setAccountNote] = useState(accountNoteData);
  useEffect(() => setAccountNote(accountNoteData), [accountNoteData]);

  const [dialerToolkitViewOptions, setDialerToolkitViewOptions] = useState([
    ...syncOptions(LEFT_SIDE_OPTIONS),
    ...syncOptions(RIGHT_SIDE_OPTIONS),
  ]);
  useEffect(() => {
    const LS = new LocalStorage();
    LS.accountDetailsViewOptions = [...dialerToolkitViewOptions];
  }, [dialerToolkitViewOptions]);

  const [isEmailSection, setIsEmailSection] = useState(false);

  /**
   * Use case
   * When call context is cleared, we need to clear the emails local drafts
   */
  useEffect(() => {
    if (!isEmailSection && callStatus === DIALER_CALL_STATUS.BEFORE) {
      const SS = new SessionStorage();
      SS.emailLocalDraftsByCallId = {};
    }
  }, [isEmailSection, callStatus]);

  /**
   * Returned after disposition submission and valid only
   * in email section after that it will be reset
   */
  const [emailDispositionProps, setEmailDispositionProps] =
    useState<DispositionAdditionalDataI>();

  const [callCopilotContent, setCallCopilotContent] =
    useState<WSCallCopilotMessageReceivedDataI[]>();

  const accountHistoryData = useInfinitePagination<AccountHistoryItemI, APII>({
    enabled: !accountHistory,
    isInitiallyLoading: true,
    apiFactory: glencocoClientAPI,
    collectionKeyInResponse: "activity_logs",
    fetchMore: (nextToken) =>
      accountHistoryFetcher(
        campaign?.id as string,
        account?.id as string,
        nextToken
      ),
    errorMessage:
      "Failed to load account history. Please message us in the chat window on the bottom right so we can assist.",
  });

  // As `contacts` represent a full collection of contacts including ones that
  // are disqualified or marked as "do not call", we need to filter them out for
  // Disqualify, Email, Add to List, Book/Reschedule Meeting and Call buttons.
  const contactsForContextMenu = useMemo(() => {
    if (!contacts) {
      return [];
    }

    return contacts.filter((contact) => {
      const { isLeadDisqualified, isLeadMarkedDNC } =
        getLeadStateFlags(contact);

      return !isLeadDisqualified && !isLeadMarkedDNC;
    });
  }, [contacts]);

  return (
    <AccountDetailsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        campaign,
        account,
        accountSummary,
        contacts,
        contactsForContextMenu,
        accountStatus,
        customerAccountDisposition,

        accountPitch,
        setAccountPitch,

        accountOneLiner,
        setAccountOneLiner,

        accountNote,
        setAccountNote,

        dialerToolkitViewOptions,
        setDialerToolkitViewOptions,

        isEmailSection,
        setIsEmailSection,

        emailDispositionProps,
        setEmailDispositionProps,

        callCopilotContent,
        setCallCopilotContent,

        // Use passed history data if available, otherwise - load from backend
        accountHistoryData: accountHistory
          ? {
              isLoading: false,
              isReachedEnd: true,
              data: accountHistory,
              reloadData: noop,
              loadMore: noop,
            }
          : accountHistoryData,

        focusedContact,
        setFocusedContact,
        onAccountUpdate,
        accountExecutiveData,
        accountUserLists,
        accountDetailsApi,

        areErrorsMuted,
        callStatus: callStatusFromProps || callStatus,
      }}
    >
      {children}
    </AccountDetailsContext.Provider>
  );
};

export const useAccountDetailsContext = (): AccountDetailsContextI =>
  useContext(AccountDetailsContext);
