// External Libraries
import { Formik, Form, FormikErrors } from "formik";
import * as Yup from "yup";
import wait from "wait";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Portal } from "react-portal";

// Shared/Utilities
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";
import {
  DISPOSITIONS_GLENX,
  DISPOSITIONS_V3,
  NEXT_TOUCH_TIME_OPTIONS,
  PROSPECT_DISPOSITION_TYPES,
} from "shared/lib/constants/dispositions";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

// Components
import { WIDGETS, widgets } from "@/widgets/index";
import {
  DATE_TIME_CALENDAR_MODAL_ID,
  DateTimeCalendarModal,
} from "@/modals/date-time-calendar-modal";
import { DispositionConversationRow } from "./conversation-row";
import { DispositionNoteRow } from "./note-row";
import { DispositionDecisionMakerRow } from "./decision-maker-row";
import { DispositionRow, isNextTouchTimeRequired } from "./disposition-row";
import { DispositionActionsRow } from "./actions-row";
import { DispositionSectionSearchRow } from "./search-row";

// Hooks
import { useDispositionSubmission } from "./use-disposition-submission-hook";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

//  Constants
import {
  DISPOSITION_CONTROL_KEYS,
  MIN_CONVERSATION_TIME_SECONDS,
} from "./constants";

// Interfaces
import {
  DispositionAdditionalDataI,
  DispositionSectionPropsI,
  FormValidationSchemaI,
} from "./interface";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useListTourContext } from "@/modules/tours/list/context";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

const DISPO_SECTION_DATE_TIME_CALENDAR_MODAL_ID = `dispo-section-${DATE_TIME_CALENDAR_MODAL_ID}`;

const FormDefaultValidationSchema = Yup.object().shape({});

const isDispositionRequired = (
  disposition_type: ValueOfObjectFields<typeof PROSPECT_DISPOSITION_TYPES>
) =>
  ![
    PROSPECT_DISPOSITION_TYPES.BAD_DATA,
    PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT,
    PROSPECT_DISPOSITION_TYPES.DO_NOT_CALL,
  ].includes(disposition_type);

const FormDispositionNurtureValidationSchema = Yup.object().shape({
  disposition: Yup.string().required(
    "Disposition is required to send feedback"
  ),
  next_touch_time: Yup.string().when("disposition", (disposition) => {
    return isNextTouchTimeRequired(disposition)
      ? Yup.string().required("Next touch time is required")
      : Yup.string().optional();
  }),
});

const FormDispositionValidationSchema = Yup.object().shape({
  disposition_type: Yup.string().required("You must select an option"),
  disposition: Yup.string().when("disposition_type", (disposition_type) => {
    return isDispositionRequired(disposition_type)
      ? Yup.string().required("Disposition is required to send feedback")
      : Yup.string().optional();
  }),
  next_touch_time: Yup.string().when(
    ["disposition_type", "disposition"],
    (disposition_type, disposition) => {
      return isDispositionRequired(disposition_type) &&
        isNextTouchTimeRequired(disposition)
        ? Yup.string().required("Next touch time is required")
        : Yup.string().optional();
    }
  ),

  /**
   * For meeting booked type only
   */
  meeting_time: Yup.string().when(
    ["disposition_type", "disposition"],
    (disposition_type, disposition) => {
      return isDispositionRequired(disposition_type) &&
        disposition === DISPOSITIONS_GLENX.MEETING_BOOKED
        ? Yup.string().required("Meeting time is required")
        : Yup.string().optional();
    }
  ),
  account_executive_id: Yup.string().when(
    ["disposition_type", "disposition"],
    (disposition_type, disposition) => {
      return isDispositionRequired(disposition_type) &&
        disposition === DISPOSITIONS_GLENX.MEETING_BOOKED
        ? Yup.string().required("Meeting taker is required")
        : Yup.string().optional();
    }
  ),
});

/**
 * NOTE
 *
 * The primary purpose of this component is to handle
 * default dropdown states based on selected values
 */
const DropdownsValueSetter = ({
  values,
  setValues,
  isDisposition,
  isDispositionType,
  setCustomNextTouchTime,
}: {
  values: FormValidationSchemaI;
  setValues: (
    values: SetStateAction<FormValidationSchemaI>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValidationSchemaI>>;
  isDisposition?: boolean;
  /**
   * If disposition type was preset from different flow
   * we need to make sure if disposition was selected
   * we have to unselect it
   */
  isDispositionType?: boolean;
  setCustomNextTouchTime: Dispatch<SetStateAction<string | undefined>>;
}) => {
  useEffect(() => {
    if (!isDisposition) {
      setValues({
        ...values,
        disposition: undefined,
        disposition_type: undefined,
        next_touch_time: undefined,
      });
    }
  }, [isDisposition]);

  useEffect(() => {
    if (isDispositionType) {
      setValues({
        ...values,
        disposition_type: undefined,
      });
    }
  }, [isDispositionType]);

  useEffect(() => {
    switch (values.disposition_type) {
      case !isDispositionRequired(values.disposition_type as string)
        ? values.disposition_type
        : "undefined0":
        setValues({
          ...values,
          disposition: undefined,
          next_touch_time: undefined,
        });
        break;
    }
  }, [values.disposition_type]);

  useEffect(() => {
    switch (values.disposition) {
      /**
       * Hacky way to squize switch case scenario into
       * handling specific similar condition
       */
      case [
        DISPOSITIONS_V3.DO_NOT_CALL,
        DISPOSITIONS_V3.QC_NOT_MET_FOR_ACCOUNT,
        DISPOSITIONS_V3.OTHER_DO_NOT_FOLLOW_UP,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined0":
        setValues({
          ...values,
          next_touch_time: undefined,
        });
        break;

      case [
        DISPOSITIONS_V3.NOT_QUALIFIED_NEED as string,
        DISPOSITIONS_V3.NOT_QUALIFIED_TIMING,
        DISPOSITIONS_V3.PITCH_REJECTED,
        DISPOSITIONS_V3.INTRO_REJECTED,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined1":
        setValues({
          ...values,
          next_touch_time: NEXT_TOUCH_TIME_OPTIONS.DAYS_45,
        });
        break;

      case [
        DISPOSITIONS_V3.INTERESTED as string,
        DISPOSITIONS_V3.OTHER_FOLLOW_UP,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined2":
        setValues({
          ...values,
          next_touch_time: NEXT_TOUCH_TIME_OPTIONS.DAYS_3,
        });
        break;
    }

    if (values.disposition !== DISPOSITIONS_GLENX.MEETING_BOOKED) {
      setValues({
        ...values,
        meeting_time: undefined,
        account_executive_id: undefined,
      });
    }
  }, [values.disposition]);

  useEffect(() => {
    if (values.next_touch_time === NEXT_TOUCH_TIME_OPTIONS.CUSTOM) {
      modalHelpers.open(DISPO_SECTION_DATE_TIME_CALENDAR_MODAL_ID);
    } else {
      setCustomNextTouchTime?.(undefined);
    }
  }, [values.next_touch_time]);

  const handleSelectCustomDateTime = (
    date: string,
    time: string,
    timezone: string
  ) => {
    const { hours, minutes } = convertTime12to24(time);
    const customNextTouchTime = DayJs(date)
      .hour(hours)
      .minute(minutes)
      .tz(TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode, true)
      .toISOString();

    setCustomNextTouchTime?.(customNextTouchTime);

    modalHelpers.close(DISPO_SECTION_DATE_TIME_CALENDAR_MODAL_ID);
  };

  /**
   * We want to switch value to NONE if user select CUSTOM time
   * then canceled the modal
   */
  const handleOnClose = async () => {
    await wait(1000);

    setCustomNextTouchTime?.((customNextTouchTime) => {
      if (!customNextTouchTime) {
        setValues({ ...values, next_touch_time: "" });
      }

      return customNextTouchTime;
    });
  };

  return (
    <>
      {values.next_touch_time === NEXT_TOUCH_TIME_OPTIONS.CUSTOM && (
        // @ts-ignore
        <Portal>
          <DateTimeCalendarModal
            modalId={DISPO_SECTION_DATE_TIME_CALENDAR_MODAL_ID}
            title="Set Next Touch Time"
            descritpion="Specify date and time when to remind you to call back"
            onSuccess={handleSelectCustomDateTime}
            onClose={handleOnClose}
          />
        </Portal>
      )}
    </>
  );
};

export const DispositionSectionUI = ({
  className,
  actionControlKeys,
  buttonTexts,
  note,
  onSubmit,
}: DispositionSectionPropsI) => {
  const { contacts } = useAccountDetailsContext();
  const { startAt, endAt, contact } = useInCallContext();

  const { isTourOpen: isTourMode } = useListTourContext();

  /**
   * Values used outside formik values flow
   */
  const [isDispoConversation, setIsDispoConversation] = useState<boolean>();
  const [customNextTouchTime, setCustomNextTouchTime] = useState<string>();

  useEffect(() => {
    if (startAt && endAt) {
      if (
        Math.abs(DayJs(startAt).diff(endAt, "seconds")) >
        MIN_CONVERSATION_TIME_SECONDS
      ) {
        setIsDispoConversation(true);
      } else {
        setIsDispoConversation(false);
      }
    }
  }, [startAt, endAt]);

  useEffect(() => {
    if (isTourMode) {
      setIsDispoConversation(true);
    }
  }, [isTourMode]);

  const formInitialValues: FormValidationSchemaI = useMemo(
    () => ({
      disposition_type: undefined,
      disposition: undefined,
      next_touch_time: undefined,
      meeting_time: undefined,
      account_executive_id: undefined,
      note: note || "",
      controlKey: undefined,
    }),
    [note]
  );

  const isDispoTypeSet = useMemo(() => {
    const currentContact = contacts?.find((c) => contact?.id === c?.id);

    if (!currentContact) return false;

    return !!currentContact?.contact_disposition_type;
  }, [contact?.id, contacts]);

  const DispositionSubmissionHookParams = useMemo(
    () => ({
      isDispoConversation,
      nextTouchTimeCustom: customNextTouchTime,
      onSuccess: (
        data: FormValidationSchemaI,
        additionalProps?: DispositionAdditionalDataI
      ) => {
        onSubmit?.(data, additionalProps);
      },
    }),
    [isDispoConversation, customNextTouchTime]
  );
  const { handleDipositionSubmission } = useDispositionSubmission(
    DispositionSubmissionHookParams
  );

  return (
    <section className={clsxMerge("w-full", className)}>
      <DispositionConversationRow
        className="mb-2"
        value={isDispoConversation}
        onChange={(value) => {
          setIsDispoConversation(value);
        }}
      />

      <Formik
        enableReinitialize
        validationSchema={
          isDispoConversation
            ? isDispoTypeSet
              ? FormDispositionNurtureValidationSchema
              : FormDispositionValidationSchema
            : FormDefaultValidationSchema
        }
        initialValues={formInitialValues}
        onSubmit={handleDipositionSubmission}
      >
        {({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setValues,
          handleSubmit,
        }) => (
          <Form className="flex grow flex-col">
            <DropdownsValueSetter
              values={values}
              setValues={setValues}
              isDispositionType={isDispoTypeSet}
              isDisposition={isDispoConversation}
              setCustomNextTouchTime={setCustomNextTouchTime}
            />

            {typeof isDispoConversation === "boolean" &&
              isDispoConversation && (
                <>
                  {!isDispoTypeSet && (
                    <DispositionDecisionMakerRow
                      name="disposition_type"
                      errors={errors.disposition_type}
                      touched={touched.disposition_type}
                      className="mb-3"
                    />
                  )}

                  {((!!values.disposition_type &&
                    isDispositionRequired(values.disposition_type as string)) ||
                    isDispoTypeSet) && (
                    <DispositionRow
                      names={{
                        DISPOSITION: "disposition",
                        NEXT_TOUCH_TIME: "next_touch_time",
                        MEETING_TIME: "meeting_time",
                        MEETING_TAKER: "account_executive_id",
                      }}
                      disposition={values.disposition}
                      errors={errors}
                      touched={touched}
                      customNextTouchTime={customNextTouchTime}
                      className="mb-6"
                      setValues={setValues}
                    />
                  )}
                </>
              )}

            {!isDispositionRequired(values.disposition_type as string) && (
              <DispositionSectionSearchRow />
            )}

            <DispositionNoteRow
              name="note"
              errors={errors.note}
              touched={touched.note}
            />

            <DispositionActionsRow
              actionControlKeys={actionControlKeys}
              buttonTexts={buttonTexts}
              values={values}
              setValues={setValues}
              isValid={typeof isDispoConversation === "boolean" && isValid}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export const DispositionSection = ({
  className,
  onSubmit,
}: DispositionSectionPropsI) => {
  const { onAccountUpdate, setIsEmailSection, setEmailDispositionProps } =
    useAccountDetailsContext();
  const { setCall } = useCallingContext();
  const { clear: clearInCallContext } = useInCallContext();

  const handlerOnSubmit = (
    data: FormValidationSchemaI,
    additionalProps?: DispositionAdditionalDataI
  ) => {
    if (additionalProps?.should_send_email) {
      setIsEmailSection?.(true);
      setEmailDispositionProps?.(additionalProps);
      onAccountUpdate();

      return;
    }

    setCall(undefined);
    clearInCallContext();
    onAccountUpdate();

    if (data?.controlKey === DISPOSITION_CONTROL_KEYS.CONTINUE_DIALING_LIST) {
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    }
  };

  return (
    <AccountDetailsSectionContainer
      title={"What happened on the call?"}
      className={clsxMerge(
        "border border-[#4474E3]",
        "animate-pulse-alt",
        className
      )}
      style={
        {
          "--pulse-alt-start-color": "rgba(68,116,227,.7)",
          "--pulse-alt-end-color": "rgba(0,0,0,0)",
        } as CSSProperties
      }
    >
      <DispositionSectionUI
        buttonTexts={["Save", "Submit & continue"]}
        actionControlKeys={["", DISPOSITION_CONTROL_KEYS.CONTINUE_DIALING_LIST]}
        onSubmit={onSubmit || handlerOnSubmit}
      />
    </AccountDetailsSectionContainer>
  );
};
