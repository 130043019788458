import { useMemo } from "react";

import {
  useGlobalContext,
  useIsSingleUserLicense,
} from "@/hooks/use-global-context";

export const useOnboardingStatus = () => {
  const isSingleUserLicense = useIsSingleUserLicense();
  const { userOnboardingStatus, isSingleUserLicenseSubscriptionActive } =
    useGlobalContext();

  const trialEndDate = userOnboardingStatus?.trial_end_date;

  const hasUploadedLeads = !!userOnboardingStatus?.is_leads_uploaded;

  const isTrialMode = useMemo(() => {
    const isTrialExpired = trialEndDate
      ? new Date(trialEndDate) < new Date()
      : false;

    return isSingleUserLicenseSubscriptionActive && !isTrialExpired;
  }, [trialEndDate, isSingleUserLicenseSubscriptionActive]);

  const isFinishedSetup = useMemo(
    () => userOnboardingStatus?.is_email_domain_set && hasUploadedLeads,
    [userOnboardingStatus, hasUploadedLeads]
  );

  return {
    isSingleUserLicenseSubscriptionActive,
    isSingleUserLicense,
    isTrialMode,
    trialEndDate,
    flags: {
      isTermsAccepted: userOnboardingStatus?.is_terms_accepted,
      isEmailDomainSet: userOnboardingStatus?.is_email_domain_set,
      isEmailDomainActive: userOnboardingStatus?.is_email_domain_active,
      isUploadedLeadList: hasUploadedLeads,
    },
    knowledgeBaseGenerationStatus: userOnboardingStatus?.kb_generation_status,
    isFinishedSetup,
  };
};
