import { useMemo } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { Logo } from "shared/ui/logo";
import { LOGO_VARIATION } from "shared/ui/logo/constants";

import { PRIMARY_NAVIGATION_V3_CONTAINER_ID } from "@/constants/element-ids";
import { NavItemsRow } from "@/components/navigation/primary/navbar/nav-items-row";
import { NAV_ITEMS_PRIMARY, NAV_ITEMS, NAV_GROUPS } from "./constants";
import { NotificationsButton } from "@/components/navigation/shared/notifications-button";
import { TodayPerformanceButton } from "@/components/navigation/shared/today-performance-button";
import { NavButtonPrimary } from "./nav-items-row/components/nav-button-primary";
import Router from "@/helpers/router";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import {
  NAV_ITEMS_PRIMARY_SINGLE_USER_LICENSE,
  SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_BILLING_ITEM,
  SINGLE_LICENSE_GETTING_STARTED_ITEM,
  SINGLE_LICENSE_GETTING_STARTED_SETTINGS_ITEM,
  getSingleLicenseSettingsItem,
  SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_SETTINGS_ITEM,
} from "@/components/navigation/primary/navbar/constants/single-user-license";
import { AIGenerationProcessesButton } from "@/components/navigation/shared/single-user-license/ai-generation-processes-button";
import { NavDropdown } from "@/components/navigation/primary/navbar/nav-items-row/components/nav-dropdown";

/**
 * @returns the black navbar that goes along the top of the app UI.
 */
export const PrimaryNavigationNavbar = () => {
  const { isSingleUserLicense, isSingleUserLicenseSubscriptionActive, flags } =
    useOnboardingStatus();
  const isSingleLicenseGettingStartedStep =
    isSingleUserLicense && !flags.isTermsAccepted;

  const settingsItem = useMemo(() => {
    if (isSingleUserLicense) {
      if (isSingleLicenseGettingStartedStep) {
        return SINGLE_LICENSE_GETTING_STARTED_SETTINGS_ITEM;
      }

      // If the subscription is not active, show only Billing and logout items
      if (!isSingleUserLicenseSubscriptionActive) {
        return SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_SETTINGS_ITEM;
      }

      return getSingleLicenseSettingsItem(flags.isEmailDomainSet);
    }

    return NAV_ITEMS[NAV_GROUPS.SETTINGS];
  }, [
    flags,
    isSingleUserLicense,
    isSingleUserLicenseSubscriptionActive,
    isSingleLicenseGettingStartedStep,
  ]);

  // Represents JSX nav items shown on the left part of the navbar
  const primaryNavigationItems = useMemo(() => {
    if (isSingleUserLicense) {
      if (isSingleLicenseGettingStartedStep) {
        return (
          <NavButtonPrimary navItem={SINGLE_LICENSE_GETTING_STARTED_ITEM} />
        );
      }

      if (!isSingleUserLicenseSubscriptionActive) {
        return (
          <NavButtonPrimary
            navItem={SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_BILLING_ITEM}
          />
        );
      }

      return (
        <>
          <NavDropdown
            navItem={NAV_ITEMS[NAV_GROUPS.LEADS]}
            dropdownClassName="mr-1 xl:mr-2"
          />

          <NavButtonPrimary
            navItem={NAV_ITEMS[NAV_GROUPS.EMAILS]}
            containerClassName="mr-1 xl:mr-2"
          />

          <NavItemsRow data={NAV_ITEMS_PRIMARY_SINGLE_USER_LICENSE} />
        </>
      );
    }

    return (
      <>
        <NavDropdown
          navItem={NAV_ITEMS[NAV_GROUPS.LEADS]}
          dropdownClassName="mr-1 xl:mr-2"
        />

        <NavButtonPrimary
          navItem={NAV_ITEMS[NAV_GROUPS.EMAILS]}
          containerClassName="mr-1 xl:mr-2"
        />

        <NavItemsRow data={NAV_ITEMS_PRIMARY} />
      </>
    );
  }, [
    isSingleUserLicense,
    isSingleUserLicenseSubscriptionActive,
    isSingleLicenseGettingStartedStep,
  ]);

  const isSecondaryButtonsBlockVisible = useMemo(() => {
    if (!isSingleUserLicense) {
      return true;
    }

    return (
      !isSingleLicenseGettingStartedStep &&
      isSingleUserLicenseSubscriptionActive
    );
  }, [
    isSingleUserLicense,
    isSingleLicenseGettingStartedStep,
    isSingleUserLicenseSubscriptionActive,
  ]);

  return (
    <div
      id={PRIMARY_NAVIGATION_V3_CONTAINER_ID}
      className={clsxMerge(
        "navbar z-30 justify-between bg-black p-0",
        "w-screen min-w-[800px]"
      )}
    >
      <div className="navbar-start h-full">
        <div className="shrink-0">
          <Logo
            className="block px-10"
            variation={LOGO_VARIATION.GLENX}
            width="68px"
            href={Router.home()}
          />
        </div>

        {primaryNavigationItems}
      </div>

      <div className="navbar-end sticky right-0 z-20 h-full w-fit">
        {isSecondaryButtonsBlockVisible && (
          <div className="flex h-full items-center gap-1 xl:gap-2">
            {isSingleUserLicenseSubscriptionActive && (
              <AIGenerationProcessesButton />
            )}

            <TodayPerformanceButton />
            <NotificationsButton />
          </div>
        )}

        <NavItemsRow
          data={[settingsItem]}
          className="xl:mx-1"
          dropdownClassName="dropdown-end"
        />
      </div>
    </div>
  );
};
