/**
 * NOTE
 *
 * Represents the active widgets in the global dialer context.
 *
 * This variable ensures that the leads queue remains paused
 * until the widget is closed.
 */
export const GLOBAL_STATE_VAR_ACTIVE_WIDGETS =
  "GLOBAL_STATE_VAR_ACTIVE_WIDGETS";

/**
 * Used as datadog debugger variable which contains all
 * global dialer state variables
 */
export const GLOBAL_DIALER_STATE = "GLOBAL_DIALER_STATE";

/**
 *
 * Contains all websocket instances
 */
export const GLOBAL_WS_INSTANCES = "GLOBAL_WS_INSTANCES";
