import {
  useState,
  createContext,
  useContext,
  useMemo,
  ReactNode,
  forwardRef,
  isValidElement,
  cloneElement,
  HTMLProps,
  useRef,
} from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  arrow,
  FloatingPortal,
  FloatingArrow,
  hide,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";

import { clsxMerge } from "shared/lib/helpers/styles";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

export type TooltipPlacementI = Placement;

export interface TooltipPropsI {
  isInitiallyOpen?: boolean;
  placement?: Placement;
  isOpen?: boolean;
  onVisibilityChange?: (isOpen: boolean) => void;
  colorScheme?: "light" | "dark";
  showDelay?: number;
  arrowFillColor?: string;
  offsetPx?: number;

  // Use it is you want to target an already existing element as TooltipTrigger
  // by its id, so you don't have to add <TooltipTrigger /> component.
  referenceId?: string;
}

const TOOLTIP_COLOR_SCHEME_STYLES = {
  light: "bg-white text-black shadow-md",
  dark: "bg-[rgba(0,0,0,.8)] text-white",
};

const TOOLTIP_ARROW_COLOR_SCHEME_STYLES = {
  light: "#fff",
  dark: "rgba(0,0,0,.8)",
};

const TOOLTIP_DEFAULT_OFFSET_PX = 10;

export function useTooltip({
  isInitiallyOpen = false,
  placement = "top",
  isOpen: controlledOpen,
  onVisibilityChange: setControlledOpen,
  colorScheme = "dark",
  showDelay = 300,
  referenceId,
  offsetPx,
  arrowFillColor = TOOLTIP_ARROW_COLOR_SCHEME_STYLES[colorScheme],
}: TooltipPropsI = {}) {
  const arrowRef = useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(isInitiallyOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    transform: true,
    middleware: [
      offset(offsetPx || TOOLTIP_DEFAULT_OFFSET_PX),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: TOOLTIP_DEFAULT_OFFSET_PX,
      }),
      shift({ padding: TOOLTIP_DEFAULT_OFFSET_PX }),
      arrow({
        element: arrowRef,
      }),
      hide(),
    ],
  });

  // If referenceId is provided, set the reference element by its id.
  useEffectOnce(() => {
    if (referenceId && document.getElementById(referenceId)) {
      const referenceElement = document.getElementById(referenceId);
      data.refs.setReference(referenceElement);
    }
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    restMs: showDelay,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      arrowRef,
      setOpen,
      colorScheme,
      arrowFillColor,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

/*
 TODO Make a test for this component.
 Currently it's hard to unit test it because of the FloatingUI dependency.
 Better to test it in e2e tests.
 */
export const Tooltip = ({
  children,
  ...options
}: { children: ReactNode } & TooltipPropsI) => {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

export const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  return (
    <button
      ref={ref}
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, forwardedRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, forwardedRef]);
  const { children, ...restFloatingProps } = context.getFloatingProps(
    props
  ) as any;

  if (!context.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...restFloatingProps}
        className={clsxMerge(
          "animate-fadein rounded-lg p-2 text-xs",
          TOOLTIP_COLOR_SCHEME_STYLES[context.colorScheme],
          context.middlewareData.hide?.referenceHidden
            ? "invisible"
            : "visible",
          props.className
        )}
      >
        <>
          <FloatingArrow
            ref={context.arrowRef}
            className="floating-arrow-icon"
            fill={
              context.arrowFillColor ||
              TOOLTIP_ARROW_COLOR_SCHEME_STYLES[context.colorScheme]
            }
            context={context.context}
          />
          {children}
        </>
      </div>
    </FloatingPortal>
  );
});
