export const HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID =
  "page-main-content-container";

export const PRIMARY_NAVIGATION_V3_CONTAINER_ID = "primary-navigation-v3";

export const PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID =
  "primary-navigation-content-container";

export const LISTS_ADD_LEAD_MENU_ID = "add-leads-button-menu";
export const LISTS_ADD_LEADS_MENU_IMPORT_LEADS_BUTTON_ID =
  "add-leads-menu-import-leads-button";

export const LISTS_START_CALLING_BUTTON_ID = "start-calling-button";

export const LISTS_ACCOUNT_DETAILS_STATUS_SECTION_ID =
  "account-details-status-section";

export const LISTS_ACCOUNT_DETAILS_SUMMARY_SECTION_ID =
  "account-details-summary-section";

export const LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID =
  "account-details-submit-disposition-buttons";

export const ACCOUNT_DETAILS_CONTENT_ID = "account-details-content";

export const FIND_MORE_LEADS_BUTTON_ID = "find-more-leads-button";

export const FIND_MORE_LEADS_MODAL_CONTENT_ID = "find-more-leads-modal-content";

export const LIST_SELECTOR_MENU_ID = "list-selector-menu";

export const LIST_SELECTOR_MENU_TRIGGER_ID = "list-selector-menu-trigger";
