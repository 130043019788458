// React and types
import { CSSProperties, FC, useEffect } from "react";

// Shared libraries and interfaces
import { clsxMerge } from "shared/lib/helpers/styles";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

// Hooks
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

// Components
import { EmailSectionContent } from "./content";
import { EmailSectionHeader } from "./header";

export const EmailSection: FC<PropsWithClassNameI> = ({ className }) => {
  const { setFocusedContact, contacts, emailDispositionProps } =
    useAccountDetailsContext();

  const { contact: callContact } = useInCallContext();

  useEffect(() => {
    const contact = contacts?.find((c) => c.id === callContact?.id);

    if (contact) {
      setFocusedContact(contact);
    }
  }, [callContact, contacts]);

  return (
    <AccountDetailsSectionContainer
      title={<EmailSectionHeader />}
      className={clsxMerge(
        "border border-[#4474E3]",
        "animate-pulse-alt",
        className
      )}
      style={
        {
          "--pulse-alt-start-color": "rgba(68,116,227,.7)",
          "--pulse-alt-end-color": "rgba(0,0,0,0)",
        } as CSSProperties
      }
    >
      <EmailSectionContent
        subject={emailDispositionProps?.email_subject}
        body={emailDispositionProps?.email_body}
        emailId={emailDispositionProps?.email_id}
      />
    </AccountDetailsSectionContainer>
  );
};
