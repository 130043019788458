import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";

import Router from "@/helpers/router";
import {
  useGlobalContext,
  useIsSingleUserLicense,
} from "@/hooks/use-global-context";
import { LoadingModule } from "@/modules/loading";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";

export const RedirectsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user: auth0User } = useAuth0();
  const { glencocoUser, isUserLoaded, userOnboardingStatus } =
    useGlobalContext();

  const isSingleUserLicense = useIsSingleUserLicense();
  const { isSingleUserLicenseSubscriptionActive } = useOnboardingStatus();

  const { replace } = useRouter();

  const [isRedirecting, setIsRedirecting] = useState(true);

  /**
   * Using Next's router replace function instead of `window.location.href =` to
   * prevent real page reload which are much slower than handling a client-side
   * navigation.
   */
  useEffect(() => {
    const url = new URL(window.location.href);

    //User is not Verified
    if (!auth0User?.email_verified && !url.pathname.includes(Router.verify())) {
      setIsRedirecting(true);
      // window.location.href = `${Router.verify()}${search}`;

      return void replace(`${Router.verify()}${url.search}`).then(() => {
        setIsRedirecting(false);
      });
    }

    /**
     * If isUserLoaded is true and glencocoUser is undefined, this means the user
     * is blocked from the platform.
     * Refer to /lib/api/glencoco.ts response interceptors to see how blocked
     * users are handled.
     */
    if (isUserLoaded && glencocoUser) {
      if (
        !userOnboardingStatus?.is_terms_accepted &&
        [
          Router.signup(),
          Router.verify(),
          Router.singleUserLicense.gettingStarted(),
        ].every((route) => !url.pathname.includes(route))
      ) {
        setIsRedirecting(true);

        return void replace(
          `${
            isSingleUserLicense
              ? Router.singleUserLicense.gettingStarted()
              : Router.signup()
          }${url.search}`
        ).then(() => {
          setIsRedirecting(false);
        });
      }

      //User is registered but trying to access signup page
      if (
        userOnboardingStatus?.is_terms_accepted &&
        url.pathname.includes(Router.signup())
      ) {
        setIsRedirecting(true);

        return void replace(Router.home()).then(() => {
          setIsRedirecting(false);
        });
      }

      if (isSingleUserLicense && !isSingleUserLicenseSubscriptionActive) {
        setIsRedirecting(true);

        return void replace(Router.singleUserLicense.billing()).then(() => {
          setIsRedirecting(false);
        });
      }

      setIsRedirecting(false);
    }
  }, [
    auth0User?.email_verified,
    isUserLoaded,
    userOnboardingStatus,
    isSingleUserLicenseSubscriptionActive,
  ]);

  // Show spinner while redirecting
  if (isRedirecting) {
    return <LoadingModule />;
  }

  return children;
};
