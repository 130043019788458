import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";

import CenteredSpinner from "shared/ui/spinners/centered-spinner";

import { AccountTopSectionContent } from "./content";
import { clsxMerge } from "shared/lib/helpers";

export const ContentWrapper = () => {
  const {
    accountDetailsApi,
    // campaign,
    account,
    accountStatus,
    // accountExecutiveData,
    // onAccountUpdate,
  } = useAccountDetailsContext();

  return (
    <DataStatesWrapper
      viewName="Account state"
      api={accountDetailsApi}
      loading={<CenteredSpinner className="h-[135px]" />}
    >
      <div
        className={clsxMerge(
          "h-[135px] w-full",
          "bg-gradient-to-t from-[#DADADA] to-[#F4F4F4]"
        )}
      >
        {accountStatus && (
          <AccountTopSectionContent
            account={account}
            accountStatus={accountStatus}
          />
        )}
      </div>
    </DataStatesWrapper>
  );
};

export const AccountTopSection = () => {
  const { callStatus } = useAccountDetailsContext();

  if (callStatus !== DIALER_CALL_STATUS.BEFORE) {
    return null;
  }

  return <ContentWrapper />;
};
