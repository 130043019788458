import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import { EmailSetupModal } from "@/modals/email-setup-modal";

/**
 * @returns a component that renders all the modals that need to be available across the app - on any page.
 */
export const AppWideModals = () => {
  const { isFinishedSetup } = useOnboardingStatus();

  return <>{!isFinishedSetup && <EmailSetupModal />}</>;
};
