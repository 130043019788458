import Router from "@/helpers/router";

export const APP_LAYOUT_SUPPORTED_ROUTES = [
  Router.home(),
  Router.lists(),
  Router.coaching(),
  Router.history(),
  Router.knowledge.builder(),
  Router.performance(),
  Router.emailInbox(),
  Router.training(),
  Router.trainingModule("[module_id]"),
  Router.settings.callForwarding(),
  Router.settings.emailSignature(),

  // Routes for single user licenses
  Router.singleUserLicense.billing(),
  Router.singleUserLicense.gettingStarted(),
  Router.singleUserLicense.emailAutomation(),
];
