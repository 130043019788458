import { FC, PropsWithChildren } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface NavButtonWrapperI extends PropsWithChildren, PropsWithClassNameI {
  isActive?: boolean;
  disabled?: boolean;
  containerClassName?: string;
}

/**
 *
 * @returns a wrapper that applies common styling for the nav bar buttons
 */
export const NavButtonWrapper: FC<NavButtonWrapperI> = ({
  isActive,
  disabled,
  containerClassName,
  className,
  children,
}) => {
  return (
    <div
      className={clsxMerge(
        "relative flex h-full items-center",
        containerClassName
      )}
    >
      <div
        className={clsxMerge(
          "btn btn-ghost relative",
          "group p-0",
          "brand-typography-h6 text-white",
          "cursor-pointer",
          "rounded-md border-[1px] border-[transparent]",
          "hover:border-[#CC3997]/50 hover:bg-[#CC3997]/20",
          {
            "border-[#CC3997]/50 bg-[#CC3997]/10": isActive,
            "pointer-events-none": disabled,
          },
          className
        )}
      >
        {children}
      </div>

      {isActive && (
        <div className="absolute bottom-0 left-0 h-1 w-full rounded-full bg-[#CC3997]" />
      )}
    </div>
  );
};
