import { useEffect, useState } from "react";

import { AccountExecutiveI, ContactDetailI } from "@/interfaces/accounts";
import { getAccountDetailsData } from "@/helpers/account-details";

import { checkIfClient } from "shared/lib/helpers";

import { CUSTOM_EVENTS } from "@/constants/custom-events";
import {
  AccountNoteI,
  GetAccountDetailsDataI,
} from "@/interfaces/account-details";
import { useFetchAccountDetails } from "@/api/routes/account/queries";
import { useEffectOnceWhen } from "shared/lib/hooks";
import { dispatchCustomEvent } from "@/helpers/events";
import { AccountReloadCompleteDataI } from "@/interfaces/custom-events";
import {
  AccountDetailsStatusI,
  AccountDetailsSummaryI,
  AccountI,
  AccountOneLinerI,
  AccountPitchI,
  AccountUserListI,
  CustomerAccountDispositionI,
} from "shared/lib/interfaces/account";
import { useGlobalContext } from "./use-global-context";

export const useAccountDetails = (
  campaignId: string,
  accountId: string,
  prefetchedData?: GetAccountDetailsDataI
) => {
  const { currentCampaign: campaign } = useGlobalContext();

  const [account, setAccount] = useState<AccountI | undefined>(
    prefetchedData?.account
  );

  const [accountSummary, setAccountSummary] = useState<
    AccountDetailsSummaryI | undefined
  >(prefetchedData?.accountSummary);

  const [accountPitch, setAccountPitch] = useState<AccountPitchI | undefined>(
    prefetchedData?.accountPitch
  );

  const [accountOneLiner, setAccountOneLiner] = useState<
    AccountOneLinerI | undefined
  >(prefetchedData?.accountOneLiner);

  const [contacts, setContacts] = useState<ContactDetailI[] | undefined>(
    prefetchedData?.contacts
  );

  const [accountExecutiveData, setAccountExecutiveData] = useState<
    AccountExecutiveI | undefined
  >(prefetchedData?.accountExecutiveData);

  const [accountStatus, setAccountStatus] = useState<
    AccountDetailsStatusI | undefined
  >(prefetchedData?.accountStatus);

  const [accountUserLists, setAccountUserLists] = useState<
    AccountUserListI[] | undefined
  >(prefetchedData?.accountUserLists);

  const [customerAccountDisposition, setCustomerAccountDisposition] = useState<
    CustomerAccountDispositionI | undefined
  >(prefetchedData?.customerAccountDisposition);

  const [accountNote, setAccountNote] = useState<AccountNoteI>();

  const [isInitialFetchComplete, setIsInitialFetchComplete] =
    useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>();
  const [isErrorOccurred, setIsErrorOccurred] = useState<boolean>(false);

  /**
   * Declaring and re-exporting it here to:
   * 1) avoid specifying campaign and account
   *    IDs in every call of useFetchAccountDetails down in the component tree.
   * 2) support re-fetching of the query in the onAccountUpdate function down here.
   */
  const accountDetailsQuery = useFetchAccountDetails(campaignId, accountId);

  const accountDetailsApi = {
    ...accountDetailsQuery,
    // This customization is required, because other than just using useFetchAccountDetails
    // hook, we're also manually fetching query in getAccountDetailsData, which
    // doesn't switch isFetching flag of query to `true`. However, we rely on this
    // flag to show loading state in the UI.
    isFetching: accountDetailsQuery.isFetching || !!isFetchingData,
  };

  const updateAccountDetails = async () => {
    setIsFetchingData(true);
    setIsErrorOccurred(false);

    const data = await getAccountDetailsData(campaignId, accountId);

    setIsErrorOccurred(data.isError || !!data.isNotApprovedForCampaign);

    setAccountStatus(data?.accountStatus);
    setAccountSummary(data?.accountSummary);
    setAccountPitch(data?.accountPitch);
    setAccountOneLiner(data?.accountOneLiner);

    setAccount(data?.account);
    setAccountExecutiveData(data?.accountExecutiveData);
    setAccountUserLists(data?.accountUserLists);
    setContacts(data?.contacts);
    setIsFetchingData(false);
    setAccountNote(data?.accountNote);
    setCustomerAccountDisposition(data?.customerAccountDisposition);

    return data;
  };

  const onAccountUpdate = async () => {
    if (checkIfClient()) {
      const data = await updateAccountDetails();

      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD_COMPLETE,
        data: {
          campaign: data?.campaign,
          account: data?.account,
        } as AccountReloadCompleteDataI,
      });
    }
  };

  useEffect(() => {
    if (!prefetchedData) {
      updateAccountDetails();
    }

    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.RELOAD,
      onAccountUpdate
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.RELOAD,
        onAccountUpdate
      );
    };
  }, [prefetchedData]);

  useEffectOnceWhen(() => {
    setIsInitialFetchComplete(true);
  }, isFetchingData === false);

  return {
    isFetchingData,
    isInitialFetchComplete,
    isErrorOccurred,

    accountDetailsApi,
    campaign,
    account,
    accountSummary,
    accountPitch,
    accountOneLiner,
    contacts,
    accountStatus,
    accountExecutiveData,
    accountUserLists,
    accountNote,
    customerAccountDisposition,

    onAccountUpdate,
  };
};
