import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import { clsxMerge } from "shared/lib/helpers";
import { TextField } from "shared/ui/user-input";
import { inputBadgesToString } from "shared/ui/user-input/badge-input/helper";
import { BadgeI, BadgeValidationI } from "shared/ui/user-input/badge-input";
import { EmailEditor, EmailEditorContentI } from "shared/ui/email/editor";
import {
  AttachmentI,
  EmailTemplateI,
  SendEmailRequestParamsI,
} from "@/api/routes/email";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useValidateEmails } from "@/components/modules/email-templates-sidebar/queries";
import { EmailAttachmentsSection } from "@/modules/email-templates-sidebar/shared/email-attachments-section";
import { EmailRecipientsSection } from "./recipients-section";
import { EmailFollowUpsSection } from "./follow-ups-section";
import { ComposeWithAiButton } from "./compose-with-ai-btn";
import { Portal } from "react-portal";
import {
  EMAIL_TEMPLATE_UNVERIFIED_CONFIRM_MODAL_ID,
  EmailTemplateUnverifiedConfirmModal,
} from "@/components/modals/email-template-unverified-confirm-modal";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { EmailFormActionsSection as DefaultActionsSection } from "./actions-section";

interface EmailFormPropsI {
  template: EmailTemplateI;
  senderEmail?: string;
  initialSubject?: string;
  initialBody?: string;
  attachmentOptions?: AttachmentI[];
  onComposeWithAI?: () => void;
  isAIGenerationComplete?: boolean;
  isAIGenerating?: boolean;
  onSend?: (data: SendEmailRequestParamsI) => Promise<boolean>;
  isSendingEmail?: boolean;
  isSendEmailSuccess?: boolean;

  hideTitle?: boolean;
  hideIntro?: boolean;
  hideAiGenerateButton?: boolean;

  /**
   * Allows for a custom actions section to replace the default one.
   * Note: When implementing a custom actions section, exercise caution with the type="submit" attribute.
   * Using both a custom submit handler and type="submit" will trigger duplicate form submissions,
   * as the form will be submitted through both mechanisms simultaneously.
   */
  actionsSection?:
    | React.ReactNode
    | ((props: { submitForm: () => void }) => React.ReactNode);

  onChangeSubject?: (subject?: string) => void;
  onChangeBody?: (body?: string) => void;
}

export interface EmailFormI {
  to: BadgeI[] | string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  max_follow_ups: number;
  follow_up_interval_days: number;
}

const EnhancedTextField = TextField(Field);

const EmailFormValidation = Yup.object().shape({
  to: Yup.array().required("Required field").min(1, "At least 1 is required"),
  cc: Yup.array(),
  bcc: Yup.array(),
  subject: Yup.string()
    .trim()
    .required("Required field")
    .max(500, "Subject cannot exceed 500 characters"),
});

const TrackChangesAndUpdateState = ({
  value,
  setState,
}: {
  value?: any;
  setState?: (value: any) => void;
}) => {
  useEffect(() => {
    setState?.(value);
  }, [value]);

  return null;
};

export const EmailForm: FC<EmailFormPropsI> = ({
  template,
  senderEmail,
  initialSubject,
  initialBody,
  attachmentOptions = [],
  onComposeWithAI = () => {},
  isAIGenerationComplete,
  isAIGenerating,
  onSend = async () => false,
  isSendingEmail,
  isSendEmailSuccess,
  hideTitle,
  hideIntro,
  hideAiGenerateButton,
  actionsSection,
  onChangeBody,
  onChangeSubject,
}) => {
  const { focusedContact, account, campaign } = useAccountDetailsContext();
  const { mutateAsync: validateEmailsAsync } = useValidateEmails();
  const [isEmailsValid, setIsEmailsValid] = useState(false);
  const formikRef = useRef<FormikProps<EmailFormI>>(null);

  const [bodyContent, setBodyContent] = useState<EmailEditorContentI>();
  const [bodyError, setBodyError] = useState<string>();

  const [isSendFollowUps, setIsSendFollowUps] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState<AttachmentI[]>(
    template.attachments || []
  );

  // the "to" field uses <BadgeInput />, so the default value for the "to" field needs to be type BadgeI
  const form: EmailFormI = useMemo(
    () => ({
      to: [
        {
          label: focusedContact?.email || "",
          value: focusedContact?.email || "",
        },
      ],
      cc: [],
      bcc: [],
      subject: "",
      max_follow_ups: 2,
      follow_up_interval_days: 2,
    }),
    [focusedContact]
  );

  const handleEmailValidation = async (emails: string[]) => {
    const resp = await validateEmailsAsync(emails);

    const validationData: BadgeValidationI = {
      validValues: [],
      invalidValues: [],
    };

    if (resp.status === 200) {
      validationData.validValues = resp.data.valid_emails;
      validationData.invalidValues = resp.data.invalid_emails;
    }

    setIsEmailsValid(
      validationData.invalidValues.length === 0 &&
        validationData.validValues.length > 0
    );

    return validationData;
  };

  const handleAttachmentsChange = (attachment: AttachmentI) => {
    let updatedAttachments = selectedAttachments;

    if (selectedAttachments.filter((v) => v.id === attachment.id).length) {
      updatedAttachments = updatedAttachments.filter(
        (v) => v.id !== attachment.id
      );
    } else {
      updatedAttachments.push(attachment);
    }

    setSelectedAttachments([...updatedAttachments]);
  };

  const onFormSubmitHandler = async (formData: EmailFormI) => {
    setBodyError(undefined);

    if (!bodyContent?.plainText?.trim() || !bodyContent?.htmlText?.trim()) {
      setBodyError("Email body is required");
      return;
    }

    await onSend({
      contact_id: focusedContact?.id as string,
      account_id: account?.id as string,
      campaign_id: campaign?.id as string,
      to: inputBadgesToString(formData.to),
      cc: inputBadgesToString(formData.cc),
      bcc: inputBadgesToString(formData.bcc),
      subject: formData.subject,
      plain_body: bodyContent.plainText,
      html_body: bodyContent.htmlText,
      attachments: selectedAttachments.map((v) => v.id),
      max_follow_ups: isSendFollowUps
        ? parseInt(formData.max_follow_ups.toString())
        : 0,
      follow_up_interval_days: parseInt(
        formData.follow_up_interval_days.toString()
      ),
    });
  };

  useEffect(() => {
    formikRef.current?.setFieldValue("subject", initialSubject || "");
  }, [initialSubject]);

  const handleSubmit = (submitForm: () => void) => () => {
    if (!isEmailsValid) {
      modalHelpers.trigger(EMAIL_TEMPLATE_UNVERIFIED_CONFIRM_MODAL_ID);
    } else {
      submitForm();
    }
  };

  useEffect(() => {
    setBodyContent({
      htmlText: initialBody,
    });
  }, [initialBody]);

  useEffect(() => {
    onChangeBody?.(bodyContent?.htmlText);
  }, [bodyContent?.htmlText]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={form}
        validationSchema={EmailFormValidation}
        onSubmit={onFormSubmitHandler}
        enableReinitialize
      >
        {({ errors, touched, submitForm, values }) => (
          <Form className="flex h-full flex-col justify-between">
            <TrackChangesAndUpdateState
              value={values?.subject}
              setState={onChangeSubject}
            />

            <section>
              {!hideIntro && (
                <div className="mb-5">
                  {!hideTitle && (
                    <div className="mb-3 typography-header-6">
                      {template.name}
                    </div>
                  )}

                  <div className="mb-2 typography-body-4-medium">
                    <span className="gradient-text">Compose with AI</span> will
                    generate an email with the following prompt:
                  </div>

                  <div className="rounded-lg bg-white px-3 py-2 italic typography-body-4">
                    {template.prompt}
                  </div>
                </div>
              )}

              <EmailRecipientsSection
                className={clsxMerge({ "-mb-6": hideAiGenerateButton })}
                senderEmail={senderEmail}
                errors={errors}
                touched={touched}
                onValidateEmails={handleEmailValidation}
                disabled={isAIGenerating}
              />

              <div className="mb-5">
                <div
                  className={clsxMerge(
                    "mb-2 flex items-center justify-between"
                  )}
                >
                  <h6 className="typography-body-4-bold">Subject</h6>

                  {!hideAiGenerateButton &&
                    !isAIGenerationComplete &&
                    !isAIGenerationComplete && (
                      <ComposeWithAiButton
                        onClick={onComposeWithAI}
                        loading={isAIGenerating}
                      />
                    )}
                </div>

                <EnhancedTextField
                  name="subject"
                  placeholder="Just following up on my last call"
                  inputClassName={clsxMerge(
                    "bg-white border-[#ccc] typography-body-4-medium placeholder:text-[#ccc]",
                    isAIGenerating && "bg-black/5"
                  )}
                  errors={errors.subject}
                  touched={touched.subject}
                  disabled={isAIGenerating}
                />
              </div>

              <div className="mb-5">
                <h6 className="mb-2 typography-body-4-bold">Body</h6>

                <EmailEditor
                  className={clsxMerge("border border-[#ccc]", {
                    "bg-black/5": isAIGenerating,
                  })}
                  placeholder="Dear prospect..."
                  onChange={(content) => {
                    setBodyError(undefined);
                    setBodyContent(content);
                  }}
                  content={bodyContent?.htmlText}
                  initialContent={initialBody}
                  error={bodyError}
                  disabled={isAIGenerating}
                />
              </div>

              <EmailAttachmentsSection
                attachmentOptions={attachmentOptions}
                selectedAttachments={selectedAttachments}
                onChange={handleAttachmentsChange}
              />

              <div className="divider" />

              <EmailFollowUpsSection
                className="mb-5"
                isSendFollowUps={isSendFollowUps}
                onChangeFollowUpsToggle={setIsSendFollowUps}
              />
            </section>

            {(actionsSection &&
              (typeof actionsSection === "function"
                ? actionsSection({ submitForm: handleSubmit(submitForm) })
                : actionsSection)) || (
              <DefaultActionsSection
                isSendingEmail={isSendingEmail}
                isSendEmailSuccess={isSendEmailSuccess}
                isEmailsValid={isEmailsValid}
                isAIGenerating={isAIGenerating}
                onSubmit={handleSubmit(submitForm)}
              />
            )}

            {/*@ts-ignore*/}
            <Portal>
              <EmailTemplateUnverifiedConfirmModal
                email={inputBadgesToString(values.to)[0]}
                onConfirm={submitForm}
              />
            </Portal>
          </Form>
        )}
      </Formik>
    </>
  );
};
