import { LeadCardAvatar } from "./avatar";
import { LeadInfo } from "./lead-info";
import { DialerConnectionLabel } from "@/modules/calling/cards/lead-card/lead-avatar/connection-label";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

export const LeadCard = () => {
  const { callStatus } = useAccountDetailsContext();

  return (
    <div className="flex h-full items-center justify-start">
      <div className="relative flex justify-center">
        <LeadCardAvatar
          className="relative justify-start"
          avatarClassName="relative w-[90px] z-[1]"
          ripplesClassName="z-[0] relative top-[45px] scale-[1] "
        />

        <DialerConnectionLabel
          className="absolute top-[calc(50%+18px)] z-10"
          callStatus={callStatus}
        />
      </div>

      <LeadInfo />
    </div>
  );
};
