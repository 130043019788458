import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import {
  AcademicCapIcon,
  ArrowLeftStartOnRectangleIcon,
  UserIcon,
  PresentationChartLineIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";
import { Headset } from "lucide-react";

import { openProfileSettings } from "@/lib/store/profile-settings";
import { BILLING_ITEM_ID, SETTINGS_ITEM_ID } from "./index";

import Router from "@/helpers/router";
import {
  BillIcon,
  CoachIcon,
  CogsIcon,
  EmailAutomationIcon,
} from "shared/ui/icons";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { EMAIL_SETUP_MODAL_ID } from "@/components/modals/email-setup-modal";

export const SINGLE_LICENSE_GETTING_STARTED_ITEM = {
  text: "Getting Started",
  icon: <RocketLaunchIcon />,
  href: Router.singleUserLicense.gettingStarted(),
  disabled: false,
};

// This item is intended to be used in the case when the subscription is expired
export const SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_BILLING_ITEM = {
  text: "Billing",
  icon: <BillIcon />,
  href: Router.singleUserLicense.billing(),
  disabled: false,
};

export const SINGLE_LICENSE_GETTING_STARTED_SETTINGS_ITEM = {
  text: "Settings",
  id: SETTINGS_ITEM_ID,
  icon: <CogsIcon className="w-5" />,
  isCollapsed: true,
  children: [
    {
      text: "Sign out",
      href: Router.logout(),
      icon: <ArrowLeftStartOnRectangleIcon />,
    },
  ],
};

export const SINGLE_LICENSE_SUBSCRIPTION_EXPIRED_SETTINGS_ITEM = {
  text: "Settings",
  id: SETTINGS_ITEM_ID,
  icon: <CogsIcon className="w-5" />,
  isCollapsed: true,
  children: [
    {
      text: "Billing",
      icon: <BillIcon />,
      href: Router.singleUserLicense.billing(),
    },
    {
      text: "Sign out",
      href: Router.logout(),
      icon: <ArrowLeftStartOnRectangleIcon />,
    },
  ],
};

export const getSingleLicenseSettingsItem = (
  isEmailDomainSet: boolean | undefined
) => ({
  text: "Settings",
  id: SETTINGS_ITEM_ID,
  icon: <CogsIcon className="w-5" />,
  isCollapsed: true,
  children: [
    {
      text: "My Profile",
      handler: openProfileSettings,
      icon: <UserIcon />,
    },
    {
      text: "Email automation",
      icon: <EmailAutomationIcon />,
      ...(isEmailDomainSet
        ? { href: Router.singleUserLicense.emailAutomation() }
        : { handler: () => modalHelpers.open(EMAIL_SETUP_MODAL_ID) }),
    },
    {
      text: "Call Settings",
      href: Router.settings.callForwarding(),
      icon: <Headset size={16} />,
    },
    {
      text: "Billing",
      id: BILLING_ITEM_ID,
      icon: <BillIcon />,
      href: Router.singleUserLicense.billing(),
    },
    {
      text: "Sign out",
      href: Router.logout(),
      icon: <ArrowLeftStartOnRectangleIcon />,
    },
  ],
});

export const SINGLE_USER_LICENSE_COACHING_ITEM = {
  text: "Coaching",
  icon: <CoachIcon />,
  href: Router.coaching(),
  disabled: false,
};

export const SINGLE_USER_LICENSE_KNOWLEDGE_ITEM = {
  text: "Knowledge",
  icon: <AcademicCapIcon />,
  href: Router.knowledge.builder(),
  disabled: false,
  children: [
    {
      text: "Knowledge base",
      href: Router.knowledge.builder(),
      icon: <CircleStackIcon />,
    },
    {
      text: "Training",
      href: Router.training(),
      icon: <PresentationChartLineIcon />,
    },
  ],
};

export const NAV_ITEMS_PRIMARY_SINGLE_USER_LICENSE = [
  SINGLE_USER_LICENSE_COACHING_ITEM,
  SINGLE_USER_LICENSE_KNOWLEDGE_ITEM,
];
