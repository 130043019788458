import { AxiosError } from "axios";
import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";
import { dd } from "@/helpers/datadog";

import { GLOBALLY_HANDLED_ERRORS } from "@/constants/errors";
import { handleGlobalErrorMessage } from "@/helpers/error";

import { redirectOnAxiosErrorInterceptor } from "@/auth/utils";

// Any endpoint which URL starts with any of these groups will throw an error in react query
const ENDPOINT_GROUPS_TO_THROW_AN_ERROR = [
  "v1/list",
  "v2/campaign",
  "v1/chat_inbox",
];

const checkIfShouldThrowExceptionForReactQuery = (url?: string) => {
  if (!url) {
    return false;
  }

  // custom conditions to throw an error
  const conditions = [
    ENDPOINT_GROUPS_TO_THROW_AN_ERROR.some((group) => url.includes(group)),
    [
      // Set contact type for a contact on the account details sidebar
      "/set_contact_type",

      // Set/unset contact as primary on the account details sidebar
      "/set_primary",

      // Edit next touch time for the nurturing state on the account details sidebar
      "/edit_next_follow_up_time",

      // Get selected contact lists to decide which lists to show on the account details "Add to list" sidebar
      "/list_memberships",
    ].some((endpointEnd) => url.endsWith(endpointEnd)),
  ];

  return conditions.some(Boolean);
};

const isLocal = process.env.NEXT_PUBLIC_ENV === "local";

export const handleOnErrorInterceptor = (
  e: AxiosError<any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  const url = `${e?.config?.baseURL}${e?.config?.url}`;
  const errorCode = e?.response?.data?.error_code as number;
  const errorContext = {
    data: {
      errorCode,
      error: e,
      pageUrl: window?.location.href,
    },
  };

  if (
    errorCode &&
    Object.keys(GLOBALLY_HANDLED_ERRORS).includes(errorCode.toString())
  ) {
    handleGlobalErrorMessage(errorCode, "Failed to load data.");
  }

  redirectOnAxiosErrorInterceptor(e);

  const startedAt = e?.config?.meta?.requestStartedAt;
  if (!startedAt) console.log(`Execution time undefined`);

  const executionTime =
    new Date().getTime() - (e?.config?.meta?.requestStartedAt || 0);

  const isNetworkError = !!e?.isAxiosError && !e?.response;
  const isTimedout =
    e?.code === "ECONNABORTED" && e?.message?.includes("timeout");

  if (isNetworkError) {
    dd.error(`NETWORK ERROR - ${url}`, errorContext);
  }

  if (!isNetworkError && isTimedout) {
    dd.error(`REQUEST TIMEDOUT $${executionTime}ms - ${url}`, errorContext);
  }

  //github.com/axios/axios/issues/383#issuecomment-234079506

  const message = `Endpoint ${e?.response?.status} ${e?.config?.method} ${url} ${executionTime}ms`;

  if (!(isTimedout || isNetworkError)) {
    dd.error(message, errorContext);
  }

  if (isLocal) {
    console.error(message, errorContext);
  }

  /**
   * TODO this is a temporary solution that needs to be changed.
   * Without throwing the error, the error is not being caught by react-query,
   * leading to tons of potential unhandled behaviors.
   **/
  if (checkIfShouldThrowExceptionForReactQuery(e.config?.url)) {
    throw e;
  }

  return e;
};
