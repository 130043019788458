import { AxiosInstance } from "axios";

import { UserOnboardingI } from "@/interfaces/user";

export const UserOnboardingAPIEndpoints = (api: AxiosInstance) => ({
  submitUserOnboarding: (params: UserOnboardingI) =>
    api.post("/x/v1/onboarding_submit", params),

  submitSingleUserLicenseOnboarding: (params: UserOnboardingI) =>
    api.post("/x/v2/onboarding_submit", params),
});
