import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { LISTS_ACCOUNT_DETAILS_SUMMARY_SECTION_ID } from "@/constants/element-ids";

export const AccountSummarySection: FC<PropsWithClassNameI> = ({
  className,
}) => {
  const { accountDetailsApi, accountSummary: accountDetailsSummaryData } =
    useAccountDetailsContext();

  const aiSummary = accountDetailsSummaryData?.ai_summary;

  return (
    <div className={clsxMerge("rounded-lg bg-white", className)}>
      <AccountDetailsSectionContainer
        id={LISTS_ACCOUNT_DETAILS_SUMMARY_SECTION_ID}
        title="Account summary"
      >
        <DataStatesWrapper
          viewName="account summary"
          api={accountDetailsApi}
          loading={<CenteredSpinner className="h-[140px]" />}
        >
          {aiSummary ? (
            <div className="typography-body-4">{aiSummary}</div>
          ) : (
            <div className="italic text-black/30 typography-body-4">
              No data available
            </div>
          )}
        </DataStatesWrapper>
      </AccountDetailsSectionContainer>
    </div>
  );
};
