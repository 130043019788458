import { FC, useMemo, useState } from "react";
import { Field, FormikErrors, FormikTouched } from "formik";

import { clsxMerge } from "shared/lib/helpers";
import { BadgeInput } from "shared/ui";
import { TextField } from "shared/ui/ae-user-input";
import { EmailFormI } from "@/modules/email-templates-sidebar/content/email-template-workspace/template/email-form";
import { BadgeI, BadgeValidationI } from "shared/ui/user-input/badge-input";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

const EnchancedBadgeInput = BadgeInput(Field);
const EnhancedTextField = TextField();

interface EmailRecipientsSectionPropsI {
  className?: string;
  senderEmail?: string;
  errors: FormikErrors<EmailFormI>;
  touched: FormikTouched<EmailFormI>;
  disabled?: boolean;
  onValidateEmails?: (emails: string[]) => Promise<BadgeValidationI>;
}

export const EmailRecipientsSection: FC<EmailRecipientsSectionPropsI> = ({
  className,
  senderEmail,
  errors,
  touched,
  disabled,

  onValidateEmails = () =>
    Promise.resolve({ validValues: [], invalidValues: [] }),
}) => {
  const { contacts, focusedContact } = useAccountDetailsContext();
  const [isCcVisible, setIsCcVisible] = useState(false);
  const [isBccVisible, setIsBccVisible] = useState(false);

  const emailInputBadgeOptions: BadgeI[] = useMemo(
    () =>
      contacts?.map((c) => ({
        label: c.email || "",
        value: c.email || "",
      })) || [],
    [contacts]
  );

  const defaultEmailBadge: BadgeI = useMemo(
    () => ({
      label: focusedContact?.email || "",
      value: focusedContact?.email || "",
    }),
    [focusedContact]
  );

  return (
    <section className={clsxMerge("", className)}>
      <div className="grid grid-cols-2 gap-11">
        <EnhancedTextField
          name="from"
          placeholder=""
          label="From"
          className="mt-2"
          labelClassName="b-typography-h5 px-1 py-2 font-medium text-black"
          inputClassName="bg-white max-h-12"
          inputProps={{ value: senderEmail }}
          disabled
        />

        <div>
          <div className="flex justify-between">
            <h5 className="b-typography-h5 px-1 py-2 font-medium">To</h5>

            <div className="flex gap-2">
              {!isCcVisible && (
                <button
                  type="button"
                  className="ae-link"
                  onClick={() => setIsCcVisible(true)}
                >
                  CC
                </button>
              )}
              {!isBccVisible && (
                <button
                  type="button"
                  className="ae-link"
                  onClick={() => setIsBccVisible(true)}
                >
                  BCC
                </button>
              )}
            </div>
          </div>

          <EnchancedBadgeInput
            name="to"
            placeholder="Enter email address"
            value={[defaultEmailBadge]} //component's internal value gets updated through user input, so this basicially acts as initial value
            errors={errors.to}
            touched={touched.to}
            className="m-0"
            presetOptions={emailInputBadgeOptions}
            onValidateBadge={onValidateEmails}
            badgeValidationErrorMessage="Email(s) has not been verified"
            badgeValidationSuccessMessage="Email(s) have been verified"
            badgeValidationErrorClassName="bg-[#F8A467] text-white px-3 py-1 rounded-lg"
            disabled={disabled}
          />
        </div>
      </div>

      <div className="mb-5 grid grid-cols-2 gap-11">
        {isCcVisible && (
          <EnchancedBadgeInput
            name="cc"
            label="CC"
            placeholder="Enter email address"
            errors={errors.cc}
            touched={touched.cc}
            labelContentClassName="b-typography-h5 font-medium"
            presetOptions={emailInputBadgeOptions}
            onValidateBadge={onValidateEmails}
            badgeValidationErrorMessage="Email(s) has not been verified"
            badgeValidationSuccessMessage="Email(s) have been verified"
            badgeValidationErrorClassName="bg-[#F8A467] text-white px-3 py-1 rounded-lg"
            disabled={disabled}
          />
        )}

        {isBccVisible && (
          <EnchancedBadgeInput
            name="bcc"
            label="BCC"
            placeholder="Enter email address"
            errors={errors.bcc}
            touched={touched.bcc}
            labelContentClassName="b-typography-h5 font-medium"
            presetOptions={emailInputBadgeOptions}
            onValidateBadge={onValidateEmails}
            badgeValidationErrorMessage="Email(s) has not been verified"
            badgeValidationSuccessMessage="Email(s) have been verified"
            badgeValidationErrorClassName="bg-[#F8A467] text-white px-3 py-1 rounded-lg"
            disabled={disabled}
          />
        )}
      </div>
    </section>
  );
};
