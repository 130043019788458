import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { useApi } from "shared/lib/hooks";

import {
  useDeleteTemplate,
  useFetchEmailTemplatesList,
  useEmailGenerationInfo,
  useGenerateAIEmailStreamBody,
  useGenerateAIEmailStreamSubject,
} from "@/modules/email-templates-sidebar/queries";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { glencocoClientAPI } from "@/api/glencoco";
import { APII } from "@/api/glencoco";
import {
  EmailTemplateI,
  GetEmailGenerationInfoResponseI,
  GetEmailGenerationStatusResponseI,
} from "@/api/routes/email";
import { GenerateEmailStreamRequestParamsI } from "shared/lib/interfaces/email/ai";
import { ERRORS } from "@/constants/errors";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

export * from "./use-email-template-local-drafts";

export const handleEmailGenerationError = (
  resp: any,
  errorMessage?: string
) => {
  /**
   * Error handling
   */
  const knownError =
    ERRORS[(resp as any)?.response?.data?.error_code as number];
  switch (resp.status) {
    case 401:
      toast.error("The prospect requested not to email");
      break;
    case 400:
      knownError
        ? toast.error(knownError)
        : toast.error(errorMessage || "Email Error");
      break;
    default:
      toast.error(errorMessage || "Email Error");
  }
};

export const useEmailTemplatesManager = () => {
  const { campaign, account, focusedContact } = useAccountDetailsContext();
  const { data: templates } = useFetchEmailTemplatesList();
  const { mutateAsync: deleteTemplateAsync } = useDeleteTemplate();
  const { mutateAsync: getEmailGenerationInfoAsync } = useEmailGenerationInfo();

  const [activeTemplateId, setActiveTemplateId] = useState<string>();
  const [isEditMode, setEditMode] = useState<boolean>();
  const [emailGenerationConfig, setEmailGenerationConfig] =
    useState<GetEmailGenerationInfoResponseI>();

  const activeTemplate = useMemo(
    () =>
      templates?.data?.email_templates?.find((t) => t.id === activeTemplateId),
    [activeTemplateId, templates]
  );

  // When user opens the email sidebar, get info for the email to be sent.
  // This endpoint returns an config object, which is required later for sending email.
  const createEmailGenerationConfig = useCallback(async () => {
    if (activeTemplateId && campaign?.id && account?.id && focusedContact?.id) {
      const resp = await getEmailGenerationInfoAsync({
        templateId: activeTemplateId,
        parameters: {
          campaign_id: campaign?.id,
          account_id: account?.id,
          contact_id: focusedContact?.id,
        },
      });

      if (resp.status === 200) {
        setEmailGenerationConfig(resp.data);
        return;
      }

      /**
       * Error handling
       */
      handleEmailGenerationError(resp, "Failed to get email configurations");
    }
  }, [activeTemplateId, focusedContact, campaign, account]);

  // by default, set first template as active
  // active template is set to undefined when isEditMode is true because that implies creating a new template
  useEffect(() => {
    if (!activeTemplateId && !isEditMode) {
      setActiveTemplateId(templates?.data?.email_templates?.[0]?.id);
    }
  }, [templates?.data?.email_templates, activeTemplateId, isEditMode]);

  useEffect(() => {
    createEmailGenerationConfig();
  }, [activeTemplateId]);

  const campaignAttachmentsFetcher = useMemo(
    () =>
      campaign?.id
        ? (api: APII) => api.getCampaignAttachments(campaign.id)
        : null,
    [campaign]
  );

  const [{ data: campaignAttachmentsData }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: campaignAttachmentsFetcher,
    onError: () => toast.error("Error getting available attachments."),
  });

  return {
    templates,
    activeTemplate,
    activeTemplateId,
    setActiveTemplateId,
    deleteTemplateAsync,
    isEditMode,
    setEditMode,
    emailGenerationConfig,
    setEmailGenerationConfig,
    campaignAttachmentsData,
  };
};

export interface AccountEmailAIGenerationOptionalPropsI {
  subject?: string;
  body?: string;
}

export const useAccountEmailAIGeneration = (
  template: EmailTemplateI,
  optional?: AccountEmailAIGenerationOptionalPropsI
) => {
  const { campaign, account, focusedContact } = useAccountDetailsContext();
  const { mutateAsync: getEmailGenerationInfoAsync } = useEmailGenerationInfo();
  const { mutateAsync: generateAIEmailBodyStreamAsync } =
    useGenerateAIEmailStreamBody();
  const { mutateAsync: generateAIEmailSubjectStreamAsync } =
    useGenerateAIEmailStreamSubject();

  const [isAIGenerationComplete, setIsAIGenerationComplete] = useState(false);
  const [isAIGenerationStarted, setIsAIGenerationStarted] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const [emailData, setEmailData] = useState<
    GetEmailGenerationStatusResponseI | undefined
  >(
    optional
      ? {
          subject: optional?.subject,
          body: optional?.body,
        }
      : undefined
  );

  const handleStream = async (
    isBody: boolean,
    parameters: GenerateEmailStreamRequestParamsI
  ) => {
    const ERROR_TYPE = "Timeout";
    const TIMEOUT_MS = 30 * TIME_INTERVALS_MS.ONE_SECOND;

    let timeoutId;
    const abortController = new AbortController();

    try {
      const timeoutPromise = new Promise((_, reject) => {
        timeoutId = window.setTimeout(() => {
          abortController.abort();
          reject(new Error(ERROR_TYPE));
        }, TIMEOUT_MS);
      });

      let resp;
      const streamPromise = (async () => {
        if (isBody) {
          resp = await generateAIEmailBodyStreamAsync({
            parameters: parameters,
            signal: abortController.signal,
          });
        } else {
          resp = await generateAIEmailSubjectStreamAsync({
            parameters: parameters,
            signal: abortController.signal,
          });
        }

        if (!resp.body) {
          throw new Error("ReadableStream not supported in this browser.");
        }

        const reader = resp.body?.getReader();
        const decoder = new TextDecoder();

        let done = false;
        while (!done) {
          if (abortController.signal.aborted) {
            break;
          }

          const readResult = await reader?.read();
          const value = readResult?.value;
          const readerDone = readResult?.done;

          done = readerDone ?? true;

          if (value) {
            const chunk = decoder.decode(value);

            if (isBody) {
              setEmailData((prev) => ({
                ...prev,
                body: (prev?.body || "") + chunk,
              }));
            } else {
              setEmailData((prev) => ({
                ...prev,
                subject: (prev?.subject || "") + chunk,
              }));
            }
          }
        }
      })();

      await Promise.race([streamPromise, timeoutPromise]);
      clearTimeout(timeoutId);
    } catch (error) {
      if ((error as Error).message === ERROR_TYPE) {
        toast.error("Email generation timed out. Please try again later.");
      } else {
        toast.error((error as Error).message);
      }
    }
  };

  const handleComposeWithAI = useCallback(async () => {
    if (isAIGenerationStarted) {
      // still on-going AI generation
      return;
    }

    setIsAIGenerationStarted(true);
    setIsAIGenerationComplete(false);

    let success = false;

    if (template?.id && campaign?.id && account?.id && focusedContact?.id) {
      const resp = await getEmailGenerationInfoAsync({
        templateId: template.id,
        parameters: {
          campaign_id: campaign?.id,
          account_id: account?.id,
          contact_id: focusedContact?.id,
        },
      });

      if (resp.status === 200) {
        const parameters: GenerateEmailStreamRequestParamsI = {
          campaign_id: resp.data.campaign_id,
          contact_email: resp.data.contact_email,
          account_id: resp.data.account_id,
          contact_id: resp.data.contact_id,
          account_name: resp.data.account_name,
          account_industry: resp.data.account_industry,
          account_city: resp.data.account_city,
          account_state: resp.data.account_state,
          account_headcount: resp.data.account_headcount,
          account_website: resp.data.account_website,
          email_signature: resp.data.email_signature,
          user_prompt: resp.data.user_prompt,
        };

        // stream email subject

        await Promise.all([
          handleStream(false, parameters), // stream email subject
          handleStream(true, parameters), // stream email body
        ]);

        success = true;
      }
    }

    setIsAIGenerationComplete(true);
    setIsAIGenerationStarted(false);

    return success;
  }, [template?.id, campaign?.id, account?.id, focusedContact?.id]);

  useEffect(() => {
    // reset/initialize content for selected template
    setIsEmailSent(false);
    setIsAIGenerationComplete(false);
    setIsAIGenerationStarted(false);
  }, [template?.id]);

  return {
    isAIGenerationComplete,
    isAIGenerationStarted,
    isEmailSent,
    emailData,
    setEmailData,
    setIsEmailSent,
    setIsAIGenerationComplete,
    setIsAIGenerationStarted,
    handleComposeWithAI,
  };
};
