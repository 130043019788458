import { AxiosInstance } from "axios";

export interface MarkOpportunityParamsI {
  isOpportunityCreated: boolean;
  notes: string;
}

export const SingleLicenseGlenXMeetingsEndpoints = (api: AxiosInstance) => ({
  markOpportunity: (
    campaignId: string,
    cadId: string,
    { isOpportunityCreated, notes }: MarkOpportunityParamsI
  ) =>
    api.post(`v1/x/company/${campaignId}/meeting/${cadId}/mark_meeting`, {
      is_opportunity: isOpportunityCreated,
      notes,
    }),
});
