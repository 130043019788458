import { FC, useMemo, useState } from "react";
import { BrainCog, Check, Loader2 } from "lucide-react";

import { useEffectOnceWhen, useInterval } from "shared/lib/hooks";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";

import { useGlobalContext } from "@/hooks/use-global-context";
import { useOnboardingStatus } from "@/hooks/use-onboarding-status";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/base/shadcn/popover";
import { KNOWLEDGE_BASE_GENERATION_STATUS } from "@/constants/user";
import { useLocalStorage } from "@/context/local-storage";
import { Button } from "shared/ui/shadcn/button";
import { useRouter } from "next/router";
import Router from "@/helpers/router";

interface AIGenerationItemProps extends PropsWithClassNameI {
  title: string;
  href: string;
  isStarted: boolean;
  isFinished: boolean;
}

const AIGenerationItem: FC<AIGenerationItemProps> = ({
  className,
  title,
  href,
  isStarted,
  isFinished,
}) => {
  const { push } = useRouter();

  const { indicator, statusLabel } = useMemo(() => {
    // green checkmark, when the process is finished
    if (isFinished) {
      return {
        indicator: <Check size={24} color="rgb(39, 202, 74)" />,
        statusLabel: "Completed",
      };
    }

    // spinner, when the process is started
    if (isStarted) {
      return {
        indicator: <LoadingSpinner className="text-[rgb(204,204,204)]" />,
        statusLabel: "In progress",
      };
    }

    // grey checkmark, when the process is not started
    return {
      indicator: <Check size={24} color="rgba(204, 204, 204)" />,
      statusLabel: "Not started",
    };
  }, [isStarted, isFinished]);

  return (
    <Button
      variant="ghost"
      onClick={() => push(href)}
      className={clsxMerge(
        "flex h-auto items-start justify-start gap-2 px-2",
        className
      )}
    >
      {indicator}

      <div className="flex flex-col items-start gap-1">
        <p className="text-black typography-body-4-medium">{title}</p>
        <p
          className={clsxMerge(
            "typography-body-4",
            isFinished ? "rgb(51, 51, 51)" : "text-muted-foreground"
          )}
        >
          {statusLabel}
        </p>
      </div>
    </Button>
  );
};

/**
 * This component is dedicated to showing AI generation processes on single user
 * licenses, which are:
 * - Configuring email automation
 * - Training modules generation
 */
export const AIGenerationProcessesButton = () => {
  const storage = useLocalStorage();

  const [shouldRenderPopover, setShouldRenderPopover] = useState(
    !storage.singleUserAIGenerationBlockHiddenAt
  );

  const { reloadUser } = useGlobalContext();
  const { knowledgeBaseGenerationStatus, flags } = useOnboardingStatus();

  const isKnowledgeBaseGenerationFinished =
    knowledgeBaseGenerationStatus ===
    KNOWLEDGE_BASE_GENERATION_STATUS.COMPLETED;

  const isAIEmailProcessPending = Boolean(
    flags.isEmailDomainSet && !flags.isEmailDomainActive
  );

  const isAIGenerationPending = Boolean(
    knowledgeBaseGenerationStatus ===
      KNOWLEDGE_BASE_GENERATION_STATUS.TRIGGERED || isAIEmailProcessPending
  );

  const isAIGenerationFinished =
    isKnowledgeBaseGenerationFinished && flags.isEmailDomainActive;

  const { cancelInterval } = useInterval(
    () => {
      if (reloadUser && !isAIGenerationFinished) {
        void reloadUser();
      }
    },
    TIME_INTERVALS_MS.ONE_SECOND * 5,
    [isAIGenerationFinished]
  );

  const handlePopoverVisibility = (isVisible: boolean) => {
    if (!isVisible && isAIGenerationFinished) {
      storage.singleUserAIGenerationBlockHiddenAt = new Date().toISOString();
      setShouldRenderPopover(false);
    }
  };

  useEffectOnceWhen(() => {
    cancelInterval();
  }, isAIGenerationFinished);

  if (!shouldRenderPopover) {
    return null;
  }

  return (
    <Popover onOpenChange={handlePopoverVisibility}>
      <PopoverTrigger asChild>
        <div
          role="button"
          className={clsxMerge(
            "group btn btn-ghost relative px-2.5 py-2 text-white",
            "hover:border-[#CC3997]/50 hover:bg-[#CC3997]/20"
          )}
        >
          <BrainCog
            size={24}
            color="white"
            className="group-hover:animate-wiggle"
          />

          {isAIGenerationPending && (
            <Loader2
              className="absolute bottom-0.5 right-0.5 animate-spin text-sky-500"
              size={14}
            />
          )}

          {isAIGenerationFinished && (
            <Check
              className="absolute bottom-0.5 right-0.5"
              size={14}
              color="rgb(39, 202, 74)"
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="flex w-[369px] flex-col gap-2 rounded-lg pb-2 shadow-sm"
        align="end"
      >
        <h3 className="gradient-text typography-body-3-semibold">
          {isAIGenerationFinished
            ? "AI has finished indexing!"
            : "AI is working"}
        </h3>

        <p className="text-muted-foreground typography-body-4">
          You have 2 sections being generated by AI:
        </p>

        <div className="-mx-2 flex flex-col">
          <AIGenerationItem
            title="Email automation"
            href={Router.singleUserLicense.emailAutomation()}
            isStarted={isAIEmailProcessPending}
            isFinished={!!flags.isEmailDomainActive}
          />

          {knowledgeBaseGenerationStatus && (
            <AIGenerationItem
              title="Training material"
              href={Router.training()}
              isStarted={
                knowledgeBaseGenerationStatus !==
                KNOWLEDGE_BASE_GENERATION_STATUS.NOT_TRIGGERED
              }
              isFinished={isKnowledgeBaseGenerationFinished}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
