import { createContext, useContext } from "react";

import {
  EmailAutomationSuggestedDomainI,
  GetEmailAutomationConfigResponseI,
  PurchaseDomainRequestParamsI,
  UpdateEmailAutomationConfigParamsI,
} from "shared/lib/interfaces/email/automation";
import { QueryResultI } from "shared/lib/interfaces/react-query";

export type UseEmailAutomationConfigQueryI =
  QueryResultI<GetEmailAutomationConfigResponseI>;

export type UseEmailAutomationSuggestedDomainsQueryI = QueryResultI<
  EmailAutomationSuggestedDomainI[] | null
>;

interface EmailAutomationContextI {
  currentCampaignId: string | undefined;
  emailConfigApi: UseEmailAutomationConfigQueryI;
  updateConfig: (data: UpdateEmailAutomationConfigParamsI) => Promise<boolean>;

  selectedDomain: EmailAutomationSuggestedDomainI | undefined;
  setSelectedDomain: (
    domain: EmailAutomationSuggestedDomainI | undefined
  ) => void;
  purchasingDomainId: string | undefined;
  suggestedDomainsApi: UseEmailAutomationSuggestedDomainsQueryI;
  enableSuggestedDomainsApi: () => void;
  purchaseDomain: (
    data: PurchaseDomainRequestParamsI
  ) => Promise<string | undefined>;
}

export const EmailAutomationContext = createContext<EmailAutomationContextI>({
  currentCampaignId: undefined,

  emailConfigApi: {} as UseEmailAutomationConfigQueryI,
  updateConfig: () => Promise.resolve(false),

  selectedDomain: undefined,
  setSelectedDomain: () => {},
  purchasingDomainId: undefined,
  enableSuggestedDomainsApi: () => {},
  suggestedDomainsApi: {} as UseEmailAutomationSuggestedDomainsQueryI,
  purchaseDomain: () => Promise.resolve(undefined),
});

export const useEmailAutomationContext = () => {
  const context = useContext(EmailAutomationContext);

  if (!context) {
    throw new Error(
      "useEmailAutomationContext must be used within a EmailAutomationProvider"
    );
  }

  return context;
};
