import { DATE_FORMAT_ONLY_TIME_WITH_TZ } from "shared/lib/constants/time";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { clsxMerge } from "shared/lib/helpers";
import { useLiveTime } from "shared/lib/hooks";
import { CurrentCallerBadge } from "./current-caller-badge";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useMemo } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { EndedLabelOnly } from "@/modules/calling/cards/lead-card/lead-avatar/connection-label/ended";
import { ConnectedLabelOnly } from "@/components/modules/calling/cards/lead-card/lead-avatar/connection-label/connected";

export const LeadAdditionalInfo = () => {
  const { contact: callContact } = useInCallContext();
  const { contacts, callStatus } = useAccountDetailsContext();

  const { startAt } = useInCallContext();

  const isConnected = callStatus === DIALER_CALL_STATUS.DURING && !!startAt;
  const isEnded = callStatus === DIALER_CALL_STATUS.AFTER;

  const contact = useMemo(
    () => contacts?.find((c) => c.id === callContact?.id),
    [callContact, contacts]
  );

  const currentTime = useLiveTime({
    dateFormat: "",
    hourFormat: DATE_FORMAT_ONLY_TIME_WITH_TZ,
    timezone: contact?.timezone || TIMEZONES[0].tzCode,
  });

  return (
    <div
      className={clsxMerge(
        "flex flex-col items-end justify-center",
        "text-white",
        "mt-10 p-3"
      )}
    >
      <span className={clsxMerge("typography-body-5-medium", "mb-2")}>
        Local time for contact {currentTime}
      </span>

      {isConnected && <ConnectedLabelOnly className="mb-1" />}
      {isEnded && <EndedLabelOnly className="mb-1" />}

      <CurrentCallerBadge />
    </div>
  );
};
