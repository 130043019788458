import { FC, PropsWithChildren, ReactNode } from "react";

import {
  HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID,
  PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID,
} from "@/constants/element-ids";
import { clsxMerge } from "shared/lib/helpers";
import { PrimaryNavigationNavbar } from "components/navigation/primary/navbar";
import { PrimaryNavigationPageHeader } from "@/components/navigation/primary/page-header";
import { Container } from "@/components/base/container";

interface PrimaryNavigationPropsI extends PropsWithChildren {
  title?: ReactNode;

  // @deprecated This prop is deprecated and will be removed in the future.
  isBackgroundVisible?: boolean;

  contentContainerClassName?: string;
  pageContainerClassName?: string;
  headerClassName?: string;
  isCurrentTimeVisible?: boolean;
  isNavbarHidden?: boolean;
  isPageHeaderHidden?: boolean;

  // Indicates whether page header should be wrapped into <Container> component
  isPageHeaderContained?: boolean;
  pageHeaderContainerClassName?: string;
}

/**
 *
 * @param title page title to be placed in the page header bar (below nav bar). Omitting the `title` results in header bar not being rendered.
 * @param isNavbarHidden removes nav bar from the page.
 * @param isPageHeaderHidden removes page header bar with title from the page.
 * @param isBackgroundVisible
 * @param contentContainerClassName
 * @param pageContainerClassName
 * @param headerClassName used for styling the header bar (the bar that appears below the nav bar).
 * @param isCurrentTimeVisible shows current time in the page header bar
 * @param children
 */
const PrimaryNavigation: FC<PrimaryNavigationPropsI> = ({
  title,
  isNavbarHidden,
  isPageHeaderHidden,
  children,
  contentContainerClassName,
  pageContainerClassName,
  headerClassName,
  isCurrentTimeVisible = true,
  isPageHeaderContained = false,
  pageHeaderContainerClassName,
}) => (
  <div
    className={clsxMerge(
      "relative z-0 h-[100vh] max-h-[100vh] min-w-[768px]",
      "overflow-x-auto overflow-y-hidden lg:overflow-x-hidden"
    )}
  >
    <div className="z-1 relative flex h-[100vh] max-h-[100vh]">
      <div
        id={HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID}
        className={clsxMerge(
          "relative z-20 flex flex-grow flex-col overflow-y-auto overflow-x-hidden",
          pageContainerClassName
        )}
      >
        {/* ---------------- TOP NAVIGATION ---------------- */}
        {!isNavbarHidden && <PrimaryNavigationNavbar />}

        {/* ---------------- PAGE HEADER BAR ---------------- */}
        {title &&
          !isPageHeaderHidden &&
          (isPageHeaderContained ? (
            <Container className={pageHeaderContainerClassName}>
              <PrimaryNavigationPageHeader
                className={headerClassName}
                title={title}
                isCurrentTimeVisible={isCurrentTimeVisible}
              />
            </Container>
          ) : (
            <PrimaryNavigationPageHeader
              className={headerClassName}
              title={title}
              isCurrentTimeVisible={isCurrentTimeVisible}
            />
          ))}

        {/* ---------------- CONTENT ---------------- */}
        <div
          id={PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID}
          className={clsxMerge("w-full flex-grow", contentContainerClassName)}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default PrimaryNavigation;
