import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { clsxMerge } from "shared/lib/helpers";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/navigation/primary/interfaces";
import { NavDropdown } from "@/components/navigation/primary/navbar/nav-items-row/components/nav-dropdown";
import { NavButtonPrimary } from "@/components/navigation/primary/navbar/nav-items-row/components/nav-button-primary";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { SETTINGS_ITEM_ID } from "../constants";

interface NavItemsRowPropsI extends PropsWithClassNameI {
  data: PrimaryNavigationItemCollapsableWithIconI[];
  dropdownClassName?: string;
  hideOnResponsive?: boolean;
}

// nav items on the right end of the nav bar. They have slightly different styling treatments.
const RIGHT_SIDE_NAV_ITEM_IDS = [SETTINGS_ITEM_ID];

export const NavItemsRow: FC<NavItemsRowPropsI> = ({
  data,
  className,
  hideOnResponsive = false,
  dropdownClassName,
}) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  const isRightSideNavItem = (navItemId: string) =>
    RIGHT_SIDE_NAV_ITEM_IDS.includes(navItemId);

  // Restrict re-navigating to other page to avoid losing data (Error: Loading initial props cancelled)
  useEffect(() => {
    const routeChangeStart = () => {
      setIsNavigating(true);
    };

    const routeChangeComplete = () => {
      setIsNavigating(false);
    };

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeComplete);
    // call routeChangeComplete() if there is a routeChangeError to reset isNavigating to false
    // this way nav items are not stuck in a disabled state
    router.events.on("routeChangeError", routeChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeComplete);
      router.events.off("routeChangeError", routeChangeComplete);
    };
  }, [router]);

  return (
    <div
      className={clsxMerge(
        "h-full",
        hideOnResponsive ? "hidden lg:flex" : "flex",
        className
      )}
    >
      <ul className="menu menu-horizontal gap-2">
        {data.map((item, i) => {
          const isDropdown =
            item?.children?.length ||
            item.disabled ||
            (!("href" in item) && !("handler" in item));
          const isItemDisabled = isNavigating || item.disabled;
          const isButtonOnly = isRightSideNavItem(item.id as string);

          return isDropdown ? (
            <NavDropdown
              key={i}
              navItem={item}
              disabled={isItemDisabled}
              dropdownClassName={dropdownClassName}
              isBtnIconOnly={isButtonOnly}
            />
          ) : (
            <NavButtonPrimary
              key={i}
              navItem={item}
              disabled={isItemDisabled}
              isBtnIconOnly={isButtonOnly}
            />
          );
        })}
      </ul>
    </div>
  );
};
