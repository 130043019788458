import { AxiosInstance } from "axios";

import {
  FirstTimeCallTimeResponseI,
  GoalTrackerResponseI,
  LifetimePerformanceResponseI,
  PerformanceCampaignStatsResponseI,
  PerformanceDashboardResponseI,
  TodayPerformanceResponseI,
  UpdateGoalTrackerRequestI,
} from "@/api/routes/performance/interfaces";
import { CoachingMessageI } from "@/interfaces/coaching";

export interface GetCoachingMessagesResponseI {
  coaching_messages: CoachingMessageI[];
  next_token?: string;
}

export const PerformanceAPIEndpoints = (client: AxiosInstance) => ({
  fetchPerformanceDashboard: (
    campaignId: string,
    startDate: string,
    endDate: string | undefined
  ) =>
    client.get<PerformanceDashboardResponseI>("/v1/performance/main", {
      params: {
        campaign_id: campaignId,
        start_date: startDate,
        end_date: endDate,
      },
    }),

  fetchPerformanceCampaignStats: (campaignId: string) =>
    client.get<PerformanceCampaignStatsResponseI>(
      "/v1/performance/campaign_stats",
      {
        params: { campaign_id: campaignId },
      }
    ),

  fetchLifetimePerformance: () =>
    client.get<LifetimePerformanceResponseI>(
      "/v1/performance/lifetime_performance"
    ),

  fetchTodayPerformance: () =>
    client.get<TodayPerformanceResponseI>("/v1/performance/today_performance"),

  fetchGoalTrackerMetrics: () =>
    client.get<GoalTrackerResponseI>("/v1/performance/goal/current"),

  updateGoalTrackerMetrics: (metrics: UpdateGoalTrackerRequestI) =>
    client.post("/v1/performance/goal/update", metrics),

  fetchFirstCallTime: () =>
    client.get<FirstTimeCallTimeResponseI>("/v1/performance/first_call_time"),

  getCoachingMessagesByCampaign: (
    campaignId: string,
    nextToken: string | null | undefined
  ) =>
    client.post<GetCoachingMessagesResponseI>(
      `/v1/campaign/${campaignId}/coaching`,
      {
        next_token: nextToken,
      }
    ),
});
