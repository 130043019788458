import { CloseIcon } from "shared/ui/icons";

import { clsxMerge } from "shared/lib/helpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

import { Portal } from "react-portal";
import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { FC, useMemo } from "react";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from "@/components/shared/widgets";

const CONFIRM_ACTION_MODAL_ID = "confirm-action-modal-close-widget";

/**
 * We want to simplify and when clicking outside of the sidebar
 * execute same logic when clicking close button
 */
export const MAXED_DIALER_CLOSE_BUTTON_ID = "maxed-dialer-close-button";

export const DialerCloseButton: FC<PropsWithClassNameI> = ({ className }) => {
  const callStatus = useDialerCallStatus();

  const openConfirmationModal = () => {
    modalHelpers.open(CONFIRM_ACTION_MODAL_ID);
  };

  const isAllowedToClose = useMemo(
    () => callStatus === DIALER_CALL_STATUS.BEFORE,
    [callStatus]
  );

  const handleClose = () => {
    if (isAllowedToClose) {
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    } else {
      openConfirmationModal();
    }
  };

  return (
    <>
      <button
        id={MAXED_DIALER_CLOSE_BUTTON_ID}
        className={clsxMerge(
          "btn btn-square btn-sm",
          "absolute right-2 top-2",
          "z-20",
          className
        )}
        onClick={handleClose}
      >
        <CloseIcon className="w-6 text-white" />
      </button>

      {/*@ts-ignore*/}
      <Portal>
        <ConfirmActionModal
          title="Disposition is required"
          description="You must finish the call and fill out the disposition before proceeding."
          id={CONFIRM_ACTION_MODAL_ID}
          cancelButtonText="Close"
          confirmButtonText={"Understood"}
        />
      </Portal>
    </>
  );
};
