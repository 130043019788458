import { useCallback } from "react";
import { Portal } from "react-portal";
import { toast } from "react-hot-toast";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { DisqualifyModal } from "@/modals/disqualify-modal";
import { EditContactModal } from "@/modals/edit-contact-modal";
import { AccountSendEmailModal } from "@/modals/account-send-email-modal";
import CreateNewLeadModal from "@/modals/create-new-lead-modal";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import { AccountEditCallbackModal } from "@/modals/account-edit-callback-modal";
import EditAccountModal from "@/modals/edit-account-modal";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { CreateNewLeadFormI } from "@/modals/create-new-lead-modal/new-lead-form";
import { glencocoClientAPI } from "@/api/glencoco";
import { handleErrorMessage } from "@/helpers/error";
import { DeleteAccountLeadConfirmationModal } from "@/modals/delete-account-lead-confirmation-modal";
import { AddUserToListSidebar } from "@/modules/pipeline/shared/add-user-to-list-sidebar";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { SetUnsetPrimaryLeadConfirmationModal } from "@/modals/set-primary-lead-confirmation-modal";
import { SetContactTypeModal } from "@/modals/set-contact-type-modal";
import { EmailTemplatesSidebar } from "@/modules/email-templates-sidebar";
import { useEmailTemplatesSidebarContext } from "@/modules/email-templates-sidebar/context";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { useLocalStorage } from "@/context/local-storage";
import { DNCNumbersToggleModal } from "@/components/modals/dnc-numbers-toggle-modal";

import { GlenXBookMeetingModal } from "@/modals/x_book_meeting_modal";
import DangerousActionConfirmationModal from "@/components/modals/dangerous-action-confirmation-modal";
import { useResetCampaignScript } from "@/modules/pipeline/account-details/queries/mutations";
import { useIsSingleUserLicense } from "@/hooks/use-global-context";
import { CreateGlenXOpportunityModal } from "customer/components/modals/glenx/meetings/create-opportunity-modal";
import { NotCreatedGlenXOpportunityModal } from "customer/components/modals/glenx/meetings/not-created-opportunity-modal";
import { FindMoreLeadsModal } from "@/components/modals/account-find-more-leads-modal";
import { useFetchCampaignIcpTitles } from "@/modules/pipeline/account-details/queries";
import { useListTourContext } from "@/modules/tours/list/context";

export const ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID =
  "account-details-create-new-lead-modal";

export const RESET_CAMPAIGN_SCRIPT_MODAL_ID = "reset-campaign-script-modal";

export const AccountDetailsModals = () => {
  const { clearModalAccount, modalAccount } = useAccountsContext();
  const { mutateAsync: resetCampaignScript } = useResetCampaignScript();
  const storage = useLocalStorage();
  const isSingleUserLicense = useIsSingleUserLicense();
  const { isTourOpen } = useListTourContext();

  const {
    focusedContact,
    setFocusedContact,
    campaign,
    account,
    contactsForContextMenu,
    customerAccountDisposition,
    onAccountUpdate: handleAccountUpdate,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const {
    isOpen: isAddUserToListSidebarOpen,
    onClose: closeAddUserToListSidebar,
  } = useAddUserToListSidebarContext();

  const {
    isOpen: isEmailTemplatesSidebarOpen,
    onClose: closeEmailTemplatesSidebar,
  } = useEmailTemplatesSidebarContext();

  const { data: campaignIcpTitles } = useFetchCampaignIcpTitles(
    isTourOpen ? undefined : campaign?.id
  );

  const submitNewLeadToAccountAsBacklog = async (data: CreateNewLeadFormI) => {
    if (campaign?.id && account?.id) {
      const API = glencocoClientAPI();

      const resp = await API.addLeadToAccount(campaign?.id, account?.id, {
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        phone: data.phone,
        mobile: data.mobile,
        email: data.email,
        linkedin_url: data.linkedin_url,
      }).catch((e) => e);

      if (resp.status === 200) {
        toast.success("Success");
        return true;
      } else {
        handleErrorMessage(
          resp?.response?.data?.error_code as number,
          "Failed to add new lead. Please message us in the chat window on the bottom right so we can assist."
        );
      }
    }
    return false;
  };

  const handleResetCampaignScript = async () => {
    if (campaign?.id) {
      await resetCampaignScript(campaign.id);
    }
  };

  // Call this function when we need to both update account data and reload account history
  const onModalActionSuccess = useCallback(() => {
    handleAccountUpdate();
    reloadAccountHistory();

    if (focusedContact) {
      setFocusedContact(undefined);
    }
  }, [handleAccountUpdate, reloadAccountHistory, focusedContact]);

  const createGlenXMarkOpportunityHandler =
    (isOpportunityCreated: boolean) => async (notes: string) => {
      if (campaign?.id && customerAccountDisposition && isSingleUserLicense) {
        const API = glencocoClientAPI();

        const markOpportunityResponse =
          await API.singleUserLicense.meetings.markOpportunity(
            campaign.id,
            customerAccountDisposition.id,
            {
              isOpportunityCreated,
              notes,
            }
          );

        if (markOpportunityResponse.status === 200) {
          toast.success(
            `Marked as ${
              isOpportunityCreated ? "created" : "not created"
            } opportunity`
          );

          void handleAccountUpdate();

          return Promise.resolve(true);
        } else {
          toast.error(
            `Failed to mark as ${
              isOpportunityCreated ? "created" : "not created"
            } opportunity`
          );

          return Promise.resolve(false);
        }
      }

      return Promise.resolve(false);
    };

  /**
   * Clear persisted data for modals. Right now we save data for AccountSendEmailModal
   * and CreateNewLeadModal
   */
  useEffectOnce(() => {
    storage.emailModalContent = null;
    storage.newLeadModalPersistedValues = null;
  });

  return (
    // @ts-ignore
    <Portal>
      {campaign && account && (
        <DisqualifyModal
          campaignId={campaign?.id}
          account={account}
          contacts={contactsForContextMenu}
          focusedContactId={focusedContact?.id}
          onSuccess={onModalActionSuccess}
        />
      )}

      {focusedContact && (
        <>
          <EditContactModal
            campaign={campaign}
            contact={focusedContact}
            onClose={() => setFocusedContact(undefined)}
            onSubmitSuccess={onModalActionSuccess}
          />

          <SetUnsetPrimaryLeadConfirmationModal
            campaign={campaign}
            account={account}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <AccountSendEmailModal
            campaignId={campaign?.id as string}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <DeleteAccountLeadConfirmationModal
            campaignId={campaign?.id}
            contact={focusedContact}
            onSuccess={() => {
              setFocusedContact(undefined);
              onModalActionSuccess();
            }}
          />

          <SetContactTypeModal
            campaignId={campaign?.id}
            accountId={account?.id}
            contactId={focusedContact?.id}
            contactType={focusedContact.contact_disposition_type}
            onSuccess={onModalActionSuccess}
          />
        </>
      )}

      {isSingleUserLicense && (
        <>
          <CreateGlenXOpportunityModal
            onSubmit={createGlenXMarkOpportunityHandler(true)}
          />

          <NotCreatedGlenXOpportunityModal
            onSubmit={createGlenXMarkOpportunityHandler(false)}
          />
        </>
      )}

      {/* THESE MODALS HANDLE NEW ACCOUNT LEAD CREATION */}
      <CreateNewLeadModal
        modalId={ACCOUNT_DETAILS_CREATE_NEW_LEAD_MODAL_ID}
        campaign={campaign}
        account={account}
        handleNewLeadSubmitAsBacklog={submitNewLeadToAccountAsBacklog}
        onSuccess={onModalActionSuccess}
      />

      {/* THESE MODALS HANDLE UPDATING AN EXISTING ACCOUNT  */}
      {!!modalAccount && (
        <>
          {modalAccount.targetAccountStatus === ACCOUNT_STATUSES.CALLBACK && (
            <AccountEditCallbackModal
              followUpTime={modalAccount.followUpTime}
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}

          {modalAccount.targetAccountStatus ===
            ACCOUNT_STATUSES.MEETING_SCHEDULED && <GlenXBookMeetingModal />}
        </>
      )}

      <EditAccountModal
        campaign={campaign}
        account={account}
        onSubmitSuccess={onModalActionSuccess}
      />

      <AddUserToListSidebar
        isOpen={isAddUserToListSidebarOpen}
        onClose={closeAddUserToListSidebar}
      />

      <EmailTemplatesSidebar
        isOpen={isEmailTemplatesSidebarOpen}
        onClose={closeEmailTemplatesSidebar}
      />

      <DNCNumbersToggleModal
        campaign={campaign}
        contact={focusedContact}
        onSuccess={onModalActionSuccess}
      />

      <DangerousActionConfirmationModal
        modalId={RESET_CAMPAIGN_SCRIPT_MODAL_ID}
        title="Reset script"
        description="Are you sure you want to reset your custom script back to the default? This action is irreversible."
        callback={handleResetCampaignScript}
      />

      <FindMoreLeadsModal
        onSuccess={handleAccountUpdate}
        defaultTitles={campaignIcpTitles}
      />
    </Portal>
  );
};
