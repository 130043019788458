import { Options } from "react-select";

import { clsxMerge } from "shared/lib/helpers";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "shared/ui/shadcn/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/ui//shadcn/select";

export interface SelectFieldPropsI {
  label?: string;
  placeholder: string;
  options?: Options<{ label: string; value: any }>;
  defaultValue?: string;
  disabled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  dropdownClassName?: string;
  dropdownItemClassName?: string;
  onChange: (value: string) => void;
}

const SelectField = ({
  label,
  placeholder,
  options = [],
  defaultValue,
  disabled = false,
  containerClassName,
  labelClassName,
  inputClassName,
  dropdownClassName,
  dropdownItemClassName,
  onChange,
}: SelectFieldPropsI) => {
  return (
    <FormItem className={containerClassName}>
      {label && (
        <FormLabel className={clsxMerge(labelClassName)}>{label}</FormLabel>
      )}
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        onValueChange={onChange}
      >
        <FormControl>
          <SelectTrigger className={inputClassName}>
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
        </FormControl>

        <SelectContent className={clsxMerge("z-[999]", dropdownClassName)}>
          {options.map((opt, index) => (
            <SelectItem
              key={index}
              value={opt.value}
              className={clsxMerge(dropdownItemClassName)}
            >
              {opt.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <FormMessage />
    </FormItem>
  );
};

export default SelectField;
