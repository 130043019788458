import { useCallback, useMemo } from "react";

import { BaseControlButton } from "@/components/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { CalendarIcon } from "shared/ui/icons";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { AccountStatusI, ContactDetailI } from "shared/lib/interfaces/account";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  ACCOUNT_DISPOSITION_STATUSES,
  ACCOUNT_STATUSES,
} from "shared/lib/constants/account";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import ButtonMenu from "shared/ui/button-menu";
import { getFullEntityName } from "shared/lib/helpers";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import {
  GlenXBookMeetingModal,
  X_BOOK_MEETING_MODAL_ID,
} from "@/modals/x_book_meeting_modal";

export const MeetingButton = () => {
  const { contact: callContact } = useInCallContext();
  const { setModalAccount } = useAccountsContext();

  const { account, accountStatus, campaign, contacts, callStatus } =
    useAccountDetailsContext();

  // User can either book a meeting or reschedule it depending on the account status
  const actionLabel = useMemo(() => {
    if (
      accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED
    ) {
      return "Reschedule meeting";
    }

    return "Book meeting";
  }, [accountStatus?.status]);

  const handleOpenBookMeetingModal = ({
    id,
    title = "",
    email = "",
    first_name,
    last_name,
  }: ContactDetailI) => {
    const name = getFullEntityName({ first_name, last_name });

    //TODO refactor this one
    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountStatus?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        id,
        title,
        email,
        name,
      },
      followUpTime: accountStatus?.meeting_time || "",
    });

    setTimeout(() => modalHelpers.open(X_BOOK_MEETING_MODAL_ID));
  };

  const items = useMemo(
    () =>
      contacts?.map((contact) => {
        let itemLabel = "Contact without email";
        const firstOrLastName = contact.first_name || contact.last_name;
        const { email } = contact;

        if (firstOrLastName) {
          itemLabel = email ? `${firstOrLastName} - ${email}` : firstOrLastName;
        }

        return (
          <ButtonMenuItem
            text={itemLabel}
            onClick={() => handleOpenBookMeetingModal(contact)}
          />
        );
      }) || [],
    [contacts]
  );

  /**
   * Note
   *
   * During the call you want to book the meeting for the person you are calling to
   */
  const isSingleOption = useMemo(() => {
    return contacts?.length === 1 || callStatus !== DIALER_CALL_STATUS.BEFORE;
  }, [contacts, callStatus]);

  const currentSingleContact = useMemo(() => {
    return callStatus === DIALER_CALL_STATUS.BEFORE
      ? contacts?.[0]
      : contacts?.find((c) => c.id === callContact.id);
  }, [callStatus, contacts, callContact]);

  const handleBookMeeting = useCallback(() => {
    handleOpenBookMeetingModal(currentSingleContact || {});
  }, [handleOpenBookMeetingModal, currentSingleContact]);

  const openModal = useCallback(() => {
    return handleBookMeeting();
  }, [currentSingleContact, handleBookMeeting]);

  return (
    <>
      {isSingleOption ? (
        <BaseControlButton className="btn-ae-default" onClick={openModal}>
          <CalendarIcon className="mb-1 mt-1 w-4" />
          <span>{actionLabel}</span>
        </BaseControlButton>
      ) : (
        <ButtonMenu
          items={items}
          className="p-0"
          position="end"
          containerClassName="dropdown-top"
          contentClassName="w-max max-w-[300px] mt-0 mb-2"
          renderTrigger={() => (
            <BaseControlButton className="btn-ae-default">
              <CalendarIcon className="mb-1 mt-1 w-4" />
              <span>{actionLabel}</span>
            </BaseControlButton>
          )}
        />
      )}

      <GlenXBookMeetingModal />
    </>
  );
};
