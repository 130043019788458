import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { setCookie } from "cookies-next";
import {
  Auth0Provider,
  AuthorizationParams,
  useAuth0,
} from "@auth0/auth0-react";

import { ROUTES_EXPECTED_IGNORE_AUTH_LOGS } from "./constants";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";
import { checkIfClient } from "shared/lib/helpers";
import { useInterval } from "shared/lib/hooks";
import { DayJs } from "shared/lib/helpers/date";

import { LoadingModule } from "@/components/modules/loading";
import { COOKIES_GLCC_ACCESS_TOKEN } from "@/constants/cookies";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { LocalStorage } from "@/helpers/local-storage";
import { logout, verifyAccessToken } from "@/auth/utils";

const Auth0LibProvider: FC<PropsWithChildren> = ({ children }) => {
  const onRedirectCallback = (appState: any) => {
    if (window.location.href.includes(`?code`) && appState?.returnTo) {
      window.location.href = appState?.returnTo;
    }

    window.history.replaceState(
      {},
      document.title,
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  const authorizationParams = useMemo(() => {
    const params: AuthorizationParams = {
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      redirect_uri: process.env.NEXT_PUBLIC_DOMAIN,
    };

    // parse url to check if query param "screen_hint" exists.
    // in that case, we must pass it to auth0 authorizationParams to tell it to
    // open the auth UI on the specified signup screen (eg. show sign UP view
    // instead of sign IN)
    if (checkIfClient()) {
      const { screen_hint } = queryString.parse(
        new URL(window?.location?.href)?.search
      );

      if (screen_hint && typeof screen_hint === "string") {
        params.screen_hint = screen_hint;
      }
    }

    return params;
  }, []);

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL as string}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
      authorizationParams={authorizationParams}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const AuthLocalProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    user,
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [isTokenAcquired, setIsTokenAcquired] = useState(false);

  const getToken = async () => {
    const isIgnoreLogs = ROUTES_EXPECTED_IGNORE_AUTH_LOGS.includes(
      window.location.pathname
    );

    try {
      const accessToken = await getAccessTokenSilently();

      if (accessToken) {
        const LS = new LocalStorage();
        setCookie(COOKIES_GLCC_ACCESS_TOKEN, accessToken);
        LS.auth0TokenUpdatedAt = DayJs().toISOString();
        setIsTokenAcquired(true);

        /**
         * Fallback scenario where accessToken is already expired
         * https://github.com/auth0/auth0-react/issues/464
         * https://github.com/auth0/auth0-react/blob/main/FAQ.md
         */
        if (!isIgnoreLogs) {
          verifyAccessToken(accessToken);
        }
      } else {
        setCookie(COOKIES_GLCC_ACCESS_TOKEN, "");
        logout();
      }
    } catch (e) {
      if (!isIgnoreLogs) {
        dd.rum.error(
          `${ERROR_CATEGORIES.AUTH} User failed to acquire access token`,
          { user, error: e }
        );
      }

      setCookie(COOKIES_GLCC_ACCESS_TOKEN, "");
      logout();
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { returnTo: window.location.href } });
    }

    if (error) {
      console.log("Error:", error);
      console.log("logout");

      logout();
    }

    if (isAuthenticated && user?.email && !error) {
      getToken();
    }
  }, [user, isAuthenticated, isLoading, error]);

  useInterval(
    async () => {
      const LS = new LocalStorage();
      const tokenUpdatedAt = LS.auth0TokenUpdatedAt;

      const isUpdatedRequired = !tokenUpdatedAt
        ? true
        : DayJs().isAfter(DayJs(tokenUpdatedAt).add(30, "seconds"));

      if (isUpdatedRequired && isAuthenticated && user?.email && !error) {
        getToken();
      }
    },
    TIME_INTERVALS_MS.ONE_SECOND * 10,
    [user, isAuthenticated, error]
  );

  return user?.email && isAuthenticated && isTokenAcquired ? (
    children
  ) : (
    <LoadingModule />
  );
};

export const AuthProvider = ({
  children,
  isAuth0Only,
}: PropsWithChildren & {
  isAuth0Only?: boolean;
}) => (
  <Auth0LibProvider>
    {isAuth0Only ? children : <AuthLocalProvider>{children}</AuthLocalProvider>}
  </Auth0LibProvider>
);
