// React imports
import { FC, useMemo } from "react";
import { AtSign, FileUserIcon, Phone } from "lucide-react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";

// Internal app imports
import { AccountDetailsV2LeadItemEditLeadMenu } from "@/components/modules/pipeline/account-details/sections/leads-section/edit-lead-menu";
import { getLeadStateFlags } from "@/modules/pipeline/account-details/utils";
import { PhoneLink } from "@/components/modules/pipeline/account-details/sections/leads-section/phone-link";
import { ExpandableCustomField } from "./expandable-custom-field";

// Shared lib imports
import { PROSPECT_DISPOSITION_TYPES_MAP } from "shared/lib/constants/dispositions";
import {
  clsxMerge,
  fixUrl,
  getFullEntityName,
  getS3AssetPath,
} from "shared/lib/helpers";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import CopyToClipboard from "shared/ui/copy-to-clipboard";
import { LogoLinkedInIcon } from "shared/ui/icons";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface LeadItemPropsI {
  className?: string;
  contact: ContactDetailI;
  isDeleteContactAllowed?: boolean;
  isCurrentlyCalling?: boolean;
}

const COPY_VALUES_CLASSNAMES = clsxMerge(
  "typography-body-4",
  "break-words line-clamp-2 text-ellipsis"
);

interface LeadTagPropsI {
  tag: string;
}

const LeadTag: FC<LeadTagPropsI & PropsWithClassNameI> = ({ tag, className }) =>
  tag && (
    <div
      className={clsxMerge(
        "bg-[#DFDFDF] px-2.5 py-1 text-[#666]",
        "rounded text-center typography-body-4-bold",
        className
      )}
    >
      {tag}
    </div>
  );

export const AccountDetailsLeadItem: FC<LeadItemPropsI> = ({
  className,
  contact,
  isDeleteContactAllowed,
  isCurrentlyCalling,
}) => {
  const { isLeadDisqualified, isLeadMarkedDNC } = useMemo(
    () => getLeadStateFlags(contact),
    [contact?.contact_disposition_type]
  );

  const linkedin = useMemo(
    () => contact?.linkedin || contact?.linkedin_url,
    [contact]
  );

  const meta = useMemo(() => {
    return contact?.meta?.values;
  }, [contact]);

  return (
    <div className={clsxMerge("flex w-full gap-2", className)}>
      <img
        alt="Lead Avatar"
        src={getS3AssetPath(
          "platform/account-details/lead-avatar-placeholder.webp"
        )}
        className="h-8 w-8"
      />

      <div className="flex flex-1 flex-col gap-y-0.5">
        <span
          className={clsxMerge(
            "line-clamp-2 max-w-[240px] text-ellipsis typography-body-4-semibold",
            {
              "line-through": isLeadDisqualified || isLeadMarkedDNC,
            }
          )}
        >
          {getFullEntityName(contact)}
        </span>

        <span className={clsxMerge(COPY_VALUES_CLASSNAMES, "text-[#666]")}>
          {contact.title}
        </span>

        {contact?.email && (
          <CopyToClipboard textToCopy={contact.email}>
            <div className="flex items-center gap-x-3">
              <AtSign className="size-[14px] rounded bg-black/10 p-0.5" />
              <span className={clsxMerge(COPY_VALUES_CLASSNAMES, "ae-link")}>
                {contact.email}
              </span>
            </div>
          </CopyToClipboard>
        )}

        {contact?.phone && (
          <div className="flex items-center gap-x-3">
            <Phone className="size-[14px] rounded bg-black/10 p-0.5" />
            <PhoneLink
              contactId={contact?.id}
              phoneNumber={contact.phone}
              className={COPY_VALUES_CLASSNAMES}
              isDNC={contact?.phone_v2?.do_not_call}
              isLoadingComponent
            />
          </div>
        )}

        {contact?.mobile && (
          <div className="flex items-center gap-x-3">
            <DevicePhoneMobileIcon className="size-[14px] rounded bg-black/10 p-0.5" />
            <PhoneLink
              contactId={contact?.id}
              phoneNumber={contact.mobile}
              className={COPY_VALUES_CLASSNAMES}
              isDNC={contact?.mobile_v2?.do_not_call}
              isLoadingComponent
            />
          </div>
        )}

        {linkedin && (
          <a
            target="_blank"
            rel="noreferrer"
            href={fixUrl(linkedin)}
            className={clsxMerge(
              COPY_VALUES_CLASSNAMES,
              "ae-link flex items-center"
            )}
          >
            <span className="flex items-center gap-x-3">
              <LogoLinkedInIcon className="size-[14px] rounded bg-[#3471FE] p-[3px] text-white" />
              {linkedin}
            </span>
          </a>
        )}

        {meta?.map((metaValue, i) => (
          <div
            key={i}
            className="relative z-[1] flex w-[calc(100%+30px)] gap-x-3"
          >
            <FileUserIcon className="relative top-[6px] size-[14px] min-w-[14px] rounded bg-black/10 p-0.5" />
            <ExpandableCustomField lineHeight={14}>
              <span
                className={clsxMerge(
                  COPY_VALUES_CLASSNAMES,
                  "line-clamp-none text-[#333]"
                )}
              >
                <span className="mr-1 typography-body-4-medium">
                  {metaValue.label}:
                </span>
                <span className="[word-break:break-word]">
                  {metaValue.value}
                </span>
              </span>
            </ExpandableCustomField>
          </div>
        ))}

        <div className="mt-1 flex flex-wrap gap-1.5">
          {contact.is_primary_lead && (
            <Tooltip showDelay={50}>
              <TooltipTrigger>
                <LeadTag
                  tag="Primary Lead"
                  className="bg-[#014DFE] text-white"
                />
              </TooltipTrigger>
              <TooltipContent className="z-50 max-w-[250px] text-center">
                A primary lead is the main contact for an account. They are the
                lead that gets dialed first when calling.
              </TooltipContent>
            </Tooltip>
          )}

          {isLeadDisqualified ? (
            <LeadTag tag="Disqualified" />
          ) : (
            <>
              {contact?.contact_disposition_type && (
                <LeadTag
                  tag={
                    PROSPECT_DISPOSITION_TYPES_MAP[
                      contact?.contact_disposition_type
                    ]
                  }
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex items-start">
        <AccountDetailsV2LeadItemEditLeadMenu
          contact={contact}
          isDeleteContactAllowed={isDeleteContactAllowed}
          isCurrentlyCalling={isCurrentlyCalling}
          isDisqualified={isLeadDisqualified}
          isMarkedDNC={isLeadMarkedDNC}
        />
      </div>
    </div>
  );
};
