import { FC, PropsWithChildren, useState } from "react";

import {
  useFetchEmailAutomationConfigQuery,
  useEmailAutomationPurchaseDomainMutation,
  useEmailAutomationSuggestedDomainsQuery,
  useEmailAutomationUpdateConfigMutation,
} from "@/modules/single-user-license/email-automation/queries/queries";

import { EmailAutomationSuggestedDomainI } from "shared/lib/interfaces/email/automation";
import { EmailAutomationBaseProvider } from "shared/ui/email/automation/context/provider";

interface EmailAutomationProviderPropsI extends PropsWithChildren {
  campaignId: string;
}

export const EmailAutomationProvider: FC<EmailAutomationProviderPropsI> = ({
  campaignId,
  children,
}) => {
  const emailConfigApi = useFetchEmailAutomationConfigQuery(campaignId);

  const updateEmailAutomationConfigApi =
    useEmailAutomationUpdateConfigMutation(campaignId);

  const purchaseEmailAutomationDomainApi =
    useEmailAutomationPurchaseDomainMutation(campaignId);

  const [isSuggestedDomainsApiEnabled, setIsSuggestedDomainsApiEnabled] =
    useState(false);

  const suggestedDomainsApi = useEmailAutomationSuggestedDomainsQuery(
    campaignId,
    isSuggestedDomainsApiEnabled
  );

  const [selectedDomain, setSelectedDomain] =
    useState<EmailAutomationSuggestedDomainI>();

  const enableSuggestedDomainsApi = () => {
    setIsSuggestedDomainsApiEnabled(true);
  };

  return (
    <EmailAutomationBaseProvider
      campaignId={campaignId}
      fetchEmailAutomationConfigApi={emailConfigApi}
      updateEmailAutomationConfigApi={updateEmailAutomationConfigApi}
      purchaseEmailAutomationDomainApi={purchaseEmailAutomationDomainApi}
      fetchSuggestedEmailAutomationDomainsApi={suggestedDomainsApi}
      selectedDomain={selectedDomain}
      setSelectedDomain={setSelectedDomain}
      enableSuggestedDomainsApi={enableSuggestedDomainsApi}
    >
      {children}
    </EmailAutomationBaseProvider>
  );
};
